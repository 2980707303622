import * as React from "react";
import { Box, IconButton, Typography, useTheme, Avatar, TextField, Skeleton } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { chatQueryOptions, chatsQueryOptions, currentUserQueryOptions, tenantQueryOptions } from "./queryOptions";
import { ArrowLeft2, Edit2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { coreChatRouterUpdateChat } from "../backend-client/generated/sdk.gen";
import { IosShare } from "@mui/icons-material";
import { ShareDialog } from "./ShareDialog";
import { SourceFilters } from "../components/SourceFilters";
import { FileType, SourceType } from "../shared/types";
import { UserFileContextSwitcher } from "./context/userFileContextSwitcher";
import { SelectedFile } from "./context/focusDocuments";
import { DocCountIcon } from "./context/docCountIcon";
import { UserFocusedDocumentPopper } from "./context/userFocusedDocumentPopper";
import { TenantFileContextSwitcher } from "./context/tenantFileContextSwitcher";
import { TenantFocusedDocumentPopper } from "./context/tenantFocusedDocumentPopper";

interface ChatHeaderProps {
    /**
     * The name of the chat.
     * If undefined, the chat is being loaded.
     */
    chatName: string | undefined;
    /**
     * The ID of the chat.
     */
    chatId: string | undefined;
    focusedDocuments: SelectedFile[];
    onFocusedDocumentsChange: ((documents: SelectedFile[]) => void) | undefined;
    projects: string[];
    onProjectsChange: (projects: string[]) => void;
    sourceTypes: SourceType[];
    onSourceTypesChange: (sourceTypes: SourceType[]) => void;
    fileTypes: FileType[];
    onFileTypesChange: (fileTypes: FileType[]) => void;
    earliestDate: Date | undefined;
    onEarliestDateChange: (date: Date | undefined) => void;
    isFiltersExpanded: boolean;
    onFiltersExpandedChange: (expanded: boolean) => void;
    disabledEditingFiltersReason: string | undefined;
    hideSourceFilters?: boolean;
    tenantFocusedDocumentNames: string[] | undefined;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({
    chatName,
    chatId,
    focusedDocuments,
    onFocusedDocumentsChange,
    projects,
    onProjectsChange,
    sourceTypes,
    onSourceTypesChange,
    fileTypes,
    onFileTypesChange,
    earliestDate,
    onEarliestDateChange,
    isFiltersExpanded,
    onFiltersExpandedChange,
    disabledEditingFiltersReason,
    hideSourceFilters,
    tenantFocusedDocumentNames,
}) => {
    const [isEditing, setIsEditing] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [editedName, setEditedName] = React.useState(chatName);
    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

    const tenantFlags = useQuery({
        ...tenantQueryOptions,
        select: data => ({
            areFiltersAvailable: data.enable_filters ?? false,
            isFocusedModeAvailable: data.can_chat_with_docs ?? false,
        }),
    });

    const queryClient = useQueryClient();

    const updateChatMutation = useMutation({
        mutationFn: ({ newName }: { newName: string }) =>
            coreChatRouterUpdateChat({
                path: { chat_id: chatId ?? "" },
                body: { name: newName, messages: null, file_scope: null, user_file_scope: null },
            }),
        onSuccess: (_, { newName }) => {
            const singleQueryOptions = chatQueryOptions(chatId ?? "");
            void queryClient.setQueryData(singleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return { ...oldData, updated_at: new Date().toISOString(), name: newName };
            });
            const multipleQueryOptions = chatsQueryOptions;
            void queryClient.setQueryData(multipleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return oldData.map(chat =>
                    chat.unique_id === chatId ? { ...chat, updated_at: new Date().toISOString(), name: newName } : chat,
                );
            });
            setIsEditing(false);
        },
    });

    const handleEditClick = React.useCallback(() => {
        setIsEditing(true);
        setEditedName(chatName);
    }, [chatName]);

    const handleEditComplete = React.useCallback(() => {
        if (editedName != null && editedName.trim() !== "" && editedName !== chatName) {
            updateChatMutation.mutate({ newName: editedName });
        } else {
            setIsEditing(false);
        }
    }, [editedName, chatName, updateChatMutation]);

    const navigate = useNavigate();
    const handleGoBack = React.useCallback(() => {
        navigate("/home");
    }, [navigate]);

    const theme = useTheme();

    const currentUserQuery = useQuery(currentUserQueryOptions);

    const initials = React.useMemo(() => {
        if (currentUserQuery.data == null) {
            return undefined;
        }
        return currentUserQuery.data.first_name.charAt(0) + currentUserQuery.data.last_name.charAt(0);
    }, [currentUserQuery.data]);

    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, []);

    const handleChangeChatName = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(e.target.value);
    }, []);

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                handleEditComplete();
            }
        },
        [handleEditComplete],
    );

    const handleShare = React.useCallback(() => {
        setIsShareDialogOpen(true);
    }, []);

    const handleCloseShareDialog = React.useCallback(() => {
        setIsShareDialogOpen(false);
    }, []);

    const isFocusedMode = focusedDocuments.length > 0 || (tenantFocusedDocumentNames?.length ?? 0) > 0;

    const handleFocusedDocumentsChange = React.useCallback(
        (docs: SelectedFile[]) => {
            if (docs.length > 0) {
                onProjectsChange([]);
                onSourceTypesChange([]);
                onFileTypesChange([]);
                onEarliestDateChange(undefined);
            }
            onFocusedDocumentsChange?.(docs);
        },
        [onFocusedDocumentsChange, onProjectsChange, onSourceTypesChange, onFileTypesChange, onEarliestDateChange],
    );

    const [userDocAnchorIconEl, setUserDocAnchorIconEl] = React.useState<HTMLElement | null>(null);
    const [tenantDocAnchorIconEl, setTenantDocAnchorIconEl] = React.useState<HTMLElement | null>(null);

    const handleUserDocIconMouseEnter = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setUserDocAnchorIconEl(event.currentTarget);
    }, []);

    const handleUserDocIconMouseLeave = React.useCallback(() => {
        setUserDocAnchorIconEl(null);
    }, []);

    const handleTenantDocIconMouseEnter = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setTenantDocAnchorIconEl(event.currentTarget);
    }, []);

    const handleTenantDocIconMouseLeave = React.useCallback(() => {
        setTenantDocAnchorIconEl(null);
    }, []);

    const showFilters = !hideSourceFilters && !isFocusedMode && tenantFlags.data?.areFiltersAvailable;

    const showTenantFocusedMode = tenantFocusedDocumentNames?.length ?? 0 > 0;
    const showUserFocusedMode = tenantFlags.data?.isFocusedModeAvailable;

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                    bgcolor: "primary.main",
                    justifyContent: "space-between",
                    borderRadius: 3,
                    overflow: "hidden",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "stretch", gap: 1.5 }}>
                    <IconButton onClick={handleGoBack}>
                        <ArrowLeft2 size={24} color={theme.palette.neutrals[70]} />
                    </IconButton>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flexGrow: 1,
                            mt: 0.5,
                            pr: !isHovered && !isEditing ? 7 : undefined,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <>
                                {isEditing ? (
                                    <TextField
                                        value={editedName}
                                        onChange={handleChangeChatName}
                                        onBlur={handleEditComplete}
                                        onKeyDown={handleKeyDown}
                                        variant="standard"
                                        autoFocus
                                        InputProps={{ disableUnderline: true, sx: { height: "20px" } }}
                                        sx={{
                                            height: "20px",
                                            "& .MuiInput-root": {
                                                color: "neutrals.80",
                                                fontSize: "subtitle1.fontSize",
                                                fontWeight: 500,
                                            },
                                        }}
                                    />
                                ) : chatName != null ? (
                                    <Typography
                                        variant="subtitle1"
                                        noWrap
                                        color="neutrals.80"
                                        fontWeight={500}
                                        sx={{ lineHeight: 1.25 }}
                                    >
                                        {chatName}
                                    </Typography>
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{ fontSize: "subtitle1.fontSize", lineHeight: 1.25 }}
                                    />
                                )}
                                {chatId != null && (isHovered || isEditing) && (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={handleEditClick}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{ p: 0.5, maxHeight: theme.spacing(2) }}
                                        >
                                            <Edit2 size={16} color={theme.palette.neutrals[50]} />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={handleShare}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{ p: 0.5, maxHeight: theme.spacing(2) }}
                                        >
                                            <IosShare sx={{ fontSize: 16, color: theme.palette.neutrals[50] }} />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        </Box>
                        <Typography variant="caption" color="neutrals.50">
                            From search results
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {(tenantFocusedDocumentNames?.length ?? 0) > 0 ? (
                        <>
                            <TenantFileContextSwitcher currentContext="Focused" />
                            <DocCountIcon
                                focusedDocumentCount={tenantFocusedDocumentNames?.length ?? 0}
                                canEditFocus={false}
                                onMouseEnter={handleTenantDocIconMouseEnter}
                                onMouseLeave={handleTenantDocIconMouseLeave}
                            />
                            <TenantFocusedDocumentPopper
                                tenantFocusedDocumentNames={tenantFocusedDocumentNames ?? []}
                                anchorEl={tenantDocAnchorIconEl}
                            />
                        </>
                    ) : tenantFlags.data?.isFocusedModeAvailable ? (
                        <>
                            <UserFileContextSwitcher
                                focusedDocuments={focusedDocuments}
                                onFocusedDocumentsChange={
                                    onFocusedDocumentsChange != null ? handleFocusedDocumentsChange : undefined
                                }
                            />
                            {focusedDocuments.length > 0 && (
                                <DocCountIcon
                                    focusedDocumentCount={focusedDocuments.length}
                                    canEditFocus={false}
                                    onMouseEnter={handleUserDocIconMouseEnter}
                                    onMouseLeave={handleUserDocIconMouseLeave}
                                />
                            )}
                            <UserFocusedDocumentPopper
                                focusedDocuments={focusedDocuments}
                                anchorEl={userDocAnchorIconEl}
                            />
                        </>
                    ) : null}
                    {!hideSourceFilters && !isFocusedMode && tenantFlags.data?.areFiltersAvailable && (
                        <SourceFilters
                            disabledEditingFiltersReason={disabledEditingFiltersReason}
                            isExpanded={isFiltersExpanded}
                            onExpandChange={onFiltersExpandedChange}
                            projects={projects}
                            onProjectsChange={onProjectsChange}
                            sourceTypes={sourceTypes}
                            onSourceTypesChange={onSourceTypesChange}
                            fileTypes={fileTypes}
                            onFileTypesChange={onFileTypesChange}
                            earliestDate={earliestDate}
                            onEarliestDateChange={onEarliestDateChange}
                        />
                    )}
                    {!showTenantFocusedMode && !showUserFocusedMode && !showFilters && (
                        <Avatar
                            sx={{
                                bgcolor: "surface.75",
                                color: "secondary.main",
                                fontSize: "1rem",
                                border: 1,
                                borderColor: "neutrals.30",
                            }}
                        >
                            {initials ?? ""}
                        </Avatar>
                    )}
                </Box>
            </Box>
            {chatId != null && (
                <ShareDialog open={isShareDialogOpen} onClose={handleCloseShareDialog} chatId={chatId} />
            )}
        </>
    );
};
