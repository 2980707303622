import { Box, SxProps, Theme } from "@mui/material";
import * as React from "react";

interface CitationProps {
    index: number;
    onClick?: (index: number) => void;
    sx?: SxProps<Theme>;
}

export const Citation: React.FC<CitationProps> = ({ index, onClick, sx }) => {
    const handleClick = React.useCallback(() => {
        if (onClick) {
            onClick(index);
        }
    }, [index, onClick]);

    return (
        <Box
            component="span"
            onClick={handleClick}
            sx={[
                {
                    color: "citations.main",
                    cursor: onClick != null ? "pointer" : "default",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 16,
                    minHeight: 20,
                    width: 16 + (index.toString().length - 1) * 4,
                    height: 20,
                    bgcolor: "surface.50",
                    borderRadius: 1,
                    fontSize: "12px",
                    ml: 0.5,
                    position: "relative",
                    top: -1,
                    fontWeight: "bold",
                    "&:hover": {
                        bgcolor: onClick != null ? "neutrals.30" : "surface.75",
                    },
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {index + 1}
        </Box>
    );
};
