import * as React from "react";
import { Popper, Box } from "@mui/material";
import { SelectedFile } from "./focusDocuments";
import { UserFocusedDocumentItem } from "./UserFocusedDocumentItem";

interface UserFocusedDocumentPopperProps {
    focusedDocuments: SelectedFile[];
    anchorEl: HTMLElement | null;
}

export const UserFocusedDocumentPopper = ({ focusedDocuments, anchorEl }: UserFocusedDocumentPopperProps) => {
    return (
        <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ zIndex: theme => theme.zIndex.tooltip }}
        >
            <Box
                sx={{
                    mt: 1,
                    p: 1.5,
                    bgcolor: "surface.0",
                    borderRadius: 1,
                    boxShadow: theme => theme.shadows[2],
                    border: 1,
                    borderColor: "neutrals.20",
                    maxWidth: 300,
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {focusedDocuments.map(file => (
                        <UserFocusedDocumentItem key={file.id} file={file} />
                    ))}
                </Box>
            </Box>
        </Popper>
    );
};
