import * as React from "react";
import { Box, IconButton, Menu, MenuItem, Typography, SxProps, Theme, Button } from "@mui/material";
import { Setting4, ArrowDown2, Global } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { Model } from "./types";
import { comparatorOnFields } from "../utils/comparators";
import { tenantQueryOptions } from "./queryOptions";
import { useQuery } from "@tanstack/react-query";
import { Apartment, ApartmentSharp } from "@mui/icons-material";

const modelNames: Record<Model, { name: string; description: string; hideInMenu?: boolean; order?: number }> = {
    "chatgpt-4o-latest": { name: "GPT-4o", description: "Great for most tasks", order: 0 },
    "gpt-4o": { name: "GPT-4o", description: "Great for most tasks", hideInMenu: true, order: 1 },
    o1: { name: "o1", description: "Uses advanced reasoning", order: 2 },
    "o3-mini": { name: "o3-mini", description: "Fast at advanced reasoning", order: 3 },
    "gpt-4o-mini": { name: "GPT-4o mini", description: "Faster for everyday tasks", order: 4 },
    "o1-mini": { name: "o1-mini", description: "Faster at reasoning", hideInMenu: true, order: 5 },
    "deepseek-r1": { name: "DeepSeek R1", description: "Fast reasoning model", order: 6, hideInMenu: true },
};

interface ContextModeButtonProps {
    isDirectBaseModelChat: boolean;
    onToggle: (() => void) | undefined;
    model: Model;
    onModelChange: (model: Model) => void;
    menuPosition: "top" | "bottom";
    sx?: SxProps<Theme>;
}

const modelMenuItemSx = {
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    px: 1.5,
};

export const ContextModeButton: React.FC<ContextModeButtonProps> = React.memo(
    ({ isDirectBaseModelChat, onToggle, model, onModelChange, sx, menuPosition }: ContextModeButtonProps) => {
        const theme = useTheme();
        const [modelMenuAnchor, setModelMenuAnchor] = React.useState<null | HTMLElement>(null);
        const [contextMenuAnchor, setContextMenuAnchor] = React.useState<null | HTMLElement>(null);

        const handleContextMenuOpen = React.useCallback(
            (event: React.MouseEvent<HTMLElement>) => {
                if (onToggle == null) {
                    return;
                }
                event.stopPropagation();
                setContextMenuAnchor(event.currentTarget);
            },
            [onToggle],
        );

        const handleContextMenuClose = React.useCallback(() => {
            setContextMenuAnchor(null);
        }, []);

        const handleSelectIsBaseModelChat = React.useCallback(() => {
            if (onToggle != null && !isDirectBaseModelChat) {
                onToggle();
            }
            handleContextMenuClose();
        }, [onToggle, handleContextMenuClose, isDirectBaseModelChat]);

        const handleSelectIsTenantContext = React.useCallback(() => {
            if (onToggle != null && isDirectBaseModelChat) {
                onToggle();
            }
            handleContextMenuClose();
        }, [onToggle, handleContextMenuClose, isDirectBaseModelChat]);

        const handleModelMenuOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setModelMenuAnchor(event.currentTarget);
        }, []);

        const handleModelMenuClose = React.useCallback(() => {
            setModelMenuAnchor(null);
        }, []);

        const handleModelSelect = React.useCallback(
            (newModel: Model) => {
                onModelChange(newModel);
                handleModelMenuClose();
            },
            [onModelChange, handleModelMenuClose],
        );

        const modelDisplayName = modelNames[model].name;

        const tenant = useQuery(tenantQueryOptions);

        return (
            <Box
                component="span"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                sx={[{ display: "flex", alignItems: "center", gap: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
            >
                <Box
                    onClick={handleContextMenuOpen}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        bgcolor: onToggle != null ? "surface.25" : "transparent",
                        py: 0.5,
                        pl: onToggle != null ? 1 : 0.5,
                        pr: 0.5,
                        borderRadius: 4,
                        cursor: onToggle != null ? "pointer" : "default",
                        // width: onToggle != null ? 170 : 130,
                        minWidth: 0,
                        height: 32,
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                            {isDirectBaseModelChat ? (
                                <Global
                                    size={20}
                                    variant={onToggle != null ? "Bold" : "Outline"}
                                    color={onToggle != null ? theme.palette.secondary.main : theme.palette.neutrals[80]}
                                />
                            ) : (
                                <ApartmentSharp
                                    sx={{
                                        color:
                                            onToggle != null
                                                ? theme.palette.secondary.main
                                                : theme.palette.neutrals[80],
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            )}
                            <Typography
                                variant="body2"
                                noWrap
                                color={onToggle != null ? "secondary.main" : "neutrals.80"}
                                fontWeight={onToggle != null ? 450 : "normal"}
                            >
                                {isDirectBaseModelChat ? "External context" : `${tenant?.data?.name ?? "Firm"} context`}
                            </Typography>
                        </Box>
                        {onToggle != null && (
                            <IconButton size="small" disableRipple disableFocusRipple disableTouchRipple>
                                <ArrowDown2 size={12} color={theme.palette.secondary.main} />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Menu
                    anchorEl={contextMenuAnchor}
                    open={Boolean(contextMenuAnchor)}
                    onClose={handleContextMenuClose}
                    MenuListProps={{ sx: { py: 0.5 } }}
                    anchorOrigin={{ vertical: menuPosition === "top" ? "top" : "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: menuPosition === "top" ? "bottom" : "top", horizontal: "center" }}
                >
                    <MenuItem
                        onClick={handleSelectIsTenantContext}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "body2",
                            pl: 1.5,
                            cursor: onToggle != null ? "pointer" : "default",
                            bgcolor: isDirectBaseModelChat ? "transparent" : "surface.25",
                            color: isDirectBaseModelChat ? "neutrals.80" : "secondary.main",
                            fontWeight: isDirectBaseModelChat ? "normal" : 450,
                            "&:hover": {
                                bgcolor: onToggle != null ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!onToggle}
                        disableTouchRipple={!onToggle}
                    >
                        <Apartment
                            sx={{
                                color: isDirectBaseModelChat
                                    ? theme.palette.neutrals[80]
                                    : theme.palette.secondary.main,
                                width: 16,
                                height: 16,
                            }}
                        />
                        {tenant?.data?.name ?? "Firm"} context
                    </MenuItem>
                    <MenuItem
                        onClick={handleSelectIsBaseModelChat}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "body2",
                            pl: 1.5,
                            cursor: onToggle != null ? "pointer" : "default",
                            bgcolor: !isDirectBaseModelChat ? "transparent" : "surface.25",
                            color: isDirectBaseModelChat ? "secondary.main" : "neutrals.80",
                            fontWeight: isDirectBaseModelChat ? 450 : "normal",
                            "&:hover": {
                                bgcolor: onToggle != null ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!onToggle}
                        disableTouchRipple={!onToggle}
                    >
                        <Global
                            size={16}
                            variant="Bold"
                            color={isDirectBaseModelChat ? theme.palette.secondary.main : theme.palette.neutrals[80]}
                        />
                        External context
                    </MenuItem>
                </Menu>

                {isDirectBaseModelChat && (
                    <>
                        <Button
                            size="small"
                            disableRipple
                            onClick={handleModelMenuOpen}
                            sx={{
                                display: "flex",
                                gap: 0.5,
                                alignItems: "center",
                                justifyContent: "start",
                                borderRadius: 4,
                                typography: "body2",
                                color: "neutrals.80",
                                bgcolor: onToggle != null ? "transparent" : "surface.25",
                                minWidth: 0,
                                "&:hover": {
                                    bgcolor: "surface.50",
                                },
                            }}
                        >
                            <Setting4 size={20} />
                            <Typography variant="body2" component="span">
                                {modelDisplayName}
                            </Typography>
                        </Button>

                        <Menu
                            anchorEl={modelMenuAnchor}
                            open={Boolean(modelMenuAnchor)}
                            onClose={handleModelMenuClose}
                            sx={{ p: 1 }}
                            MenuListProps={{ sx: { py: 0.5 } }}
                        >
                            {Object.entries(modelNames)
                                .sort(
                                    comparatorOnFields(modelEntry => [modelEntry[1].order ?? Number.POSITIVE_INFINITY]),
                                )
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                .filter(([m, modelEntry]) => !modelEntry.hideInMenu || m === model)
                                .map(([m]) => (
                                    <ModelMenuItem
                                        key={m}
                                        model={m as Model}
                                        isSelected={m === model}
                                        onModelChange={handleModelSelect}
                                    />
                                ))}
                        </Menu>
                    </>
                )}
            </Box>
        );
    },
);

ContextModeButton.displayName = "ContextModeButton";

interface ModelMenuItemProps {
    model: Model;
    isSelected: boolean;
    onModelChange: (model: Model) => void;
}

const ModelMenuItem: React.FC<ModelMenuItemProps> = ({ model, isSelected, onModelChange }) => {
    return (
        <MenuItem onClick={() => onModelChange(model)} sx={modelMenuItemSx}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" fontWeight={isSelected ? 550 : "normal"}>
                    {modelNames[model].name}
                </Typography>
                <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>{modelNames[model].description}</Typography>
            </Box>
        </MenuItem>
    );
};
