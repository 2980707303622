import * as React from "react";
import { Dialog, Box, Typography, IconButton, CircularProgress, Skeleton } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { NavigationButton } from "./components/NavigationButton";
import { PageThumbnail } from "./pageThumbnail";
import CloseIcon from "@mui/icons-material/Close";
import { Document, Page } from "react-pdf";
import { Add, Minus } from "iconsax-react";
import { FileType } from "../shared/types";

interface FullScreenPDFDialogProps {
    open: boolean;
    onClose: () => void;
    pdfName: string;
    fileType: FileType | undefined;
    pdfUrl: string;
    pageNumbers: number[];
    initialPage?: number;
}

export const FullScreenPDFDialog: React.FC<FullScreenPDFDialogProps> = React.memo(
    ({ open, onClose, pdfName, fileType, pdfUrl, pageNumbers, initialPage = 1 }: FullScreenPDFDialogProps) => {
        const { width, ref } = useResizeDetector({
            refreshMode: "debounce",
            refreshRate: 100,
        });

        const [pageNumber, setPageNumber] = React.useState(initialPage);
        const [numPages, setNumPages] = React.useState<number | null>(null);

        // const handlePageChange = React.useCallback((newPage: number) => {
        //     setPageNumber(newPage);
        // }, []);

        const goToPrevPage = React.useCallback(() => {
            setPageNumber(prev => Math.max(prev - 1, 1));
        }, []);

        const goToNextPage = React.useCallback(() => {
            setPageNumber(prev => Math.min(prev + 1, numPages ?? prev));
        }, [numPages]);

        const onDocumentLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
            setNumPages(numPages);
        }, []);

        const handlePageSelect = React.useCallback((pageNum: number) => {
            setPageNumber(pageNum);
        }, []);

        const [pageInputValue, setPageInputValue] = React.useState<string | undefined>(undefined);

        const handlePageInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPageInputValue(value);
        }, []);

        const handlePageInputBlur = React.useCallback(() => {
            const newPage = parseInt(pageInputValue ?? pageNumber.toString(), 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= (numPages ?? 1)) {
                setPageNumber(newPage);
            }
            setPageInputValue(undefined);
        }, [pageInputValue, numPages, pageNumber]);

        const handleKeyPress = React.useCallback(
            (event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                    handlePageInputBlur();
                }
            },
            [handlePageInputBlur],
        );

        React.useEffect(() => {
            setPageInputValue(pageNumber.toString());
        }, [pageNumber]);

        const [scale, setScale] = React.useState(0.8);

        const handleZoomIn = React.useCallback(() => {
            setScale(prev => Math.min(prev + 0.1, 2)); // Max zoom 200%
        }, []);

        const handleZoomOut = React.useCallback(() => {
            setScale(prev => Math.max(prev - 0.1, 0.5)); // Min zoom 50%
        }, []);

        const handleZoomInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(event.target.value, 10);
            if (!isNaN(value) && value >= 50 && value <= 200) {
                setScale(value / 100);
            }
        }, []);

        if (!open) {
            return null;
        }

        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                sx={{
                    "& .MuiDialog-paper": {
                        bgcolor: "surface.50",
                    },
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        px: 1,
                        py: 2,
                    }}
                >
                    {/* Header */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={theme => ({ width: 20, height: 20, color: theme.palette.neutrals[80] })} />
                        </IconButton>
                        <Typography variant="body1" sx={{ color: "secondary.main", fontWeight: 550 }}>
                            {pdfName}
                        </Typography>
                    </Box>

                    {/* Main Content */}
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}
                        ref={ref}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                overflow: "auto",
                                bgcolor: "grey.100",
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                            }}
                        >
                            <Document
                                className="highlight-block"
                                file={pdfUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={<CircularProgress />}
                                error={<Typography sx={{ p: 2 }}>Failed to load PDF</Typography>}
                            >
                                <Page
                                    className="highlight-block"
                                    pageNumber={pageNumber}
                                    width={width != null ? (width - 48) * scale : undefined}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    loading={<Skeleton variant="rectangular" width="100%" height={800} />}
                                    scale={scale}
                                />
                            </Document>

                            <ZoomAndNavControls
                                scale={scale}
                                handleZoomOut={handleZoomOut}
                                handleZoomIn={handleZoomIn}
                                handleZoomInputChange={handleZoomInputChange}
                                pageInputValue={pageInputValue}
                                handlePageInputChange={handlePageInputChange}
                                handlePageInputBlur={handlePageInputBlur}
                                handleKeyPress={handleKeyPress}
                                numPages={numPages}
                                goToPrevPage={goToPrevPage}
                                goToNextPage={goToNextPage}
                                pageNumber={pageNumber}
                            />
                        </Box>
                        {/* Relevant Pages Section */}
                        <Box sx={{ ml: 2, mt: 2, overflowX: "auto", pb: 2 }}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: "secondary.main",
                                    mb: 1,
                                    fontWeight: 500,
                                }}
                            >
                                Relevant Pages
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                {pageNumbers.map(pageNum => (
                                    <PageThumbnail
                                        key={pageNum}
                                        pageNumber={pageNum}
                                        fileType={fileType}
                                        pdfUrl={pdfUrl}
                                        isSelected={pageNumber === pageNum}
                                        onClick={() => handlePageSelect(pageNum)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);

FullScreenPDFDialog.displayName = "FullScreenPDFDialog";

interface ZoomAndNavControlsProps {
    scale: number;
    handleZoomOut: () => void;
    handleZoomIn: () => void;
    handleZoomInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    pageInputValue: string | undefined;
    handlePageInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handlePageInputBlur: () => void;
    handleKeyPress: (event: React.KeyboardEvent) => void;
    numPages: number | null;
    goToPrevPage: () => void;
    goToNextPage: () => void;
    pageNumber: number;
}

const ZoomAndNavControls: React.FC<ZoomAndNavControlsProps> = React.memo(
    ({
        scale,
        handleZoomOut,
        handleZoomIn,
        handleZoomInputChange,
        pageInputValue,
        handlePageInputChange,
        handlePageInputBlur,
        handleKeyPress,
        numPages,
        goToPrevPage,
        goToNextPage,
        pageNumber,
    }: ZoomAndNavControlsProps) => {
        return (
            <>
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        borderRadius: 1,
                        px: 0.5,
                    }}
                >
                    <IconButton
                        onClick={handleZoomOut}
                        disabled={scale <= 0.5}
                        size="small"
                        sx={{ color: "action.active" }}
                    >
                        <Minus size={16} />
                    </IconButton>

                    <input
                        value={`${Math.round(scale * 100)}%`}
                        onChange={handleZoomInputChange}
                        style={{
                            width: "60px",
                            padding: "4px 8px",
                            border: "1px solid #E0E0E0",
                            borderRadius: "4px",
                            textAlign: "center",
                        }}
                    />

                    <IconButton
                        onClick={handleZoomIn}
                        disabled={scale >= 2}
                        size="small"
                        sx={{ color: "action.active" }}
                    >
                        <Add size={16} />
                    </IconButton>
                </Box>

                {/* Updated Navigation Controls */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        left: 32,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{ color: "neutrals.60" }}>
                        Page
                    </Typography>
                    <input
                        value={pageInputValue}
                        onChange={handlePageInputChange}
                        onBlur={handlePageInputBlur}
                        onKeyPress={handleKeyPress}
                        style={{
                            width: "40px",
                            padding: "4px 8px",
                            border: "1px solid #E0E0E0",
                            borderRadius: "4px",
                        }}
                    />
                    <Typography variant="body2" sx={{ color: "neutrals.60" }}>
                        of {numPages ?? "?"}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        right: 32,
                        display: "flex",
                        gap: 1,
                    }}
                >
                    <NavigationButton direction="left" onClick={goToPrevPage} disabled={pageNumber <= 1} />
                    <NavigationButton
                        direction="right"
                        onClick={goToNextPage}
                        disabled={pageNumber >= (numPages ?? 1)}
                    />
                </Box>
            </>
        );
    },
);

ZoomAndNavControls.displayName = "ZoomControls";
