import * as React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { PersonData } from "../types";
import { format } from "date-fns";
import { DocumentThumbnail } from "../documentThumbnail";
import { Calendar, Folder, Gps } from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import { projectDocumentNamesQueryOptions } from "../../chat/queryOptions";
import { formatName } from "../formatPersonName";
import { getScrollbarSx } from "../../shared/scrollbarProps";
import { Apartment } from "@mui/icons-material";

interface ProjectPreviewSectionProps {
    // TODO: Make type more generic to person and people search
    project: PersonData["projects"][number];
    /** Use when project is selected from a person */
    personId?: string;
    onSelectFile?: (fileIdx: number) => void;
}

export const ProjectPreviewSection = React.memo(
    ({ project, personId, onSelectFile: onSelectFile }: ProjectPreviewSectionProps) => {
        const theme = useTheme();

        const { data: documentNames } = useQuery(projectDocumentNamesQueryOptions(project));

        const otherProjectMembers = React.useMemo(() => {
            return personId != null
                ? project.all_members?.filter(member => member.unique_id !== personId)
                : project.all_members;
        }, [project.all_members, personId]);

        const handleFileClick = React.useCallback(
            (fileIdx: number) => {
                onSelectFile?.(fileIdx);
            },
            [onSelectFile],
        );

        const filesWithNames = React.useMemo(() => {
            return project.file_ids
                .map((fileId, index) => ({
                    file_id: fileId,
                    file_name: documentNames?.[index],
                    index,
                }))
                .filter(file => file.file_name != null);
        }, [project.file_ids, documentNames]);

        const transformedSummary = React.useMemo(() => {
            return project.summary?.replace(/<[^>]*>?/gm, "")?.replace(/\s?\[\d+-\d+\]/g, "");
        }, [project.summary]);

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
                {project.summary != null && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="body2" fontWeight={550} color="neutrals.80">
                            Summary
                        </Typography>
                        <Box
                            sx={[
                                {
                                    bgcolor: "surface.0",
                                    maxHeight: "40vh",
                                    overflowY: "auto",
                                    p: 2,
                                    borderRadius: 2,
                                    border: 1,
                                    borderColor: "neutrals.25",
                                },
                                getScrollbarSx("surface.0"),
                            ]}
                        >
                            <Typography variant="body2" color="neutrals.80">
                                {transformedSummary}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="body2" fontWeight={550} color="neutrals.80">
                        Documents
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", height: 60 }}>
                        {filesWithNames.map(({ file_id, file_name, index }) => (
                            <Box
                                key={file_id}
                                onClick={() => handleFileClick(index)}
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    bgcolor: "surface.0",
                                    border: 1,
                                    borderColor: "neutrals.25",
                                    borderRadius: 2,
                                    p: 1,
                                    width: 220,
                                    cursor: "pointer",
                                    transition: "all 0.2s ease-in-out",
                                    height: 60,
                                    "&:hover": {
                                        bgcolor: "neutrals.5",
                                        borderColor: "neutrals.35",
                                    },
                                }}
                            >
                                <DocumentThumbnail
                                    blobName={file_id}
                                    fileType="pdf"
                                    isUserFile={false}
                                    width={60}
                                    height={40}
                                    shouldSasSign
                                    sx={{ cursor: "pointer" }}
                                />
                                <Typography
                                    variant="caption"
                                    color="neutrals.80"
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {file_name}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                {otherProjectMembers?.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                bgcolor: "surface.0",
                                border: 1,
                                borderColor: "neutrals.25",
                                borderRadius: 2,
                                p: 1,
                                // width: "fit-content",
                            }}
                        >
                            <Box sx={{ display: "flex" }}>
                                {otherProjectMembers.slice(0, 2).map((member, index) => (
                                    <Avatar
                                        key={member.unique_id}
                                        src={member.profile_picture_url ?? undefined}
                                        alt={`${member.first_name} ${member.last_name}`}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            ml: index === 0 ? 0 : -0.8,
                                            border: 1,
                                            borderColor: "white",
                                            zIndex: index + 1,
                                        }}
                                    />
                                ))}
                            </Box>

                            <Typography variant="caption" color="neutrals.50">
                                {otherProjectMembers.length === 1
                                    ? `${formatName(otherProjectMembers[0].first_name, otherProjectMembers[0].last_name)} collaborated on this project`
                                    : otherProjectMembers.length === 2
                                      ? `${formatName(otherProjectMembers[0].first_name, otherProjectMembers[0].last_name)} and ${formatName(otherProjectMembers[1].first_name, otherProjectMembers[1].last_name)} collaborated on this project`
                                      : otherProjectMembers.length === 3
                                        ? `${otherProjectMembers[0].first_name}, ${otherProjectMembers[1].first_name} and ${otherProjectMembers[2].first_name} collaborated on this project`
                                        : `${otherProjectMembers[0].first_name}, ${otherProjectMembers[1].first_name} and ${
                                              otherProjectMembers.length - 2
                                          } ${otherProjectMembers.length - 2 === 1 ? "other" : "others"} collaborated on this project`}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, ml: 1 }}>
                    {project.client != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Apartment sx={{ width: 12, height: 12, color: "neutrals.50" }} />
                            <Typography variant="caption" color="neutrals.50">
                                {project.client.name}
                            </Typography>
                        </Box>
                    )}
                    {project.target_organization != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Gps size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                {project.target_organization.name}
                            </Typography>
                        </Box>
                    )}
                    {project.id != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Folder size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                #{project.id.split("-")[1]}
                            </Typography>
                        </Box>
                    )}
                    {project.start_date != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Calendar size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                Started {format(new Date(project.start_date), "MMM yyyy")}
                            </Typography>
                        </Box>
                    )}
                    {project.end_date != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Calendar size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                Completed {format(new Date(project.end_date), "MMM yyyy")}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    },
);

ProjectPreviewSection.displayName = "ProjectPreviewSection";
