import { MemberWithProjects, ProjectWithAiSummary } from "../backend-client/generated/types.gen";
import { PersonData, Project } from "../search/types";
import { nullToUndefined } from "../utils/nullToUndefined";

export function convertProjectToCard(project: ProjectWithAiSummary): Project {
    return nullToUndefined(project);
}

export function convertPersonToCard(person: MemberWithProjects): PersonData {
    return nullToUndefined({
        unique_id: person.unique_id,
        first_name: person.first_name,
        last_name: person.last_name,
        email: person.email ?? "",
        profile_picture_url: person.profile_picture_url,
        active: person.active,
        projects: person.projects.map(project => ({
            id: project.id,
            name: project.name,
            start_date: project.start_date,
            end_date: project.end_date,
            all_members: project.all_members.map(member => ({
                unique_id: member.unique_id,
                first_name: member.first_name,
                last_name: member.last_name,
                profile_picture_url: member.profile_picture_url,
            })),
            file_ids: project.file_ids,
            summary: project.summary,
            client: project.client,
            target_organization: project.target_organization,
            project_type: project.project_type,
            can_be_used_for_credentials: project.can_be_used_for_credentials,
        })),
        title: person.title ?? "",
        country: person.country ?? "",
        city: person.city ?? "",
    });
}
