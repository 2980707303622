import * as React from "react";
import { Box, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logomark-primary-custom-color.svg";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { coreChatRouterCreateChat } from "../backend-client/generated/sdk.gen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, tenantQueryOptions } from "./queryOptions";
import { ChatSplitLayout } from "./chatSplitLayout";
import { ChatHeaderForTenant } from "./chatHeaderForTenant";
import { FocusDocumentsDialog } from "./context/FocusDocumentsDialog";
import { SelectedFile } from "./context/focusDocuments";
import { ChatInput } from "./chatInput";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { Model } from "./types";
import { useBoolean } from "../utils/hooks";
import { Helmet } from "react-helmet-async";
import { FileType, SourceType } from "../shared/types";
import { getSourceFilters } from "./getSourceFilters";

export const NewChatView: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>AnswerGrid</title>
            </Helmet>
            <SidebarLayout>
                <NewChatViewContent />
            </SidebarLayout>
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NewChatViewContentProps {}

const NO_OP = () => {
    // No-op
};

const NewChatViewContent: React.FC<NewChatViewContentProps> = () => {
    const [query, setQuery] = React.useState<string>("");
    const [model, setModel] = React.useState<Model | undefined>();
    const { value: isDirectBaseModelChat, toggleValue: toggleIsDirectBaseModelChat } = useBoolean(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = React.useState(false);

    const [projects, setProjects] = React.useState<string[]>([]);
    const [sourceTypes, setSourceTypes] = React.useState<SourceType[]>([]);
    const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
    const [earliestDate, setEarliestDate] = React.useState<Date | undefined>(undefined);

    const [searchParams] = useSearchParams();
    const tenantFocusedDocumentNames = React.useMemo<string[]>(
        () => searchParams.get("sources")?.split(",") ?? [],
        [searchParams],
    );

    const [userFocusedDocuments, setUserFocusedDocuments] = React.useState<Array<SelectedFile>>([]);

    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { mutate: createChatAndNavigateMutation, isPending } = useMutation({
        mutationFn: () =>
            coreChatRouterCreateChat({
                throwOnError: true,
                body: {
                    // TODO: Change to file IDs
                    file_scope: tenantFocusedDocumentNames,
                    user_file_scope: userFocusedDocuments.map(doc => doc.id),
                    user_persona_id:
                        selectedPersona.type !== "user" || selectedPersona.id === DEFAULT_PERSONA_ID
                            ? null
                            : selectedPersona.id,
                    tenant_persona_id:
                        selectedPersona.type !== "tenant" || selectedPersona.id === DEFAULT_PERSONA_ID
                            ? null
                            : selectedPersona.id,
                    is_direct_base_model_chat: isDirectBaseModelChat,
                    model: model,
                    source_filters: getSourceFilters(projects, sourceTypes, fileTypes, earliestDate),
                },
            }),
        onSuccess: response => {
            sessionStorage.setItem(SESSION_STORAGE_NEW_CHAT_QUERY, query);
            // if (searchParams.has("sources")) {
            //     sessionStorage.setItem(SESSION_STORAGE_FOCUSED_SEARCH_FILE_PATHS, searchParams.get("sources") ?? "");
            // }

            if (response.data.unique_id != null) {
                queryClient.setQueryData(chatQueryOptions(response.data.unique_id).queryKey, response.data);
                navigate(`/chat/${response.data.unique_id}?new`);
            } else {
                console.error("No unique_id returned from create chat");
            }
        },
        onError: error => {
            console.error("Error creating chat:", error);
        },
    });

    const handleQueryChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(event.target.value);
        },
        [setQuery],
    );

    const handleQuerySubmit = React.useCallback(() => {
        createChatAndNavigateMutation();
    }, [createChatAndNavigateMutation]);

    const [isAttachDialogOpen, setIsAttachDialogOpen] = React.useState(false);

    const handleAttach = React.useCallback(() => {
        setIsAttachDialogOpen(true);
    }, []);

    const handleAttachDialogClose = React.useCallback(() => {
        setIsAttachDialogOpen(false);
    }, []);

    const handleAttachDialogDone = React.useCallback((selectedFiles: Array<SelectedFile>) => {
        setUserFocusedDocuments(selectedFiles);
        setIsAttachDialogOpen(false);
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) {
            return "Please enter a message";
        }
        if (isPending) {
            return "Creating chat...";
        }
        return null;
    }, [query, isPending]);

    const theme = useTheme();

    const tenant = useQuery(tenantQueryOptions);

    const mainContent = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                bgcolor: "primary.main",
                borderRadius: 3,
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: "30%", rowGap: 2 }}>
                <Logo style={{ width: 64, height: 64, marginBottom: 2, color: theme.palette.surface[100] }} />
            </Box>
            <ChatInput
                query={query}
                onQueryChange={handleQueryChange}
                onQuerySubmit={isPending ? NO_OP : handleQuerySubmit}
                onAttach={(tenant.data?.can_chat_with_docs ?? false) ? handleAttach : undefined}
                disabledReason={disabledReason}
                isDirectBaseModelChat={isDirectBaseModelChat}
                onDirectBaseModelChatToggle={toggleIsDirectBaseModelChat}
                model={model}
                onModelChange={setModel}
                sx={{ maxWidth: "100%", position: "sticky", bottom: theme.spacing(2), left: 0, right: 0 }}
            />
        </Box>
    );

    const [selectedPersona, setSelectedPersona] = React.useState<SelectedPersona>({
        id: DEFAULT_PERSONA_ID,
        type: "tenant",
    });

    const header = (
        <ChatHeaderForTenant
            tenant={tenant.data}
            chatId={undefined}
            chatName={undefined}
            isLoading={false}
            tenantFocusedDocumentNames={tenantFocusedDocumentNames}
            focusedDocuments={userFocusedDocuments}
            onFocusedDocumentsChange={setUserFocusedDocuments}
            selectedPersona={selectedPersona}
            onSelectedPersonaChange={setSelectedPersona}
            hideSourceFilters={isDirectBaseModelChat}
            projects={projects}
            onProjectsChange={setProjects}
            sourceTypes={sourceTypes}
            onSourceTypesChange={setSourceTypes}
            fileTypes={fileTypes}
            onFileTypesChange={setFileTypes}
            earliestDate={earliestDate}
            onEarliestDateChange={setEarliestDate}
            isFiltersExpanded={isFiltersExpanded}
            onFiltersExpandedChange={setIsFiltersExpanded}
        />
    );

    return (
        <>
            <ChatSplitLayout
                header={header}
                mainContent={mainContent}
                mainContentProps={{
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                }}
            />
            <FocusDocumentsDialog
                open={isAttachDialogOpen}
                onClose={handleAttachDialogClose}
                onDone={handleAttachDialogDone}
                selectedFiles={userFocusedDocuments}
            />
        </>
    );
};
