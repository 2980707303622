import * as React from "react";
import { Box, Typography, Theme, SxProps, Popper, useTheme, Paper } from "@mui/material";
import { Global, Link } from "iconsax-react";
import { getFavIconUrl, extractDomain } from "../../utils/url";

type WebSourcePopoverProps = {
    children: React.ReactNode;
    url: string;
    title: string | undefined;
    description: string | undefined;
    rank: number;
    sx?: SxProps<Theme>;
};

export const WebSourcePopover = React.memo(({ children, url, title, description, rank, sx }: WebSourcePopoverProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [imageError, setImageError] = React.useState(false);

    const handlePopoverOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    const open = Boolean(anchorEl);
    const theme = useTheme();
    const domain = React.useMemo(() => extractDomain(url) ?? "", [url]);

    return (
        <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={[
                {
                    display: "inline-block",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {children}
            <Popper
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                placement="top"
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 2],
                        },
                    },
                ]}
            >
                <Paper elevation={0} sx={{ borderRadius: 2, bgcolor: "surface.0" }}>
                    <Box
                        sx={{
                            px: 2,
                            py: 1,
                            maxWidth: 250,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            overflow: "hidden",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                            <Global size={12} color={theme.palette.neutrals[80]} />
                            <Typography variant="caption" color="neutrals.80">
                                Source {rank + 1}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1.5 }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    sx={{
                                        mb: 0.5,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 5,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        wordBreak: "break-all",
                                    }}
                                    color="neutrals.80"
                                >
                                    {title ?? url}
                                </Typography>
                                <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
                                    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                        {imageError ? (
                                            <Link size={14} color={theme.palette.neutrals[60]} />
                                        ) : (
                                            <img
                                                src={getFavIconUrl(url, "duckduckgo")}
                                                alt={`${domain} favicon`}
                                                width={14}
                                                height={14}
                                                style={{ borderRadius: 2 }}
                                                onError={handleImageError}
                                            />
                                        )}
                                        <Typography variant="caption" color="neutrals.60">
                                            {domain}
                                        </Typography>
                                    </Box>
                                </Box>
                                {description != null && (
                                    <Typography
                                        variant="caption"
                                        color="neutrals.60"
                                        sx={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {description}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Popper>
        </Box>
    );
});

WebSourcePopover.displayName = "WebSourcePopover";
