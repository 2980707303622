type NullToUndefined<T> = T extends null
    ? undefined
    : T extends (infer U)[]
      ? NullToUndefined<U>[]
      : T extends object
        ? { [K in keyof T]: NullToUndefined<T[K]> }
        : T;

export function nullToUndefined<T>(obj: T): NullToUndefined<T> {
    if (obj == null) {
        return undefined as NullToUndefined<T>;
    }

    if (Array.isArray(obj)) {
        return obj.map(nullToUndefined) as NullToUndefined<T>;
    }

    if (typeof obj === "object") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                result[key] = nullToUndefined(obj[key]);
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return result;
    }

    return obj as NullToUndefined<T>;
}
