import * as React from "react";
import { faker } from "@faker-js/faker";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { Page, Sources } from "./types";
import { Source } from "./types";
import { comparatorOnFields } from "../utils/comparators";
import { SearchViewHeader } from "./searchViewHeader";
import { azureDocIntelligenceContentCleaner } from "../utils/contentCleaner";
import { DocumentThumbnail } from "./documentThumbnail";
import { FILE_EXTENSIONS_REGEX } from "./fileExtensions";
import { Add, Calendar, Folder, Minus } from "iconsax-react";
import { unique } from "../utils/arrays";

export const SourcesSection: React.FC<{
    sources: Sources | undefined;
    onSourceSelect: (idx: number, page: number | undefined) => void;
    focusedChatSourceIdxs: number[];
    onFocusedChatSourceIdxsChange: React.Dispatch<React.SetStateAction<number[]>>;
    selectedFileName?: string;
    selectedPage: number | undefined;
}> = ({
    sources,
    onSourceSelect,
    focusedChatSourceIdxs,
    onFocusedChatSourceIdxsChange,
    selectedFileName,
    selectedPage,
}) => {
    const onClickSource = React.useCallback(
        (idx: number, page: number | undefined) => {
            if (sources == null) {
                return;
            }
            onSourceSelect(idx, page);
        },
        [sources, onSourceSelect],
    );

    const focusedChatSourceIdxsSet = React.useMemo(() => new Set(focusedChatSourceIdxs), [focusedChatSourceIdxs]);

    const handleToggleFocusedChat = React.useCallback(
        (idx: number) => {
            if (focusedChatSourceIdxsSet.has(idx)) {
                onFocusedChatSourceIdxsChange(prev => prev.filter(i => i !== idx));
            } else {
                onFocusedChatSourceIdxsChange(prev => [...prev, idx]);
            }
        },
        [focusedChatSourceIdxsSet, onFocusedChatSourceIdxsChange],
    );

    if (sources == null) {
        return null;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.5 }}>
            <SearchViewHeader
                text="Documents"
                tooltip={`These are the (${Object.keys(sources).length}) sources that matched your search query`}
            />
            {Object.keys(sources).length > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, overflowX: "auto" }}>
                    {sources.map((source, index) => (
                        <FileSourceItem
                            key={index}
                            source={source}
                            idx={index}
                            onClick={onClickSource}
                            onToggleFocusedChat={handleToggleFocusedChat}
                            isAddedToFocusedChat={focusedChatSourceIdxsSet.has(index)}
                            isFileSelected={source.file_name === selectedFileName}
                            selectedPage={selectedPage}
                        />
                    ))}
                </Box>
            ) : (
                <Typography variant="body1">No results found.</Typography>
            )}
        </Box>
    );
};

const FileSourceItem: React.FC<{
    source: Source;
    idx: number;
    isAddedToFocusedChat: boolean;
    onClick: (idx: number, page: number | undefined) => void;
    onToggleFocusedChat: (idx: number) => void;
    isFileSelected: boolean;
    selectedPage: number | undefined;
}> = ({ source, idx, isAddedToFocusedChat, onClick, onToggleFocusedChat, isFileSelected, selectedPage }) => {
    const fakeUpdatedDate = React.useMemo(() => faker.date.recent({ days: 800 }), []);
    const realUpdatedDate = React.useMemo(
        () => (source.time_created != null ? new Date(source.time_created) : fakeUpdatedDate),
        [fakeUpdatedDate, source.time_created],
    );

    const formattedDate = React.useMemo(() => {
        const fourWeeksAgo = new Date();
        fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 4 * 7);

        if (realUpdatedDate > fourWeeksAgo) {
            return formatDistanceToNow(realUpdatedDate, { addSuffix: true });
        }

        return realUpdatedDate.toLocaleString("en-US", { month: "long", year: "numeric" });
    }, [realUpdatedDate]);

    const handleClickAnywhere = React.useCallback(() => {
        onClick(idx, undefined);
    }, [idx, onClick]);

    const handleClickPage = React.useCallback(
        (page: number) => {
            onClick(idx, page);
        },
        [idx, onClick],
    );

    const sortedPages = React.useMemo(() => {
        return source.pages.sort(comparatorOnFields(p => [p.page]));
    }, [source.pages]);

    const cleanContent = React.useMemo(
        () => (source.pages.length > 0 ? azureDocIntelligenceContentCleaner(source.pages[0].content) : undefined),
        [source.pages],
    );

    const handleAddToFocusedChat = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onToggleFocusedChat(idx);
        },
        [idx, onToggleFocusedChat],
    );

    const shouldShowPages = React.useMemo(() => {
        if (source.file_type === "xlsx") {
            const uniquePages = new Set(source.pages.map(p => p.page));
            return uniquePages.size > 1;
        }
        return source.pages.length !== 0;
    }, [source.pages, source.file_type]);

    const uniquePages = React.useMemo(() => {
        return unique(sortedPages, p => p.page.toString());
    }, [sortedPages]);

    const theme = useTheme();

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
                columnGap: 1.5,
                overflow: "hidden",
                bgcolor: "surface.0",
                borderRadius: 2,
                border: 1,
                borderColor: isFileSelected ? "selection.main" : "neutrals.25",
                px: 1,
                pt: 1,
                pb: 2,
                cursor: "pointer",
                "&:hover .add-to-chat": {
                    opacity: 1,
                },
            }}
            onClick={handleClickAnywhere}
        >
            <DocumentThumbnail
                blobName={source.thumbnail_url}
                fileType={source.file_type ?? undefined}
                isUserFile={source.is_user_file}
                sx={{ mt: 0.6, cursor: "pointer" }}
            />
            {/* <img
                src={PDFIcon}
                alt="PDF icon"
                width={35}
                height={43}
                style={{ marginTop: theme.spacing(0.6), cursor: "pointer" }}
                onClick={handleClickAnywhere}
            /> */}
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
                <Typography
                    variant="body1"
                    fontWeight={450}
                    noWrap
                    color="neutrals.80"
                    sx={{ mt: 0.25, cursor: "pointer" }}
                >
                    {source.file_name.replace(FILE_EXTENSIONS_REGEX, "")}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", mt: 0.25, gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Calendar size={12} color={theme.palette.neutrals[60]} />
                        <Typography variant="caption" color="neutrals.60" noWrap>
                            {formattedDate}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        {(source.project_code != null || source.project_name != null) && (
                            <>
                                <Folder size={12} color={theme.palette.neutrals[60]} />
                                <Typography
                                    className="highlight-mask"
                                    variant="caption"
                                    sx={{
                                        color: "neutrals.60",
                                    }}
                                >
                                    {source.project_name != null
                                        ? `${source.project_name}${source.project_code != null ? ` (${formatProjectCode(source.project_code)})` : ""}`
                                        : formatProjectCode(source.project_code ?? "")}
                                </Typography>
                            </>
                        )}
                    </Box>
                    {source.tags?.map((tag, index) => (
                        <Typography
                            variant="caption"
                            sx={{ color: "neutrals.60", bgcolor: "surface.75", borderRadius: 4, py: 0.25, px: 1 }}
                            key={index}
                        >
                            • {tag}
                        </Typography>
                    ))}
                </Box>
                {cleanContent != null && (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            mt: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            cursor: "pointer",
                        }}
                        onClick={handleClickAnywhere}
                    >
                        {/* TODO: Show other pages of the source */}
                        {cleanContent}
                    </Typography>
                )}
                {shouldShowPages && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
                        {uniquePages.map(p => (
                            <FileSourceItemPage
                                page={p}
                                key={`${source.file_name}-${p.page}-${p.rank}`}
                                onClick={handleClickPage}
                                isSelected={isFileSelected && p.page === selectedPage}
                            />
                        ))}
                    </Box>
                )}
                {/* {hasTagsAndProjectCode && metadata} */}
            </Box>
            <AddToFocusedChatButton onClick={handleAddToFocusedChat} isAdded={isAddedToFocusedChat} />
        </Box>
    );
};

const AddToFocusedChatButton: React.FC<{
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isAdded: boolean;
}> = ({ onClick, isAdded }) => {
    const theme = useTheme();

    return (
        <Button
            className="add-to-chat"
            startIcon={
                isAdded ? (
                    <Minus size={16} color={theme.palette.selection.main} />
                ) : (
                    <Add size={16} color={theme.palette.selection.main} />
                )
            }
            size="small"
            onClick={onClick}
            sx={{
                position: "absolute",
                top: 8,
                right: 8,
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                pr: 1,
                borderRadius: 8,
                bgcolor: "surface.0",
                border: 1,
                borderColor: "neutrals.25",
                color: "selection.main",
                cursor: "pointer",
                opacity: isAdded ? 1 : 0,
                transition: "opacity 0.2s",
                "&:hover": {
                    bgcolor: "action.hover",
                },
                "& .MuiButton-startIcon": {
                    marginRight: 0,
                },
            }}
        >
            <Typography variant="caption" fontWeight={500}>
                {isAdded ? "Remove from chat" : "Add to chat"}
            </Typography>
        </Button>
    );
};

function formatProjectCode(projectCode: string) {
    const isNumber = /^\d+$/.test(projectCode);
    return isNumber ? `${projectCode}` : projectCode;
}

interface FileSourceItemPageProps {
    page: Page;
    isSelected: boolean;
    onClick: (page: number) => void;
}

const FileSourceItemPage: React.FC<FileSourceItemPageProps> = ({ page, isSelected, onClick }) => {
    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onClick(page.page);
        },
        [onClick, page.page],
    );

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                border: 1,
                borderColor: isSelected ? "selection.main" : "neutrals.30",
                borderRadius: 1,
                bgcolor: isSelected ? "neutrals.25" : undefined,
                px: 1,
                cursor: "pointer",
                "&:hover": {
                    bgcolor: "action.hover",
                },
            }}
            onClick={handleClick}
        >
            <Typography variant="caption" color="neutrals.90">
                {page.page_name ?? `Pg. ${page.page}`}
            </Typography>
        </Box>
    );
};
