import * as React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FileType } from "../shared/types";
import { Sources } from "./types";
import { useResizeDetector } from "react-resize-detector";
import { CitedSourceBox } from "../shared/citedSourceBox";
import { ArrowUp2 } from "iconsax-react";
import { CitedSourcePage } from "../shared/citedSourceBox";
import { ArrowDown2 } from "iconsax-react";
import { CitedText } from "../shared/citedText";
import { DateRangeCallout, useDateRange } from "./dateRangeCallout";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { Citation } from "../shared/citation";
import { CitedSourcePopover } from "../shared/citationPopovers/citedSourcePopover";
import { SearchExternallyCallout } from "./searchExternallyCallout";
const EMPTY_PAGES: CitedSourcePage[] = [];

interface TextAnswerSectionProps {
    aiAnswer: string | undefined;
    citedSources: Sources | undefined;
    isStreamingAnswer: boolean;
    isExpanded: boolean;
    showDateRange?: boolean;
    hasSuitableAnswer: boolean;
    onSearchWeb: () => void;
    /**
     * If this is provided, the answer is from project summaries, and clicking a citation will select the project with the given index.
     */
    onSelectProject: ((idx: number) => void) | undefined;
    onSelectSource: (idx: number, page: number | undefined) => void;
    onExpandedChange: (expanded: boolean) => void;
}

const MAX_HEIGHT = 152;

const NON_TEXT_HEIGHT = 90;

const EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT = 48 + 8;

export const TextAnswerSection: React.FC<TextAnswerSectionProps> = ({
    aiAnswer,
    citedSources,
    isStreamingAnswer,
    isExpanded,
    showDateRange,
    hasSuitableAnswer,
    onSelectSource,
    onExpandedChange,
    onSearchWeb,
    onSelectProject,
}) => {
    const { height, ref } = useResizeDetector({
        refreshMode: "debounce",
        refreshRate: 100,
    });
    const theme = useTheme();
    const maxHeight = hasSuitableAnswer ? MAX_HEIGHT : MAX_HEIGHT + EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT;
    const nonTextHeight = hasSuitableAnswer ? NON_TEXT_HEIGHT : NON_TEXT_HEIGHT + EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT;
    const shouldShowExpandButton = !isStreamingAnswer || (height != null && height > maxHeight - 5 - nonTextHeight);

    const dateRange = useDateRange(citedSources);

    const handleToggleExpand = React.useCallback(() => {
        onExpandedChange(!isExpanded);
    }, [isExpanded, onExpandedChange]);

    const isAnswerFromProjectSummaries = onSelectProject != null;

    const handleCitationClick = React.useCallback(
        (rank: number) => {
            if (isAnswerFromProjectSummaries) {
                onSelectProject?.(rank);
                return;
            }
            const sourcePages = citedSources?.flatMap((s, sourceIdx) => s.pages.map(p => ({ sourceIdx, page: p })));
            const clickedPage = sourcePages?.find(s => s.page.rank === rank);
            if (clickedPage == null) {
                return;
            }
            onSelectSource(clickedPage.sourceIdx, clickedPage.page.page);
        },
        [citedSources, onSelectSource, isAnswerFromProjectSummaries, onSelectProject],
    );

    const renderCitation = React.useCallback(
        (rank: number, onCitationClick: ((rank: number) => void) | undefined) => {
            if (citedSources != null && citedSources.length > 0 && !isAnswerFromProjectSummaries) {
                const source = citedSources.find(s => s.pages?.some(p => p.rank === rank));
                if (source != null) {
                    const page = source.pages?.find(p => p.rank === rank);
                    return (
                        <CitedSourcePopover
                            fileName={source.file_name}
                            fileType={source.file_type ?? undefined}
                            page={page?.page ?? 1}
                            timeCreated={source.time_created ?? undefined}
                            projectCode={source.project_code ?? undefined}
                        >
                            <Citation index={rank} onClick={onCitationClick} />
                        </CitedSourcePopover>
                    );
                }
            }
            return <Citation index={rank} onClick={onCitationClick} />;
        },
        [citedSources, isAnswerFromProjectSummaries],
    );

    return (
        <Box
            sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                rowGap: 2,
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    width: "100%",
                    maxHeight: isExpanded ? "none" : maxHeight,
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-out",
                    bgcolor: "surface.0",
                    px: 1.5,
                    py: 1.5,
                    borderRadius: 2,
                    border: 1,
                    borderColor: "neutrals.25",
                }}
            >
                {!hasSuitableAnswer && <SearchExternallyCallout onSearchWeb={onSearchWeb} sx={{ mb: 1 }} />}
                <Typography
                    ref={ref}
                    variant="body2"
                    sx={{
                        // maxHeight: isExpanded ? "none" : MAX_HEIGHT,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: isExpanded ? "none" : 3,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    <CitedText
                        text={aiAnswer ?? ""}
                        onCitationClick={handleCitationClick}
                        renderCitation={renderCitation}
                    />
                </Typography>
                {showDateRange && isExpanded && dateRange != null && <DateRangeCallout dateRange={dateRange} />}
                {isExpanded && (
                    <Box
                        sx={{
                            display: "flex",
                            overflowX: "auto",
                            mt: 2,
                            gap: 1,
                            ...getScrollbarSx("surface.0", theme.palette.mode === "dark"),
                        }}
                    >
                        {citedSources?.map((source, index) => (
                            <TextAnswerSectionCitedSourceBox
                                key={index}
                                fileName={source.file_name}
                                blobName={source.blob_name ?? undefined}
                                fileType={source.file_type ?? undefined}
                                isUserFile={source.is_user_file}
                                timeCreated={source.time_created}
                                pages={source.pages ?? EMPTY_PAGES}
                                sourceIdx={index}
                                onSelect={onSelectSource}
                            />
                        ))}
                    </Box>
                )}
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleToggleExpand}
                    sx={{
                        textTransform: "none",
                        borderColor: "neutrals.30",
                        transition: "all 0.5s ease-in-out",
                        visibility: shouldShowExpandButton ? "visible" : "hidden",
                        opacity: shouldShowExpandButton ? 1 : 0,
                        height: shouldShowExpandButton ? "auto" : 0,
                        "&:hover": {
                            bgcolor: "action.hover",
                            borderColor: "neutrals.30",
                        },
                        mt: isAnswerFromProjectSummaries ? 1 : isExpanded ? 2 : 1.5,
                        flexShrink: 0,
                        flexGrow: 0,
                    }}
                >
                    <Typography variant="caption" color="secondary.main" fontWeight={500} sx={{ mr: 0.25 }}>
                        {isExpanded ? "Show less" : "Show more"}
                    </Typography>
                    {isExpanded ? (
                        <ArrowUp2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                    ) : (
                        <ArrowDown2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                    )}
                </Button>
            </Box>
        </Box>
    );
};

interface TextAnswerSectionCitedSourceBoxProps {
    fileName: string;
    blobName: string | undefined;
    fileType: FileType | undefined;
    isUserFile: boolean;
    timeCreated: string | undefined;
    pages: CitedSourcePage[];
    sourceIdx: number;
    onSelect: (sourceIdx: number, page: number | undefined) => void;
}

const TextAnswerSectionCitedSourceBox: React.FC<TextAnswerSectionCitedSourceBoxProps> = ({
    fileName,
    blobName,
    fileType,
    isUserFile,
    timeCreated,
    pages,
    sourceIdx,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => onSelect(sourceIdx, page),
        [sourceIdx, onSelect],
    );
    const blobNameForThumbnail = React.useMemo(() => {
        return blobName?.replace(".pdf", ".jpg");
    }, [blobName]);

    return (
        <CitedSourceBox
            fileName={fileName}
            blobName={blobNameForThumbnail}
            fileType={fileType}
            isUserFile={isUserFile}
            timeCreated={timeCreated}
            pages={pages}
            onSelect={handleSelect}
        />
    );
};
