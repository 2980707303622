export const SEARCH_PARAMS = {
    QUERY: "q",
    PROJECTS: "projects",
    SOURCES: "sources",
    FILE_TYPES: "file_types",
    EARLIEST_DATE: "earliest_date",
} as const;

export function getSearchUrl(
    query: string,
    projects: string[],
    sources: string[],
    fileTypes: string[],
    earliestDate: Date | undefined,
): string {
    let url = `/search?${SEARCH_PARAMS.QUERY}=${encodeURIComponent(query)}`;
    if (projects.length > 0) {
        const encodedProjects = projects.map(p => encodeURIComponent(p)).join(",");
        url += `&${SEARCH_PARAMS.PROJECTS}=${encodedProjects}`;
    }
    if (sources.length > 0) {
        const encodedSources = sources.map(s => encodeURIComponent(s)).join(",");
        url += `&${SEARCH_PARAMS.SOURCES}=${encodedSources}`;
    }
    if (fileTypes.length > 0) {
        const encodedFileTypes = fileTypes.map(ft => encodeURIComponent(ft)).join(",");
        url += `&${SEARCH_PARAMS.FILE_TYPES}=${encodedFileTypes}`;
    }
    if (earliestDate != null) {
        url += `&${SEARCH_PARAMS.EARLIEST_DATE}=${earliestDate.toISOString().split("T")[0]}`;
    }
    return url;
}
