import { coreChatRouterCreateChat } from "../backend-client/generated/sdk.gen";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, chatsQueryOptions } from "./queryOptions";
import { SelectedFile } from "./context/focusDocuments";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { Model } from "./types";
import { FileType, SourceType } from "../shared/types";
import { getSourceFilters } from "./getSourceFilters";

export const useStartNewChatMutation = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const createChatMutation = useMutation({
        mutationFn: ({
            tenantFocusedDocumentNames,
            userFocusedDocuments,
            selectedPersona,
            isDirectBaseModelChat,
            model,
            projects,
            sourceTypes,
            fileTypes,
            earliestDate,
        }: {
            query: string;
            tenantFocusedDocumentNames: string[];
            userFocusedDocuments: Array<SelectedFile>;
            selectedPersona: SelectedPersona;
            isDirectBaseModelChat: boolean;
            model: Model | undefined;
            projects: string[];
            sourceTypes: SourceType[];
            fileTypes: FileType[];
            earliestDate: Date | undefined;
        }) =>
            coreChatRouterCreateChat({
                throwOnError: true,
                body: {
                    // TODO: Change to file IDs
                    file_scope: tenantFocusedDocumentNames,
                    user_file_scope: userFocusedDocuments.map(doc => doc.id),
                    user_persona_id:
                        selectedPersona.type !== "user" || selectedPersona.id === DEFAULT_PERSONA_ID
                            ? null
                            : selectedPersona.id,
                    tenant_persona_id:
                        selectedPersona.type !== "tenant" || selectedPersona.id === DEFAULT_PERSONA_ID
                            ? null
                            : selectedPersona.id,
                    is_direct_base_model_chat: isDirectBaseModelChat,
                    model: isDirectBaseModelChat ? model : undefined,
                    source_filters: getSourceFilters(projects, sourceTypes, fileTypes, earliestDate),
                },
            }),
        onSuccess: (response, { query }) => {
            sessionStorage.setItem(SESSION_STORAGE_NEW_CHAT_QUERY, query);
            // if (searchParams.has("sources")) {
            //     sessionStorage.setItem(SESSION_STORAGE_FOCUSED_SEARCH_FILE_PATHS, searchParams.get("sources") ?? "");
            // }

            if (response.data.unique_id != null) {
                queryClient.setQueryData(chatQueryOptions(response.data.unique_id).queryKey, response.data);
                queryClient.setQueryData(chatsQueryOptions.queryKey, old => {
                    if (old == null) return old;
                    const hasChat = old.some(c => c.unique_id === response.data.unique_id);
                    if (hasChat) return old;
                    return [...old, response.data];
                });
                navigate(`/chat/${response.data.unique_id}?new`);
            } else {
                console.error("No unique_id returned from create chat");
            }
        },
        onError: error => {
            console.error("Error creating chat:", error);
        },
    });
    return createChatMutation;
};
