import * as React from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BACKEND_URL } from "../backend-client/url";
import {
    coreChatRouterGetChat,
    CoreChatRouterRespondToUserResponse,
    coreFileStorageRouterGetFiles,
} from "../backend-client/generated";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, chatsQueryOptions, tenantQueryOptions } from "./queryOptions";
import {
    AssistantChatMessage,
    UserChatMessage,
    AssistantChatMessageSource,
    ChatOutSchema,
    CoreFileStorageRouterGetFilesResponse,
    ChatResponseSchema,
    ProjectWithAiSummary,
    MemberWithProjects,
    AssistantChatMessageWebSearchSource,
} from "../backend-client/generated/types.gen";
import { ChatSplitLayout } from "./chatSplitLayout";
import { ChatHeaderForTenant } from "./chatHeaderForTenant";
import { SelectedFile } from "./context/focusDocuments";
import { ChatInput } from "./chatInput";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { PreviewSection } from "../shared/previewSection";
import { Model } from "./types";
import { FileType, SourceType } from "../shared/types";
import { getSourceFilters } from "./getSourceFilters";
import { PersonData } from "../search/types";
import { ProjectAnswerPreviewSection } from "../search/preview/projectAnswerPreviewSection";
import { PeopleAnswerPreviewSection } from "../search/preview/peopleAnswerPreviewSection";
import { SelectedPersonData, SelectedProjectData, SelectedSourceInfo } from "./selectionState";
import { SystemMessage } from "./systemMessage";
import { convertPersonToCard, convertProjectToCard } from "./converters";
import { DEFAULT_MODEL_FOR_BASE_CHAT, isNonStreamingReasoningModel } from "./models";
const DEFAULT_SELECTED_PERSONA: SelectedPersona = {
    id: DEFAULT_PERSONA_ID,
    type: "tenant",
};

export const ChatView: React.FC = () => {
    return (
        <SidebarLayout>
            <ChatViewLayoutContent />
        </SidebarLayout>
    );
};

function filterOutNonCitedSources(
    sources: RequiredAssistantChatMessageSource[],
    citedRanks: Set<number>,
): RequiredAssistantChatMessageSource[] {
    return sources
        .map(s => ({
            ...s,
            pages: s.pages?.filter(p => citedRanks.has(p.rank)) ?? [],
        }))
        .filter(s => s.pages.length > 0);
}

function filterOutNonCitedProjects(projects: ProjectWithAiSummary[], citedIdx: Set<number>): ProjectWithAiSummary[] {
    return projects.filter((p, idx) => citedIdx.has(idx));
}

type RequiredAssistantChatMessageSource = Required<AssistantChatMessageSource>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChatViewLayoutContentProps {}

const ChatViewLayoutContent: React.FC<ChatViewLayoutContentProps> = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const [selectedSourceInfo, setSelectedSourceInfo] = React.useState<SelectedSourceInfo | undefined>(undefined);
    const [query, setQuery] = React.useState<string>("");
    const [lastQuery, setLastQuery] = React.useState<string | undefined>(undefined);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [isTimeSensitive, setIsTimeSensitive] = React.useState(false);
    const [streamedResponse, setStreamedResponse] = React.useState<string>("");
    const [streamedSources, setStreamedSources] = React.useState<RequiredAssistantChatMessageSource[] | undefined>(
        undefined,
    );
    const [streamedProjects, setStreamedProjects] = React.useState<ProjectWithAiSummary[] | undefined>(undefined);
    const [streamedPeople, setStreamedPeople] = React.useState<MemberWithProjects[] | undefined>(undefined);
    const [streamedWebSources, setStreamedWebSources] = React.useState<
        AssistantChatMessageWebSearchSource[] | undefined
    >(undefined);
    const [streamedReasoningTime, setStreamedReasoningTime] = React.useState<number | undefined>(undefined);
    const [streamedIsSearchingTheWeb, setStreamedIsSearchingTheWeb] = React.useState<boolean | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [model, setModel] = React.useState<Model | undefined>();

    const [projectFilters, setProjectFilters] = React.useState<string[]>([]);
    const [sourceTypes, setSourceTypes] = React.useState<SourceType[]>([]);
    const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
    const [earliestDate, setEarliestDate] = React.useState<Date | undefined>(undefined);
    const [isFiltersExpanded, setIsFiltersExpanded] = React.useState(false);

    const [selectedProjectData, setSelectedProjectData] = React.useState<SelectedProjectData | undefined>(undefined);

    const [selectedPersonData, setSelectedPersonData] = React.useState<SelectedPersonData | undefined>(undefined);

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollPositionRef = React.useRef(0);

    const {
        data: chat,
        isLoading,
        isError,
    } = useQuery({
        ...chatQueryOptions(chatId ?? ""),
        refetchInterval: isStreaming ? false : undefined,
        refetchOnWindowFocus: !isStreaming,
    });

    useDerivedState(chat, setModel, setProjectFilters, setSourceTypes, setFileTypes, setEarliestDate);

    const queryClient = useQueryClient();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleFetchResponse = React.useCallback(
        async (submittedQuery: string) => {
            if (chatId == null || submittedQuery.trim().length === 0) {
                return;
            }

            setIsStreaming(true);
            setStreamedResponse("");
            setStreamedSources(undefined);
            setStreamedProjects(undefined);
            setStreamedPeople(undefined);
            setStreamedWebSources(undefined);
            setStreamedReasoningTime(undefined);
            setStreamedIsSearchingTheWeb(undefined);
            setLastQuery(submittedQuery);
            setIsTimeSensitive(false);
            const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
            if (token == null) {
                console.error("No auth token found");
                setIsStreaming(false);
                return;
            }

            const url = `${BACKEND_URL}/api/chat/respond/${chatId}`;
            const abortController = new AbortController();

            const body: ChatResponseSchema = {
                query: submittedQuery,
                model: model,
                source_filters: getSourceFilters(projectFilters, sourceTypes, fileTypes, earliestDate),
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                    signal: abortController.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = "";
                let completeResponse = "";
                let completeSources: RequiredAssistantChatMessageSource[] | undefined = undefined;
                let completeProjects: ProjectWithAiSummary[] | undefined = undefined;
                let completePeople: MemberWithProjects[] | undefined = undefined;
                let completeWebSources: AssistantChatMessageWebSearchSource[] | undefined = undefined;
                let streamedIsTimeSensitive = false;
                let streamedReasoningTime: number | undefined = undefined;

                const processChunk = async ({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> => {
                    if (done) {
                        if (textBuffer.length > 0) {
                            completeResponse += textBuffer;
                            setStreamedResponse(prev => prev + textBuffer);
                        }
                        return;
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? "";

                        for (const line of lines) {
                            if (line.trim()) {
                                try {
                                    const parsed = JSON.parse(line) as CoreChatRouterRespondToUserResponse;
                                    switch (parsed.t) {
                                        case "r_chnk": {
                                            textBuffer += parsed.d;
                                            const match = textBuffer.match(/(.*?[.,!?;:\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                completeResponse += completeText;
                                                setStreamedResponse(prev => prev + completeText);
                                            }
                                            break;
                                        }
                                        case "sources":
                                            completeSources = parsed.d.map(source => ({
                                                name: source.file_name,
                                                pages: source.pages,
                                                file_type: source.file_type ?? null,
                                                blob_name: source.blob_name ?? null,
                                                is_user_file: source.is_user_file ?? null,
                                                time_created: source.time_created ?? null,
                                                project_code: source.project_code ?? null,
                                            }));
                                            setStreamedSources(completeSources);
                                            break;
                                        case "projects":
                                            completeProjects = parsed.d;
                                            setStreamedProjects(completeProjects);
                                            break;
                                        case "status":
                                            if (parsed.d === "completed" || parsed.d === "no-results") {
                                                setIsStreaming(false);
                                            }
                                            break;
                                        case "citations": {
                                            const usedRanks = new Set(parsed.d);
                                            completeSources =
                                                completeSources != null
                                                    ? filterOutNonCitedSources(completeSources, usedRanks)
                                                    : undefined;

                                            completeProjects =
                                                completeProjects != null
                                                    ? filterOutNonCitedProjects(completeProjects, usedRanks)
                                                    : undefined;

                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", parsed.d);
                                            setIsStreaming(false);
                                            break;
                                        case "is_time_sensitive":
                                            streamedIsTimeSensitive = parsed.d;
                                            setIsTimeSensitive(streamedIsTimeSensitive);
                                            break;
                                        case "people":
                                            completePeople = parsed.d;
                                            setStreamedPeople(completePeople);
                                            break;
                                        case "web_sources":
                                            completeWebSources = parsed.d;
                                            setStreamedWebSources(completeWebSources);
                                            break;
                                        case "reasoning_time":
                                            streamedReasoningTime = parsed.d;
                                            setStreamedReasoningTime(streamedReasoningTime);
                                            break;
                                        case "is_web_search":
                                            setStreamedIsSearchingTheWeb(parsed.d);
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                };

                // After we've finished reading the stream, we can apply the same logic from the former effect
                await reader.read().then(processChunk);

                if (completeResponse.trim().length > 0 && chatId != null) {
                    const numMessagesBeforeCompletion = queryClient.getQueryData(chatQueryOptions(chatId).queryKey)
                        ?.messages.length;

                    const lastUserMessage: UserChatMessage = {
                        role: "user",
                        content: submittedQuery,
                    };
                    const newAssistantMessage: AssistantChatMessage = {
                        role: "assistant",
                        content: completeResponse,
                        sources: completeSources ?? [],
                        people: completePeople,
                        web_sources: completeWebSources,
                        are_sources_recency_sensitive: streamedIsTimeSensitive,
                        projects: completeProjects,
                        reasoning_time: streamedReasoningTime,
                    };
                    queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                        if (oldData == null) return oldData;
                        return {
                            ...oldData,
                            messages: [...oldData.messages, lastUserMessage, newAssistantMessage],
                        };
                    });

                    setStreamedResponse("");
                    setLastQuery(undefined);
                    setStreamedSources(undefined);
                    setStreamedProjects(undefined);
                    setStreamedPeople(undefined);
                    setStreamedWebSources(undefined);
                    setStreamedReasoningTime(undefined);
                    setStreamedIsSearchingTheWeb(undefined);
                    setIsTimeSensitive(false);
                    // We auto-set the name on the BE for the first message, so we need to refetch if this was the first exchange
                    if (numMessagesBeforeCompletion != null && numMessagesBeforeCompletion === 0) {
                        try {
                            const refetchResponse = await coreChatRouterGetChat({ path: { chat_id: chatId } });
                            const refetchedChat = refetchResponse.data;
                            if (refetchedChat != null) {
                                queryClient.setQueryData(chatQueryOptions(chatId).queryKey, old => {
                                    if (old == null) return old;
                                    return {
                                        ...old,
                                        name: refetchedChat.name,
                                        messages:
                                            old.messages.length > numMessagesBeforeCompletion
                                                ? old.messages
                                                : [...old.messages, lastUserMessage, newAssistantMessage],
                                    };
                                });

                                queryClient.setQueryData(chatsQueryOptions.queryKey, old => {
                                    if (old == null) return old;
                                    return old.map(c =>
                                        c.unique_id === chatId ? { ...c, name: refetchedChat.name } : c,
                                    );
                                });
                            }
                        } catch (error) {
                            console.error("Failed to fetch chat:", error);
                        }
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    if (error.name === "AbortError") {
                        return;
                    }
                    console.error("Stream failed:", error);
                    setIsStreaming(false);
                }
            }

            return () => {
                abortController.abort();
            };
        },
        [chatId, earliestDate, fileTypes, model, projectFilters, queryClient, sourceTypes],
    );

    const handleSubmit = React.useCallback(() => {
        void handleFetchResponse(query);
        setQuery("");
    }, [handleFetchResponse, query]);

    const handleSelectSource = React.useCallback((systemMessageIdx: number, sourceIdx: number, page: number) => {
        setSelectedSourceInfo({ systemMessageIdx, sourceIdx, page });
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handleSelectProject = React.useCallback((systemMessageIdx: number, projectIdx: number, fileIdx?: number) => {
        setSelectedProjectData({ systemMessageIdx, projectIdx, fileIdx });
        setSelectedSourceInfo(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handleClosePreview = React.useCallback(() => {
        setSelectedSourceInfo(undefined);
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSourceInfo(prev => {
            if (prev == null) return undefined;
            return { ...prev, page: newPage };
        });
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) return "Please enter a message";
        if (isStreaming) return "Sending message…";
        if (isLoading) return "Loading chat…";
        return null;
    }, [query, isStreaming, isLoading]);

    // Handle "new" chat in search params
    React.useEffect(() => {
        if (searchParams.has("new")) {
            const storedQuery = sessionStorage.getItem(SESSION_STORAGE_NEW_CHAT_QUERY);

            if (storedQuery != null && storedQuery.trim().length > 0) {
                void handleFetchResponse(storedQuery);
                sessionStorage.removeItem(SESSION_STORAGE_NEW_CHAT_QUERY);
                setSearchParams({}, { replace: true });
            }
        }
    }, [searchParams, handleFetchResponse, setSearchParams]);

    const selectedSource = React.useMemo((): AssistantChatMessageSource | undefined => {
        if (selectedSourceInfo?.sourceIdx == null && selectedSourceInfo?.systemMessageIdx == null) return undefined;
        const message = chat?.messages[selectedSourceInfo.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.sources[selectedSourceInfo.sourceIdx];
    }, [chat?.messages, selectedSourceInfo?.systemMessageIdx, selectedSourceInfo?.sourceIdx]);

    const selectedProject = React.useMemo(() => {
        if (selectedProjectData == null) return undefined;
        const message = chat?.messages[selectedProjectData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        const project = message.projects?.[selectedProjectData.projectIdx];

        return project != null ? convertProjectToCard(project) : undefined;
    }, [chat?.messages, selectedProjectData]);

    const selectedPerson = React.useMemo((): PersonData | undefined => {
        if (selectedPersonData == null) return undefined;
        const message = chat?.messages[selectedPersonData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        const person = message.people?.[selectedPersonData.personIdx];
        return person != null ? convertPersonToCard(person) : undefined;
    }, [chat?.messages, selectedPersonData]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRef.current && !isStreaming) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
    }, [isStreaming]);

    const isInitialLoad = React.useRef(true);

    React.useEffect(() => {
        if (
            scrollRef.current &&
            chat?.messages.length != null &&
            chat.messages.length > 0 &&
            isInitialLoad.current &&
            !scrollPositionRef.current
        ) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
            isInitialLoad.current = false;
        }
    }, [chat?.messages.length, scrollRef]);

    const scrollToBottom = React.useCallback((element: HTMLDivElement) => {
        requestAnimationFrame(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: "smooth",
            });
        });
    }, []);

    const debouncedScrollToBottom = React.useMemo(() => {
        let timeoutId: number;
        return (element: HTMLDivElement) => {
            if (timeoutId) {
                cancelAnimationFrame(timeoutId);
            }
            timeoutId = requestAnimationFrame(() => {
                scrollToBottom(element);
            });
        };
    }, [scrollToBottom]);

    React.useEffect(() => {
        if (isStreaming && scrollRef.current) {
            debouncedScrollToBottom(scrollRef.current);
        }
    }, [isStreaming, debouncedScrollToBottom, streamedResponse]);

    // TODO: REMOVE PATCHEDCHAT AND JUST USE CHAT
    const patchedChat = React.useMemo(() => {
        if (isStreaming && lastQuery != null && chat != null) {
            const userMessage: UserChatMessage = { role: "user", content: lastQuery };
            return { ...chat, messages: [...chat.messages, userMessage] };
        }
        return chat;
    }, [chat, isStreaming, lastQuery]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    const initialData = React.useMemo((): CoreFileStorageRouterGetFilesResponse | null => {
        if (chat?.user_file_scope == null) return null;
        return chat.user_file_scope.map(fileId => ({
            unique_id: fileId,
            blob_name: fileId,
            file_name: fileId,
            file_type: "pdf",
            is_indexing: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        }));
    }, [chat?.user_file_scope]);

    const { data: files } = useQuery({
        queryKey: ["files", chat?.user_file_scope],
        queryFn: async () => {
            if (chat?.user_file_scope == null) return null;
            return coreFileStorageRouterGetFiles({
                body: { file_ids: chat.user_file_scope },
            }).then(res => res.data);
        },
        enabled: chat?.user_file_scope != null && chat.user_file_scope.length > 0,
        select: (data): SelectedFile[] =>
            data?.map<SelectedFile>(file => ({
                id: file.unique_id,
                name: file.file_name,
                fileType: file.file_type,
                isIndexing: file.is_indexing,
                createdAt: file.created_at,
                updatedAt: file.updated_at,
            })) ?? [],
        initialData,
    });

    const selectedPersona = React.useMemo((): SelectedPersona => {
        if (patchedChat?.user_persona != null) {
            return { id: patchedChat.user_persona.unique_id, type: "user" };
        }
        if (patchedChat?.tenant_persona != null) {
            return { id: patchedChat.tenant_persona.unique_id, type: "tenant" };
        }
        return DEFAULT_SELECTED_PERSONA;
    }, [patchedChat]);

    const handleSelectFileForProjectPreview = React.useCallback(
        (fileIdx: number | undefined) => {
            setSelectedProjectData(prev => {
                if (prev == null) return prev;
                return { ...prev, fileIdx };
            });
        },
        [setSelectedProjectData],
    );

    const handleDeselectProject = React.useCallback(() => {
        setSelectedProjectData(undefined);
    }, [setSelectedProjectData]);

    const handleSelectPerson = React.useCallback(
        (systemMessageIdx: number, personIdx: number, projectIdx?: number, fileIdx?: number) => {
            setSelectedPersonData({ systemMessageIdx, personIdx, projectIdx, fileIdx });
            setSelectedSourceInfo(undefined);
            setSelectedProjectData(undefined);
        },
        [],
    );

    const handleSelectProjectAndOptionalFileForPerson = React.useCallback(
        (projectIdx: number | undefined, fileIdx?: number) => {
            setSelectedPersonData(prev => {
                if (prev == null) return prev;
                return { ...prev, projectIdx, fileIdx };
            });
            setSelectedSourceInfo(undefined);
            setSelectedProjectData(undefined);
        },
        [],
    );

    const handleDeselectPerson = React.useCallback(() => {
        setSelectedPersonData(undefined);
    }, [setSelectedPersonData]);

    if (chatId == null) {
        return (
            <SidebarLayout>
                <Typography>No chat selected</Typography>
            </SidebarLayout>
        );
    }

    const mainContent = (
        <ChatViewContent
            chat={chat}
            lastQuery={lastQuery}
            isLoading={isLoading}
            isError={isError}
            isStreaming={isStreaming}
            isTimeSensitive={isTimeSensitive}
            streamedResponse={streamedResponse}
            streamedSources={streamedSources}
            streamedProjects={streamedProjects}
            streamedWebSources={streamedWebSources}
            streamedPeople={streamedPeople}
            streamedReasoningTime={streamedReasoningTime}
            streamedIsSearchingTheWeb={streamedIsSearchingTheWeb}
            query={query}
            disabledReason={disabledReason}
            selectedSourceInfo={selectedSourceInfo}
            selectedProjectData={selectedProjectData}
            selectedPersonData={selectedPersonData}
            model={model}
            onQueryChange={handleQueryChange}
            onPersonSelect={handleSelectPerson}
            onSubmit={handleSubmit}
            onSourceSelect={handleSelectSource}
            onProjectSelect={handleSelectProject}
            onModelChange={setModel}
        />
    );

    const header = (
        <ChatHeaderForTenant
            tenant={tenant}
            chatId={chatId}
            chatName={patchedChat?.name}
            isLoading={isLoading}
            tenantFocusedDocumentNames={chat?.file_scope}
            // TODO: what happens if a fiel is deleted that was used in focus? We should still show
            focusedDocuments={files}
            onFocusedDocumentsChange={undefined}
            selectedPersona={selectedPersona}
            onSelectedPersonaChange={undefined}
            disabledEditingFiltersReason={isStreaming ? "Sending message…" : undefined}
            projects={projectFilters}
            sourceTypes={sourceTypes}
            fileTypes={fileTypes}
            earliestDate={earliestDate}
            isFiltersExpanded={isFiltersExpanded}
            hideSourceFilters={chat?.is_direct_base_model_chat ?? false}
            onProjectsChange={setProjectFilters}
            onSourceTypesChange={setSourceTypes}
            onFileTypesChange={setFileTypes}
            onEarliestDateChange={setEarliestDate}
            onFiltersExpandedChange={setIsFiltersExpanded}
        />
    );
    return (
        <ChatSplitLayout
            header={header}
            mainContent={mainContent}
            preview={
                selectedSource != null ? (
                    <ChatPreviewSection
                        source={selectedSource}
                        page={selectedSourceInfo?.page ?? 1}
                        onPageChange={handlePageChange}
                        onClose={handleClosePreview}
                    />
                ) : selectedPerson != null ? (
                    <PeopleAnswerPreviewSection
                        person={selectedPerson}
                        projectIdx={selectedPersonData?.projectIdx}
                        fileIdx={selectedPersonData?.fileIdx}
                        onSelect={handleSelectProjectAndOptionalFileForPerson}
                        onClose={handleDeselectPerson}
                    />
                ) : selectedProject != null ? (
                    <ProjectAnswerPreviewSection
                        project={selectedProject}
                        fileIdx={selectedProjectData?.fileIdx}
                        onSelect={handleSelectFileForProjectPreview}
                        onClose={handleDeselectProject}
                    />
                ) : undefined
            }
            mainContentProps={{
                bgcolor: "primary.main",
                borderRadius: 3,
                border: 1,
                borderColor: "neutrals.30",
            }}
            scrollRef={scrollRef}
            onScroll={saveScrollPosition}
        />
    );
};

interface ChatViewContentProps {
    // scrollRef: React.RefObject<HTMLDivElement>;
    // onScroll: () => void;
    chat: ChatOutSchema | undefined;
    lastQuery: string | undefined;
    isLoading: boolean;
    isError: boolean;
    isStreaming: boolean;
    isTimeSensitive: boolean;
    streamedResponse: string;
    streamedSources: AssistantChatMessageSource[] | undefined;
    streamedProjects: ProjectWithAiSummary[] | undefined;
    streamedWebSources: AssistantChatMessageWebSearchSource[] | undefined;
    query: string;
    disabledReason: string | null;
    selectedSourceInfo: SelectedSourceInfo | undefined;
    selectedProjectData: SelectedProjectData | undefined;
    selectedPersonData: SelectedPersonData | undefined;
    streamedPeople: MemberWithProjects[] | undefined;
    streamedReasoningTime: number | undefined;
    streamedIsSearchingTheWeb: boolean | undefined;
    model: Model | undefined;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onSourceSelect: (systemMessageIdx: number, sourceIdx: number, page: number) => void;
    onProjectSelect: (systemMessageIdx: number, projectIdx: number, fileIdx?: number) => void;
    onModelChange: (model: Model) => void;
    onPersonSelect: (systemMessageIdx: number, personIdx: number, projectIdx?: number) => void;
}

const ChatViewContent: React.FC<ChatViewContentProps> = ({
    // scrollRef,
    // onScroll,
    chat,
    lastQuery,
    isLoading,
    isError,
    isStreaming,
    isTimeSensitive,
    streamedResponse,
    streamedSources,
    streamedProjects,
    streamedWebSources,
    streamedPeople,
    streamedReasoningTime,
    streamedIsSearchingTheWeb,
    query,
    disabledReason,
    selectedSourceInfo,
    selectedProjectData,
    selectedPersonData,
    model,
    onQueryChange,
    onSubmit,
    onSourceSelect,
    onProjectSelect,
    onModelChange,
    onPersonSelect,
}) => {
    const { data: tenant } = useQuery(tenantQueryOptions);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                borderRadius: 3,
                // mb: 2,
                // height: "100%",
                bgcolor: "primary.main",
            }}
        >
            <Box
                // ref={scrollRef}
                // onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    px: 2,
                    pt: 2,
                }}
            >
                {isLoading ? (
                    <Typography>Loading chat…</Typography>
                ) : isError ? (
                    <Typography>Error loading chat</Typography>
                ) : (
                    <>
                        {chat?.messages.map((message, index) =>
                            message.role === "user" ? (
                                <UserMessage key={index} content={message.content} />
                            ) : (
                                <SystemMessage
                                    key={index}
                                    content={message.content}
                                    sources={message.sources}
                                    people={message.people ?? undefined}
                                    projects={message.projects ?? undefined}
                                    webSources={message.web_sources ?? undefined}
                                    reasoningTime={message.reasoning_time ?? undefined}
                                    selectedSourceInfo={
                                        selectedSourceInfo?.systemMessageIdx === index ? selectedSourceInfo : undefined
                                    }
                                    selectedProjectData={
                                        selectedProjectData?.systemMessageIdx === index
                                            ? selectedProjectData
                                            : undefined
                                    }
                                    selectedPersonData={
                                        selectedPersonData?.systemMessageIdx === index ? selectedPersonData : undefined
                                    }
                                    systemMessageIdx={index}
                                    // TODO: Only matters for streaming
                                    isNonStreamingReasoningModel={false}
                                    isSearchingTheWeb={undefined}
                                    areSourcesRecencySensitive={message.are_sources_recency_sensitive ?? false}
                                    onSourceSelect={onSourceSelect}
                                    onProjectSelect={onProjectSelect}
                                    onPersonSelect={onPersonSelect}
                                />
                            ),
                        )}
                        {lastQuery != null && <UserMessage content={lastQuery} />}
                        {isStreaming && (
                            <SystemMessage
                                // TODO: Use same key?
                                content={streamedResponse}
                                reasoningTime={streamedReasoningTime}
                                sources={streamedSources}
                                projects={streamedProjects}
                                webSources={streamedWebSources}
                                people={streamedPeople}
                                systemMessageIdx={chat?.messages.length ?? 0}
                                onSourceSelect={undefined}
                                onProjectSelect={undefined}
                                onPersonSelect={undefined}
                                selectedSourceInfo={undefined}
                                selectedProjectData={undefined}
                                selectedPersonData={undefined}
                                areSourcesRecencySensitive={isTimeSensitive}
                                isStreaming={isStreaming}
                                isNonStreamingReasoningModel={isNonStreamingReasoningModel(
                                    model ?? DEFAULT_MODEL_FOR_BASE_CHAT,
                                )}
                                isSearchingTheWeb={streamedIsSearchingTheWeb}
                            />
                        )}
                    </>
                )}
            </Box>
            <Box
                sx={{
                    position: "sticky",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: "primary.main",
                    pb: 2,
                    maxWidth: "100%",
                    zIndex: 10,
                }}
            >
                <ChatInput
                    query={query}
                    onQueryChange={onQueryChange}
                    onQuerySubmit={onSubmit}
                    disabledReason={disabledReason}
                    attachDisabledReason={
                        (tenant?.can_chat_with_docs ?? false)
                            ? "You can only attach files to your first message. To attach new files, start a new chat"
                            : undefined
                    }
                    isDirectBaseModelChat={chat?.is_direct_base_model_chat ?? false}
                    onDirectBaseModelChatToggle={undefined}
                    model={model}
                    onModelChange={onModelChange}
                />
            </Box>
        </Box>
    );
};

// New components
const UserMessage: React.FC<{ content: string }> = ({ content }) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Box
            sx={{
                maxWidth: "75%",
                px: 2,
                py: 1.5,
                borderRadius: 2,
                bgcolor: "surface.75",
            }}
        >
            <Typography whiteSpace="pre-wrap">{content}</Typography>
        </Box>
    </Box>
);

const headerSx: SxProps<Theme> = { mb: 2 };

const ChatPreviewSection: React.FC<{
    source: AssistantChatMessageSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const previewSource = React.useMemo(() => {
        return {
            file_name: source.name,
            pages: source.pages ?? [],
            blob_name: source.blob_name ?? undefined,
            is_user_file: source.is_user_file ?? false,
            time_created: source.time_created ?? undefined,
            project_code: source.project_code ?? undefined,
        };
    }, [source]);

    const header = React.useMemo(() => {
        return (
            <Typography variant="h6" color="secondary.main" noWrap>
                {source.name}
            </Typography>
        );
    }, [source.name]);

    return (
        <PreviewSection
            source={previewSource}
            page={page}
            onPageChange={onPageChange}
            onClose={onClose}
            header={header}
            headerSx={headerSx}
        />
    );
};

function useDerivedState(
    chat: ChatOutSchema | undefined,
    setModel: (model: Model) => void,
    setProjects: (projects: string[]) => void,
    setSourceTypes: (sourceTypes: SourceType[]) => void,
    setFileTypes: (fileTypes: FileType[]) => void,
    setEarliestDate: (earliestDate: Date | undefined) => void,
) {
    React.useEffect(() => {
        if (chat?.model != null) {
            setModel(chat.model);
        }
    }, [chat?.model, setModel]);

    React.useEffect(() => {
        if (chat?.source_filters?.projects != null) {
            setProjects(chat.source_filters.projects);
        }
    }, [chat?.source_filters?.projects, setProjects]);

    React.useEffect(() => {
        if (chat?.source_filters?.source_types != null) {
            setSourceTypes(chat.source_filters.source_types);
        }
    }, [chat?.source_filters?.source_types, setSourceTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.file_types != null) {
            setFileTypes(chat.source_filters.file_types);
        }
    }, [chat?.source_filters?.file_types, setFileTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.earliest_date != null) {
            setEarliestDate(new Date(chat.source_filters.earliest_date));
        }
    }, [chat?.source_filters?.earliest_date, setEarliestDate]);
}
