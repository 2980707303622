import * as React from "react";
import { Typography, Container, Box, useTheme, Skeleton } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { useNavigate } from "react-router-dom";
import { SearchHomeInput } from "../shared/searchHomeInput";
import { getSearchUrl } from "./url";
import { SuggestedQueries } from "./suggestedQueries";
import { useQuery } from "@tanstack/react-query";
import { tenantQueryOptions } from "../chat/queryOptions";
import { Filters } from "./filters";
import { Helmet } from "react-helmet-async";
import { FileType, SourceType } from "../shared/types";
import { Model } from "../chat/types";
import { useBoolean } from "../utils/hooks";
import { useStartNewChatMutation } from "../chat/useStartNewChatMutation";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";

export const SearchHomeView: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>AnswerGrid</title>
            </Helmet>
            <SidebarLayout>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        bgcolor: "primary.main",
                        borderRadius: 3,
                        border: 1,
                        borderColor: "neutrals.30",
                    }}
                >
                    <Container
                        maxWidth="sm"
                        sx={{
                            mt: "14%",
                            minWidth: "712px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            rowGap: 2,
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 2 }}>
                            <SearchHomeViewLogoAndMessage />
                        </Box>
                        <SearchHomeViewInputAndFilters />
                    </Container>
                </Box>
            </SidebarLayout>
        </>
    );
};

const DEFAULT_LOGO_SIZE = 56;

const CUSTOM_LOGO_SIZE = 66;

const SearchHomeViewLogoAndMessage: React.FC = () => {
    const tenant = useQuery(tenantQueryOptions);

    if (tenant.data?.home_page_message != null) {
        return <SearchHomeViewCustomerLogoAndMessage />;
    }
    return (
        <>
            <Logo style={{ width: DEFAULT_LOGO_SIZE, height: DEFAULT_LOGO_SIZE }} />
            <Typography variant="h3" sx={{ color: "secondary.main", fontFamily: "Garamond-Light-Cond" }}>
                Answers at scale
            </Typography>
        </>
    );
};

const SearchHomeViewCustomerLogoAndMessage: React.FC = () => {
    const tenant = useQuery(tenantQueryOptions);
    const theme = useTheme();

    const message = (
        <Typography variant="h3" sx={{ color: "secondary.main", fontFamily: "Garamond-Light-Cond", fontSize: 40 }}>
            {tenant.data?.home_page_message ?? "Answers at scale"}
        </Typography>
    );

    if (tenant.isLoading) {
        return (
            <>
                <Skeleton width={CUSTOM_LOGO_SIZE} height={CUSTOM_LOGO_SIZE} />
                {message}
            </>
        );
    }

    if (tenant.data?.logo_url == null) {
        return (
            <>
                <Logo
                    style={{
                        width: CUSTOM_LOGO_SIZE,
                        height: CUSTOM_LOGO_SIZE,
                        color: theme.palette.secondary.main,
                    }}
                />
                {message}
            </>
        );
    }

    const firstImage = (
        <Box
            component="img"
            src={tenant.data.logo_url}
            alt={tenant.data.name}
            sx={{
                width: CUSTOM_LOGO_SIZE,
                height: CUSTOM_LOGO_SIZE,
                flexShrink: 0,
            }}
        />
    );

    return (
        <>
            {tenant.data.extra_home_page_image_url != null ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 1.5 }}>
                    {firstImage}
                    <Box
                        component="img"
                        src={tenant.data.extra_home_page_image_url}
                        alt={tenant.data.name}
                        sx={{ width: CUSTOM_LOGO_SIZE, height: CUSTOM_LOGO_SIZE, flexShrink: 0 }}
                    />
                </Box>
            ) : (
                firstImage
            )}
            {message}
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchHomeViewInputAndFiltersProps {}

const SearchHomeViewInputAndFilters: React.FC<SearchHomeViewInputAndFiltersProps> = () => {
    const [query, setQuery] = React.useState<string>("");
    const [projects, setProjects] = React.useState<string[]>([]);
    const [sourceTypes, setSourceTypes] = React.useState<SourceType[]>([]);
    const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [model, setModel] = React.useState<Model | undefined>();
    const { value: isDirectBaseModelChat, toggleValue: toggleIsDirectBaseModelChat } = useBoolean(false);
    const navigate = useNavigate();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const { mutate: createChatMutation, isPending } = useStartNewChatMutation();

    const handleQuerySubmit = React.useCallback(() => {
        if (isDirectBaseModelChat) {
            if (isPending) {
                return;
            }
            createChatMutation({
                query,
                tenantFocusedDocumentNames: [],
                userFocusedDocuments: [],
                selectedPersona: { type: "tenant", id: DEFAULT_PERSONA_ID },
                isDirectBaseModelChat,
                model,
                projects,
                sourceTypes,
                fileTypes,
                earliestDate: selectedDate,
            });
        } else {
            navigate(getSearchUrl(query, projects, sourceTypes, fileTypes, selectedDate));
        }
    }, [
        isDirectBaseModelChat,
        isPending,
        createChatMutation,
        query,
        model,
        projects,
        sourceTypes,
        fileTypes,
        selectedDate,
        navigate,
    ]);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) {
            return "Please enter a search query";
        }
        // Add more conditions here if needed
        return null;
    }, [query]);

    const handleSuggestedQueryClick = React.useCallback((selectedQuery: string) => {
        setQuery(selectedQuery);
    }, []);

    const tenant = useQuery(tenantQueryOptions);

    const hasSuggestedQueries = (tenant.data?.suggested_queries?.length ?? 0) > 0;

    return (
        <>
            <SearchHomeInput
                query={query}
                onQueryChange={handleQueryChange}
                onQuerySubmit={handleQuerySubmit}
                disabledReason={disabledReason}
                // TODO: WHY IS THIS MULTILINE?
                multiline={hasSuggestedQueries}
                isDirectBaseModelChat={isDirectBaseModelChat}
                onDirectBaseModelChatToggle={toggleIsDirectBaseModelChat}
                model={model}
                onModelChange={setModel}
            />
            {hasSuggestedQueries ? (
                <SuggestedQueries queries={tenant.data?.suggested_queries ?? []} onClick={handleSuggestedQueryClick} />
            ) : (
                <Filters
                    projects={projects}
                    onProjectsChange={setProjects}
                    sourceTypes={sourceTypes}
                    onSourceTypesChange={setSourceTypes}
                    fileTypes={fileTypes}
                    onFileTypesChange={setFileTypes}
                    earliestDate={selectedDate}
                    onEarliestDateChange={setSelectedDate}
                />
            )}
        </>
    );
};
