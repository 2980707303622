import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    Box,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Switch,
    InputBase,
    useTheme,
    InputAdornment,
} from "@mui/material";
import { Copy, TickSquare } from "iconsax-react";
import { chatQueryOptions } from "./queryOptions";
import { coreChatRouterUpdateChat } from "../backend-client/generated/sdk.gen";
import { ChatOutSchema } from "../backend-client/generated/types.gen";
import { useMemo } from "react";

interface ShareDialogProps {
    open: boolean;
    onClose: () => void;
    chatId: string;
}

export const ShareDialog: React.FC<ShareDialogProps> = ({ open, onClose, chatId }) => {
    const theme = useTheme();
    const shareLink = useMemo(() => {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port ? `:${window.location.port}` : "";
        return `${protocol}//${hostname}${port}/share/${chatId}`;
    }, [chatId]);

    const { data: chat } = useQuery(chatQueryOptions(chatId));
    const queryClient = useQueryClient();
    const updateChatMutation = useMutation({
        mutationFn: (data: { allow_link_sharing: boolean }) =>
            coreChatRouterUpdateChat({
                path: { chat_id: chatId },
                body: { ...data, messages: null, file_scope: null, user_file_scope: null },
            }),
        onSuccess: data => {
            queryClient.setQueryData(chatQueryOptions(chatId).queryKey, (old: ChatOutSchema | undefined) => {
                if (old == null || data.data == null) {
                    return undefined;
                }
                return { ...old, ...data.data };
            });
        },
    });

    const [copySuccess, setCopySuccess] = React.useState(false);

    const handleCopy = React.useCallback(() => {
        void navigator.clipboard.writeText(shareLink);
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 500);
    }, [shareLink]);

    const handleToggle = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            updateChatMutation.mutate({ allow_link_sharing: event.target.checked });
        },
        [updateChatMutation],
    );

    const isEnabled = chat?.allow_link_sharing ?? false;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    width: "100%",
                    maxWidth: "500px",
                },
            }}
        >
            <DialogContent sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Share Chat
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {isEnabled
                        ? "Link sharing is enabled. Copy the link below to share."
                        : "Link sharing is disabled. Enable it with the toggle below."}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <InputBase
                        value={shareLink}
                        startAdornment={
                            <InputAdornment position="start">
                                <Switch checked={isEnabled} onChange={handleToggle} color="secondary" />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleCopy} color="primary">
                                    {copySuccess ? (
                                        <TickSquare color={theme.palette.secondary.main} />
                                    ) : (
                                        <Copy color={theme.palette.secondary.main} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        readOnly
                        sx={{ mr: 2, p: 1, border: 1, borderColor: "neutrals.30", borderRadius: 3, width: "100%" }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};
