import * as React from "react";
import { Box, Typography, Theme, SxProps, Popper, useTheme, Paper } from "@mui/material";
import { ArchiveBook, Calendar, Folder } from "iconsax-react";
import { format } from "date-fns";
import { FILE_EXTENSIONS_REGEX } from "../../search/fileExtensions";
import { FileType } from "../types";

type CitedSourcePopoverProps = {
    children: React.ReactNode;
    fileName: string;
    timeCreated?: string;
    fileType: FileType | undefined;
    page: number;
    projectCode: string | undefined;
    sx?: SxProps<Theme>;
};

export const CitedSourcePopover = React.memo(
    ({ children, fileName, timeCreated, page, projectCode, sx }: CitedSourcePopoverProps) => {
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

        const handlePopoverOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handlePopoverClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);

        const open = Boolean(anchorEl);

        const formattedDate = React.useMemo(() => {
            if (timeCreated == null) return null;
            return format(new Date(timeCreated), "MMM yyyy");
        }, [timeCreated]);

        const theme = useTheme();
        return (
            <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={[
                    {
                        display: "inline-block",
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
            >
                {children}
                <Popper
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    placement="top"
                    modifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [0, 2],
                            },
                        },
                    ]}
                >
                    <Paper
                        elevation={0}
                        sx={{ borderRadius: 2, bgcolor: "surface.0", border: 1, borderColor: "neutrals.20" }}
                    >
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                maxWidth: 250,
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                <ArchiveBook size={12} color={theme.palette.neutrals[80]} />
                                <Typography variant="caption" color="neutrals.80">
                                    Page {page}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1.5 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2" fontWeight={500} sx={{ mb: 0.5 }} color="neutrals.80">
                                        {fileName.replace(FILE_EXTENSIONS_REGEX, "")}
                                    </Typography>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
                                        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                            {formattedDate != null && (
                                                <>
                                                    <Calendar size={14} color={theme.palette.neutrals[60]} />
                                                    <Typography variant="caption" color="neutrals.60">
                                                        {formattedDate}
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                        {projectCode != null && (
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                <Folder size={14} color={theme.palette.neutrals[60]} />
                                                <Typography variant="caption" color="neutrals.60">
                                                    {`#${projectCode}`}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Popper>
            </Box>
        );
    },
);

CitedSourcePopover.displayName = "CitedSourcePopover";
