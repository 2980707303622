import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import {
    Box,
    Typography,
    Skeleton,
    IconButton,
    Button,
    InputAdornment,
    TextField,
    useTheme,
    Theme,
    SxProps,
    Menu,
    MenuItem,
    Popover,
    CircularProgress,
} from "@mui/material";
import {
    AssistantChatMessage,
    AssistantChatMessageSource,
    ChatSourceFilters,
    CoreSearchRouterSearchResponse,
    SearchInputSchema,
    UserChatMessage,
} from "../backend-client/generated";
import { BACKEND_URL } from "../backend-client/url";
import { Close as CloseIcon } from "@mui/icons-material";
import {
    InfoCircle,
    Message,
    SearchNormal1,
    Like1 as ThumbsUp,
    Dislike as ThumbsDown,
    MessageText,
    Send,
    Export,
} from "iconsax-react";
import { Filters } from "./filters";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    coreChatRouterCreateChat,
    coreSearchRouterSubmitSearchFeedback,
    coreSearchRouterSearchProjects,
    coreClientRouterGenerateLogosSlide,
} from "../backend-client/generated/sdk.gen";
import { useSnackbar } from "notistack";
import { chatQueryOptions, tenantQueryOptions } from "../chat/queryOptions";
import { PersonData, Project, Sources } from "./types";
import { Source } from "./types";
import { SourcesSection } from "./sourcesSection";
import { SearchViewHeader } from "./searchViewHeader";
import { TextAnswerSection } from "./textAnswerSection";
import { areSetsEqual } from "../utils/sets";
import { comparatorOnFields } from "../utils/comparators";
import { getSearchUrl, SEARCH_PARAMS } from "./url";
import { SplitLayout } from "../shared/splitLayout";
import { useLeftAlignPadding } from "../shared/useLeftAlignPadding";
import { faker } from "@faker-js/faker";
import { Avatar } from "@mui/material";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { isNonNullable } from "../utils/isNonNullable";
import { PreviewSection } from "../shared/previewSection";
import { H } from "highlight.run";
import { ReactComponent as FeedbackIcon } from "../assets/feedback.svg";
import { PersonAnswerSection } from "./personAnswerSection";
import { PeopleAnswerPreviewSection } from "./preview/peopleAnswerPreviewSection";
import { asFileType, asSourceType, FileType, SourceType } from "../shared/types";
import { getSourceFilters } from "../chat/getSourceFilters";
import { useDebounce } from "../hooks/useDebounce";
import { ProjectAnswerSection } from "./projectAnswerSection";
import { ProjectAnswerPreviewSection } from "./preview/projectAnswerPreviewSection";
import { useBoolean } from "../utils/hooks";
import { useStartNewChatMutation } from "../chat/useStartNewChatMutation";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { nullToUndefined } from "../utils/nullToUndefined";

export const SearchView: React.FC = () => {
    return (
        <SidebarLayout>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    borderRadius: 3,
                    bgcolor: "surface.50",
                    rowGap: 1,
                    overflowY: "hidden",
                    overflowX: "hidden",
                    // position: "relative",
                }}
            >
                <SearchViewContent />
            </Box>
        </SidebarLayout>
    );
};

type SelectedPersonData = {
    personIdx: number;
    projectIdx?: number;
    fileIdx?: number;
};

type SelectedProjectData = {
    projectIdx: number;
    fileIdx?: number;
};

const SearchViewContent: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sources, setSources] = React.useState<Sources | undefined>(undefined);
    const [ranksOfCitedPages, setRanksOfCitedPages] = React.useState<number[] | undefined>(undefined);
    const [aiAnswer, setAiAnswer] = React.useState<string | undefined>(undefined);
    const [hasNoResults, setHasNoResults] = React.useState<boolean>(false);
    const [hasSuitableAnswer, setHasSuitableAnswer] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isWaitingForSources, setIsWaitingForSources] = React.useState(true);
    const [isStreamingAnswer, setIsStreamingAnswer] = React.useState(false);
    const [selectedSource, setSelectedSource] = React.useState<{ idx: number; page: number | undefined } | undefined>(
        undefined,
    );
    const [people, setPeople] = React.useState<PersonData[] | undefined>(undefined);
    const [projects, setProjects] = React.useState<Project[] | undefined>(undefined);
    const [minDistanceForMoreProjects, setMinDistanceForMoreProjects] = React.useState<number | undefined>(undefined);
    const [focusedChatSourceIdxs, setFocusedChatSourceIdxs] = React.useState<number[]>([]);
    const [isAnswerExpanded, setIsAnswerExpanded] = React.useState(false);
    const [isTimeSensitive, setIsTimeSensitive] = React.useState(false);
    const [selectedPersonData, setSelectedPersonData] = React.useState<SelectedPersonData | undefined>(undefined);
    const [selectedProjectData, setSelectedProjectData] = React.useState<SelectedProjectData | undefined>(undefined);

    const {
        value: isProjectAnswerExpanded,
        setTrue: setTrueIsProjectAnswerExpanded,
        toggleValue: toggleIsProjectAnswerExpanded,
    } = useBoolean(false);
    const [isLoadingMoreProjects, setIsLoadingMoreProjects] = React.useState(false);
    const [hasLoadedMoreProjects, setHasLoadedMoreProjects] = React.useState(false);

    const query = React.useMemo(() => {
        return new URLSearchParams(location.search).get(SEARCH_PARAMS.QUERY) || "";
    }, [location.search]);

    const filterProjects = React.useMemo(() => {
        return new URLSearchParams(location.search).get(SEARCH_PARAMS.PROJECTS)?.split(",") || [];
    }, [location.search]);

    const filterSources = React.useMemo<SourceType[]>(() => {
        const sourceTypes = new URLSearchParams(location.search).get(SEARCH_PARAMS.SOURCES)?.split(",") || [];
        return sourceTypes.map(asSourceType).filter(isNonNullable);
    }, [location.search]);

    const filterFileTypes = React.useMemo(() => {
        const fileTypes = new URLSearchParams(location.search).get(SEARCH_PARAMS.FILE_TYPES)?.split(",") || [];
        return fileTypes.map(asFileType).filter(isNonNullable);
    }, [location.search]);

    const filterEarliestDate = React.useMemo(() => {
        const dateStr = new URLSearchParams(location.search).get(SEARCH_PARAMS.EARLIEST_DATE);
        return dateStr != null ? new Date(dateStr) : undefined;
    }, [location.search]);

    const resetState = React.useCallback(() => {
        setSources(undefined);
        setRanksOfCitedPages(undefined);
        setAiAnswer(undefined);
        setPeople(undefined);
        setProjects(undefined);
        setMinDistanceForMoreProjects(undefined);
        setHasLoadedMoreProjects(false);
        setIsLoadingMoreProjects(false);
        setHasSuitableAnswer(true);
        setHasNoResults(false);
        setErrorMessage(undefined);
        setIsWaitingForSources(true);
        setSelectedSource(undefined);
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handleSearchBarSearch = React.useCallback(
        (
            newQuery: string,
            newProjects: string[],
            newSources: string[],
            newFileTypes: FileType[],
            newEarliestDate: Date | undefined,
        ) => {
            resetState();
            navigate(getSearchUrl(newQuery, newProjects, newSources, newFileTypes, newEarliestDate));
        },
        [navigate, resetState],
    );

    useSearchSSEStream({
        query,
        filterProjects,
        filterSources,
        filterFileTypes,
        filterEarliestDate,
        minDistanceForMoreProjects,
        setSources,
        setCitedSourceIds: setRanksOfCitedPages,
        setAiAnswer,
        setHasNoResults,
        setErrorMessage,
        setIsStreamingAnswer,
        setIsWaitingForSources,
        setPeople,
        setProjects,
        setIsTimeSensitive,
        setMinDistanceForMoreProjects,
        setHasAnswer: setHasSuitableAnswer,
    });

    const sourcesWithCitedPages = React.useMemo((): Sources | undefined => {
        if (ranksOfCitedPages == null || sources == null) {
            return undefined;
        }
        return (
            sources
                // Remove sources that don't have any cited pages
                .filter(s => s.pages.some(p => ranksOfCitedPages.includes(p.rank)))
                // Remove pages that aren't cited in the file sources
                .map(s => ({ ...s, pages: s.pages.filter(p => ranksOfCitedPages.includes(p.rank)) }))
        );
    }, [ranksOfCitedPages, sources]);

    const selectedSourceData = React.useMemo(() => {
        if (sources == null || selectedSource?.idx == null) {
            return undefined;
        }
        return sources[selectedSource.idx];
    }, [sources, selectedSource?.idx]);

    const selectedSourcePage = React.useMemo(() => {
        return selectedSource?.page ?? 1;
    }, [selectedSource?.page]);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSource(prev => {
            if (prev == null) {
                return undefined;
            }
            return { ...prev, page: newPage };
        });
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handleClosePreview = React.useCallback(() => {
        setSelectedSource(undefined);
    }, []);

    const handleSelectSource = React.useCallback((idx: number, page?: number) => {
        setSelectedSource({ idx, page });
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
    }, []);

    const handleSelectPerson = React.useCallback((personIdx: number, projectIdx?: number, fileIdx?: number) => {
        setSelectedPersonData({ personIdx, projectIdx, fileIdx });
        setSelectedSource(undefined);
        setSelectedProjectData(undefined);
    }, []);

    // The sourcesWithCitedPages only contains the sources that have cited pages, so the indices
    // don't match up with the sources array. This logic finds the corresponding index in the
    // sources array and sets the selected source using that.
    const handleSelectCitedSource = React.useCallback(
        (idx: number, page?: number) => {
            const source = sourcesWithCitedPages?.[idx];
            const idxInAllSources = sources?.findIndex(s => s.file_name === source?.file_name);
            if (idxInAllSources == null) {
                return;
            }
            setSelectedSource({ idx: idxInAllSources, page });
            setSelectedProjectData(undefined);
            setSelectedPersonData(undefined);
        },
        [sources, sourcesWithCitedPages],
    );

    const patchedIsStreamingAnswer = isStreamingAnswer && errorMessage == null && !isWaitingForSources;

    const handleChatWithSources = React.useCallback(() => {
        if (focusedChatSourceIdxs.length === 0 || sources == null) {
            return;
        }
        navigate(`/chat?sources=${focusedChatSourceIdxs.map(idx => sources[idx].file_name).join(",")}`);
    }, [focusedChatSourceIdxs, sources, navigate]);

    const selectedPerson = React.useMemo(() => {
        if (selectedPersonData == null || people == null) {
            return undefined;
        }
        return people[selectedPersonData.personIdx];
    }, [selectedPersonData, people]);

    const handleSelectProjectOrFileForPeopleAnswer = React.useCallback(
        (projectIdx: number | undefined, fileIdx: number | undefined) => {
            setSelectedPersonData(prev => {
                if (prev == null) {
                    return undefined;
                }
                return { ...prev, projectIdx, fileIdx };
            });
        },
        [],
    );

    const handleDeselectPerson = React.useCallback(() => {
        setSelectedPersonData(undefined);
        setSelectedSource(undefined);
        setSelectedProjectData(undefined);
    }, []);

    const handleSelectProjectOrFileForProjectsAnswer = React.useCallback((projectIdx: number, fileIdx?: number) => {
        setSelectedProjectData({ projectIdx, fileIdx });
        setSelectedPersonData(undefined);
        setSelectedSource(undefined);
    }, []);

    const selectedProject = React.useMemo(() => {
        if (selectedProjectData == null || projects == null) {
            return undefined;
        }
        return projects[selectedProjectData.projectIdx];
    }, [selectedProjectData, projects]);

    const handleSelectFileForProjectPreview = React.useCallback((fileIdx: number | undefined) => {
        setSelectedProjectData(prev => {
            if (prev == null) {
                return undefined;
            }
            return { ...prev, fileIdx };
        });
    }, []);

    const handleDeselectProject = React.useCallback(() => {
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
        setSelectedSource(undefined);
    }, []);

    // Pass this to ProjectAnswerSection
    const canLoadMoreProjects = minDistanceForMoreProjects != null;

    const loadMoreProjects = useLoadMoreProjects(setProjects, setSources);
    const handleLoadMoreProjects = React.useCallback(async () => {
        if (minDistanceForMoreProjects == null) {
            return;
        }
        setIsLoadingMoreProjects(true);
        await loadMoreProjects(query, minDistanceForMoreProjects);
        setHasLoadedMoreProjects(true);
        setIsLoadingMoreProjects(false);
        setTrueIsProjectAnswerExpanded();
    }, [loadMoreProjects, query, minDistanceForMoreProjects, setTrueIsProjectAnswerExpanded]);

    if (errorMessage != null) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <InfoCircle size="32" color="red" />
                <Typography variant="body1" color="error" sx={{ ml: 2, maxWidth: "400px" }}>
                    {errorMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <SplitLayout
                header={
                    <SearchBarSection
                        query={query}
                        projects={filterProjects}
                        sourceTypes={filterSources}
                        fileTypes={filterFileTypes}
                        earliestDate={filterEarliestDate}
                        onSearch={handleSearchBarSearch}
                    />
                }
                mainContent={
                    isWaitingForSources ? (
                        <SkeletonAnswerSection />
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {!hasNoResults && (
                                <AnswerSection
                                    isTimeSensitive={isTimeSensitive}
                                    aiAnswer={aiAnswer}
                                    isStreamingAnswer={patchedIsStreamingAnswer}
                                    citedSources={sourcesWithCitedPages}
                                    filterProjects={filterProjects}
                                    filterSources={filterSources}
                                    filterFileTypes={filterFileTypes}
                                    filterEarliestDate={filterEarliestDate}
                                    query={query}
                                    people={people}
                                    projects={projects}
                                    isExpanded={isAnswerExpanded}
                                    hasSuitableAnswer={hasSuitableAnswer}
                                    selectedProject={selectedProjectData}
                                    isProjectAnswerExpanded={isProjectAnswerExpanded}
                                    hasLoadedMoreProjects={hasLoadedMoreProjects}
                                    canLoadMoreProjects={canLoadMoreProjects}
                                    isLoadingMoreProjects={isLoadingMoreProjects}
                                    onToggleProjectAnswerExpanded={toggleIsProjectAnswerExpanded}
                                    onSelectSource={handleSelectCitedSource}
                                    onSelectPerson={handleSelectPerson}
                                    onExpandedChange={setIsAnswerExpanded}
                                    onSelectProject={handleSelectProjectOrFileForProjectsAnswer}
                                    onLoadMoreProjects={handleLoadMoreProjects}
                                />
                            )}
                            <SourcesSection
                                sources={sources}
                                onSourceSelect={handleSelectSource}
                                focusedChatSourceIdxs={focusedChatSourceIdxs}
                                onFocusedChatSourceIdxsChange={setFocusedChatSourceIdxs}
                                selectedFileName={selectedSourceData?.file_name}
                                selectedPage={selectedSourcePage}
                            />
                        </Box>
                    )
                }
                preview={
                    selectedSourceData != null ? (
                        <SearchPreviewSection
                            source={selectedSourceData}
                            page={selectedSourcePage}
                            onPageChange={handlePageChange}
                            onClose={handleClosePreview}
                        />
                    ) : selectedPerson != null ? (
                        <PeopleAnswerPreviewSection
                            person={selectedPerson}
                            projectIdx={selectedPersonData?.projectIdx}
                            fileIdx={selectedPersonData?.fileIdx}
                            onSelect={handleSelectProjectOrFileForPeopleAnswer}
                            onClose={handleDeselectPerson}
                        />
                    ) : selectedProject != null ? (
                        <ProjectAnswerPreviewSection
                            project={selectedProject}
                            fileIdx={selectedProjectData?.fileIdx}
                            onSelect={handleSelectFileForProjectPreview}
                            onClose={handleDeselectProject}
                        />
                    ) : undefined
                }
                mainContentProps={{
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                }}
            />
            {focusedChatSourceIdxs.length > 0 && (
                <Button
                    sx={{
                        position: "absolute",
                        width: "140px",
                        bottom: "10px",
                        left: 0,
                        transform: "translateX(calc(50vw - 70px))",
                        px: 1,
                        py: 0.25,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={handleChatWithSources}
                >
                    <Typography variant="caption">
                        Chat with{" "}
                        {focusedChatSourceIdxs.length === 1 ? "1 source" : `${focusedChatSourceIdxs.length} sources`}
                    </Typography>
                </Button>
            )}
        </>
    );
};

function constructSearchUrl() {
    return `${BACKEND_URL}/api/search/search`;
}

function asSourceFilter(source: string): NonNullable<SearchInputSchema["filter_source"]>[number] | undefined {
    switch (source) {
        case "answergrid":
            return "answergrid";
        case "fusioo":
            return "fusioo";
        default:
            console.warn(`Unknown source: ${source}`);
            return undefined;
    }
}

function asFileTypeFilter(fileType: string): NonNullable<SearchInputSchema["filter_file_type"]>[number] | undefined {
    switch (fileType) {
        case "pdf":
            return "pdf";
        case "docx":
            return "docx";
        case "xlsx":
            return "xlsx";
        case "pptx":
            return "pptx";
        default:
            console.warn(`Unknown file type: ${fileType}`);
            return undefined;
    }
}

function useSearchSSEStream({
    query,
    filterProjects,
    filterSources,
    filterFileTypes,
    filterEarliestDate,
    minDistanceForMoreProjects,
    setSources,
    setCitedSourceIds,
    setAiAnswer,
    setHasNoResults,
    setErrorMessage,
    setIsWaitingForSources,
    setIsStreamingAnswer,
    setPeople,
    setIsTimeSensitive,
    setProjects,
    setMinDistanceForMoreProjects,
    setHasAnswer,
}: {
    query: string;
    filterProjects: string[];
    filterSources: string[];
    filterFileTypes: string[];
    filterEarliestDate: Date | undefined;
    minDistanceForMoreProjects: number | undefined;
    setSources: React.Dispatch<React.SetStateAction<Sources | undefined>>;
    setCitedSourceIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
    setAiAnswer: React.Dispatch<React.SetStateAction<string | undefined>>;
    setHasNoResults: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsWaitingForSources: React.Dispatch<React.SetStateAction<boolean>>;
    setIsStreamingAnswer: React.Dispatch<React.SetStateAction<boolean>>;
    setPeople: React.Dispatch<React.SetStateAction<PersonData[] | undefined>>;
    setIsTimeSensitive: React.Dispatch<React.SetStateAction<boolean>>;
    setProjects: React.Dispatch<React.SetStateAction<Project[] | undefined>>;
    setMinDistanceForMoreProjects: React.Dispatch<React.SetStateAction<number | undefined>>;
    setHasAnswer: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    React.useEffect(() => {
        if (query.length === 0) {
            return;
        }

        setIsWaitingForSources(true); // Set loading when starting a new search

        const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
        if (token == null) {
            setErrorMessage("You must be logged in to search.");
            setIsWaitingForSources(false);
            return;
        }

        // Construct the endpoint URL. It should now be a POST endpoint.
        const url = constructSearchUrl();
        const abortController = new AbortController();

        const body: SearchInputSchema = {
            query,
            filter_project: filterProjects,
            filter_source: filterSources.map(asSourceFilter).filter(isNonNullable),
            filter_file_type: filterFileTypes.map(asFileTypeFilter).filter(isNonNullable),
            filter_earliest_date: filterEarliestDate?.toISOString().split("T")[0],
        };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // If your backend expects auth
            },
            body: JSON.stringify(body),
            signal: abortController.signal,
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body?.getReader();
                if (!reader) {
                    throw new Error("No readable stream returned by the server.");
                }

                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = ""; // Add a text buffer for complete words

                async function processChunk({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> {
                    if (done) {
                        // Flush any remaining text when stream is done
                        if (textBuffer.length > 0) {
                            setAiAnswer(prevAnswer => (prevAnswer ?? "") + textBuffer);
                        }
                        return Promise.resolve(undefined);
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? ""; // Keep the last partial line

                        for (let line of lines) {
                            line = line.trim();
                            if (line) {
                                try {
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    const response: CoreSearchRouterSearchResponse = JSON.parse(line);
                                    switch (response.t) {
                                        case "status":
                                            switch (response.d) {
                                                case "no-results":
                                                    setHasNoResults(true);
                                                    setIsWaitingForSources(false);
                                                    break;
                                                case "completed":
                                                    setIsStreamingAnswer(false);
                                                    break;
                                            }
                                            break;
                                        case "sources":
                                            setSources(response.d);
                                            setIsWaitingForSources(false);
                                            break;
                                        case "r_chnk": {
                                            setIsStreamingAnswer(true);
                                            // Buffer the text until we have complete words
                                            textBuffer += response.d;
                                            // Match up to the last space, punctuation, or citation closing bracket
                                            const match = textBuffer.match(/(.*?[.,!?;:\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                setAiAnswer(prevAnswer => (prevAnswer ?? "") + completeText);
                                            }
                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", response.d);
                                            setErrorMessage(response.d);
                                            setIsStreamingAnswer(false);
                                            // Once an error is received, we might want to stop reading.
                                            abortController.abort();
                                            break;
                                        case "citations":
                                            setCitedSourceIds(response.d);
                                            break;
                                        case "people":
                                            if (response.d.length > 0) {
                                                setPeople(response.d);
                                                setIsWaitingForSources(false);
                                            }
                                            break;
                                        case "is_time_sensitive":
                                            setIsTimeSensitive(response.d);
                                            break;
                                        case "projects":
                                            setProjects(response.d);
                                            break;
                                        case "max_distance_considered":
                                            setMinDistanceForMoreProjects(response.d ?? undefined);
                                            break;
                                        case "has_answer":
                                            setHasAnswer(response.d);
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                }

                return reader.read().then(processChunk);
            })
            .catch((error: Error) => {
                if (error.name === "AbortError") {
                    // This is expected when the fetch is aborted.
                    return;
                }
                // Handle any fetch or parsing error
                console.error("Stream failed:", error);
                setErrorMessage("Stream failed");
                // setErrorMessage(error.message);
                setIsStreamingAnswer(false);
                setIsWaitingForSources(false);
            });

        return () => {
            // Cleanup: Abort the fetch and close the stream if the component unmounts
            abortController.abort();
        };
    }, [
        query,
        filterSources,
        filterFileTypes,
        setAiAnswer,
        setCitedSourceIds,
        setErrorMessage,
        setHasNoResults,
        setIsStreamingAnswer,
        setIsWaitingForSources,
        setSources,
        filterProjects,
        filterEarliestDate,
        setIsTimeSensitive,
        setPeople,
        setProjects,
        minDistanceForMoreProjects,
        setMinDistanceForMoreProjects,
        setHasAnswer,
    ]);
}

const SearchBarSection: React.FC<{
    query: string;
    projects: string[];
    sourceTypes: SourceType[];
    fileTypes: FileType[];
    earliestDate: Date | undefined;
    onSearch: (
        newQuery: string,
        newProjects: string[],
        newSourceTypes: SourceType[],
        newFileTypes: FileType[],
        newEarliestDate: Date | undefined,
    ) => void;
}> = ({ query, projects, sourceTypes, fileTypes, earliestDate, onSearch }) => {
    const [inputValue, setInputValue] = React.useState(query);
    const [newProjects, setNewProjects] = React.useState(projects);
    const [newSourceTypes, setNewSourceTypes] = React.useState(sourceTypes);
    const [newFileTypes, setNewFileTypes] = React.useState(fileTypes);
    const [newEarliestDate, setNewEarliestDate] = React.useState<Date | undefined>(earliestDate);

    const handleSubmit = React.useCallback(
        (event?: React.FormEvent) => {
            event?.preventDefault();

            if (
                hasQueryOrFiltersChanged(
                    projects,
                    newProjects,
                    sourceTypes,
                    newSourceTypes,
                    fileTypes,
                    newFileTypes,
                    earliestDate,
                    newEarliestDate,
                    query,
                    inputValue,
                )
            ) {
                onSearch(
                    inputValue.trim(),
                    Array.from(new Set(newProjects)).sort(comparatorOnFields(project => [project])),
                    Array.from(new Set(newSourceTypes)).sort(comparatorOnFields(sourceType => [sourceType])),
                    Array.from(new Set(newFileTypes)).sort(comparatorOnFields(fileType => [fileType])),
                    newEarliestDate,
                );
            }
        },
        [
            projects,
            newProjects,
            sourceTypes,
            newSourceTypes,
            fileTypes,
            newFileTypes,
            earliestDate,
            newEarliestDate,
            query,
            inputValue,
            onSearch,
        ],
    );

    const handleClear = React.useCallback(() => {
        setInputValue("");
    }, []);

    const theme = useTheme();

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, []);

    const leftAlignPadding = useLeftAlignPadding();

    const debouncedInputValue = useDebounce(inputValue, 200);

    const showGoButton = React.useMemo(() => {
        return hasQueryOrFiltersChanged(
            projects,
            newProjects,
            sourceTypes,
            newSourceTypes,
            fileTypes,
            newFileTypes,
            earliestDate,
            newEarliestDate,
            query,
            debouncedInputValue,
        );
    }, [
        projects,
        newProjects,
        sourceTypes,
        newSourceTypes,
        fileTypes,
        newFileTypes,
        earliestDate,
        newEarliestDate,
        query,
        debouncedInputValue,
    ]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                bgcolor: "primary.main",
                py: 2,
                pl: leftAlignPadding,
                borderRadius: 3,
                minWidth: 1300,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    minWidth: 700,
                    maxWidth: "calc(60% - 200px)",
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    value={inputValue}
                    size="small"
                    onChange={handleChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchNormal1 size={20} color={theme.palette.secondary.main} />
                            </InputAdornment>
                        ),
                        endAdornment: inputValue && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClear} edge="end" size="small">
                                    <CloseIcon
                                        sx={theme => ({ width: 16, height: 16, color: theme.palette.neutrals[80] })}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                        sx: {
                            height: 48,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 8,
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "neutrals.50",
                                borderWidth: 1,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "neutrals.50",
                                borderWidth: 1,
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "neutrals.80",
                        },
                    }}
                />
                <Filters
                    projects={newProjects}
                    onProjectsChange={setNewProjects}
                    sourceTypes={newSourceTypes}
                    onSourceTypesChange={setNewSourceTypes}
                    fileTypes={newFileTypes}
                    onFileTypesChange={setNewFileTypes}
                    earliestDate={newEarliestDate}
                    onEarliestDateChange={setNewEarliestDate}
                    showGoButton={showGoButton}
                    onGoClick={handleSubmit}
                    sx={{ alignSelf: "stretch" }}
                />
            </Box>
        </Box>
    );
};

function hasQueryOrFiltersChanged(
    oldProjects: string[],
    newProjects: string[],
    oldSourceTypes: SourceType[],
    newSourceTypes: SourceType[],
    oldFileTypes: FileType[],
    newFileTypes: FileType[],
    oldEarliestDate: Date | undefined,
    newEarliestDate: Date | undefined,
    oldQuery: string,
    newQuery: string,
) {
    return (
        oldQuery !== newQuery ||
        !areSetsEqual(new Set(oldProjects), new Set(newProjects)) ||
        !areSetsEqual(new Set(oldSourceTypes), new Set(newSourceTypes)) ||
        !areSetsEqual(new Set(oldFileTypes), new Set(newFileTypes)) ||
        oldEarliestDate !== newEarliestDate
    );
}

const TEXT_ANSWER_TOOLTIP = "This answer was generated by an AI based on the sources that matched your search query.";
const PERSON_ANSWER_TOOLTIP = "These people have worked on projects that matched your search query.";
const PROJECT_ANSWER_TOOLTIP = "These projects matched your search query.";

interface AssistantChatMessageWithMandatorySourceFields extends AssistantChatMessage {
    sources: Array<AssistantChatMessageSourceWithMandatoryFields>;
}

function getAnswerTooltip(people: PersonData[] | undefined, projects: Project[] | undefined) {
    if (people != null) {
        return PERSON_ANSWER_TOOLTIP;
    }
    if (projects != null) {
        return PROJECT_ANSWER_TOOLTIP;
    }
    return TEXT_ANSWER_TOOLTIP;
}

type AssistantChatMessageSourceWithMandatoryFields = Required<AssistantChatMessageSource>;

interface AnswerSectionProps {
    aiAnswer: string | undefined;
    isStreamingAnswer: boolean;
    citedSources: Sources | undefined;
    query: string;
    people: PersonData[] | undefined;
    projects: Project[] | undefined;
    sx?: SxProps<Theme>;
    isExpanded: boolean;
    filterProjects: string[];
    filterSources: SourceType[];
    filterFileTypes: FileType[];
    filterEarliestDate: Date | undefined;
    isTimeSensitive: boolean;
    selectedProject: SelectedProjectData | undefined;
    isProjectAnswerExpanded: boolean;
    hasLoadedMoreProjects: boolean;
    canLoadMoreProjects: boolean;
    isLoadingMoreProjects: boolean;
    onToggleProjectAnswerExpanded: () => void;
    hasSuitableAnswer: boolean;
    onSelectSource: (idx: number, page: number | undefined) => void;
    onSelectPerson: (idx: number, projectIdx: number | undefined) => void;
    onSelectProject: (idx: number) => void;
    onExpandedChange: (expanded: boolean) => void;
    onLoadMoreProjects: () => Promise<void>;
}

const AnswerSection: React.FC<AnswerSectionProps> = ({
    aiAnswer,
    isStreamingAnswer,
    citedSources,
    query,
    people,
    projects,
    sx,
    isExpanded,
    filterProjects,
    filterSources,
    filterFileTypes,
    filterEarliestDate,
    isTimeSensitive,
    selectedProject,
    isProjectAnswerExpanded,
    hasLoadedMoreProjects,
    canLoadMoreProjects,
    isLoadingMoreProjects,
    hasSuitableAnswer,
    onSelectSource,
    onSelectPerson,
    onSelectProject,
    onToggleProjectAnswerExpanded,
    onExpandedChange,
    onLoadMoreProjects,
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const createChatMutation = useMutation({
        mutationFn: ({
            messages,
            name,
            sourceFilters,
        }: {
            messages: Array<AssistantChatMessageWithMandatorySourceFields | UserChatMessage>;
            name: string;
            sourceFilters: ChatSourceFilters | undefined;
        }) =>
            coreChatRouterCreateChat({
                throwOnError: true,
                body: { messages, name, source_filters: sourceFilters },
            }),
        onSuccess: data => {
            // Navigate to the newly created chat
            if (data.data.unique_id != null) {
                queryClient.setQueryData(chatQueryOptions(data.data.unique_id).queryKey, oldData => {
                    if (oldData == null) {
                        return data.data;
                    }
                    return {
                        ...oldData,
                        data: data.data,
                    };
                });
                navigate(`/chat/${data.data.unique_id}`);
            } else {
                enqueueSnackbar("Failed to create chat", { variant: "error" });
                console.error("No unique_id returned from create chat");
            }
        },
        onError: error => {
            enqueueSnackbar("Failed to create chat", { variant: "error" });
            console.error("Failed to create chat:", error);
        },
    });

    const handleFollowUp = React.useCallback(() => {
        if (aiAnswer == null) {
            return;
        }
        createChatMutation.mutate({
            messages: [
                { role: "user", content: query },
                {
                    role: "assistant",
                    content: aiAnswer,
                    sources:
                        citedSources?.map<AssistantChatMessageSourceWithMandatoryFields>(source => ({
                            name: source.file_name,
                            pages: source.pages,
                            file_type: source.file_type ?? null,
                            time_created: source.time_created ?? null,
                            project_code: source.project_code ?? null,
                            is_user_file: source.is_user_file ?? null,
                            blob_name: source.blob_name ?? null,
                        })) ?? [],
                    are_sources_recency_sensitive: isTimeSensitive,
                },
            ],
            name: `${query.charAt(0).toUpperCase() + query.slice(1, 40) + (query.length > 40 ? "…" : "")} - Follow-up`,
            sourceFilters: getSourceFilters(filterProjects, filterSources, filterFileTypes, filterEarliestDate),
        });
    }, [
        aiAnswer,
        createChatMutation,
        query,
        citedSources,
        isTimeSensitive,
        filterProjects,
        filterSources,
        filterFileTypes,
        filterEarliestDate,
    ]);

    const filters = React.useMemo(() => {
        return {
            projects: filterProjects,
            sources: filterSources,
            fileTypes: filterFileTypes,
            earliestDate: filterEarliestDate,
        };
    }, [filterProjects, filterSources, filterFileTypes, filterEarliestDate]);

    const isProjectAnswer = projects != null;

    const hasLogos = React.useMemo(() => {
        return projects?.some(
            p =>
                p.client?.unique_id != null ||
                (p.project_type !== "private-equity-buyside-due-diligence" && p.target_organization?.unique_id != null),
        );
    }, [projects]);

    const handleExportLogos = React.useCallback(async () => {
        if (projects == null) return;

        try {
            const response = await coreClientRouterGenerateLogosSlide({
                throwOnError: true,
                body: {
                    project_ids: projects.filter(p => p.can_be_used_for_credentials).map(p => p.id),
                },
            });
            // Create a blob from the response and download it
            const blob = new Blob([response.data as BlobPart], {
                type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "project_logos.pptx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Failed to export logos:", error);
            enqueueSnackbar("Failed to export logos", { variant: "error" });
        }
    }, [enqueueSnackbar, projects]);

    const theme = useTheme();
    const tenant = useQuery(tenantQueryOptions);

    const projectAction = React.useMemo(() => {
        if ((tenant.data?.enable_project_logo_expansion ?? false) && isProjectAnswer && hasLogos) {
            return (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleExportLogos}
                        size="small"
                        sx={{
                            border: 1,
                            borderColor: "neutrals.30",
                            bgcolor: "surface.0",
                            borderRadius: 12,
                        }}
                    >
                        <Export size={16} variant="Linear" color={theme.palette.neutrals[80]} />
                    </IconButton>
                </Box>
            );
        }
        return undefined;
    }, [
        handleExportLogos,
        hasLogos,
        isProjectAnswer,
        tenant.data?.enable_project_logo_expansion,
        theme.palette.neutrals,
    ]);

    const action = React.useMemo(() => {
        return !isStreamingAnswer && people == null && !isProjectAnswer ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FeedbackIconButton query={query} answer={aiAnswer} searchFilters={filters} />
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Message size={16} />}
                    onClick={handleFollowUp}
                    disabled={createChatMutation.isPending}
                    size="small"
                    sx={{
                        borderRadius: 12,
                        borderColor: "neutrals.30",
                        alignSelf: "flex-start",
                        bgcolor: "surface.0",
                    }}
                >
                    <Typography variant="caption" fontWeight={500}>
                        {createChatMutation.isPending ? "Creating chat…" : "Follow up"}
                    </Typography>
                </Button>
            </Box>
        ) : undefined;
    }, [
        aiAnswer,
        createChatMutation.isPending,
        filters,
        handleFollowUp,
        isProjectAnswer,
        isStreamingAnswer,
        people,
        query,
    ]);

    const startNewChatMutation = useStartNewChatMutation();
    const onSearchWeb = React.useCallback(() => {
        if (startNewChatMutation.isPending || startNewChatMutation.isError) {
            return;
        }
        startNewChatMutation.mutate({
            query,
            tenantFocusedDocumentNames: [],
            userFocusedDocuments: [],
            selectedPersona: { type: "user", id: DEFAULT_PERSONA_ID },
            isDirectBaseModelChat: true,
            model: "o1",
            projects: [],
            sourceTypes: [],
            fileTypes: [],
            earliestDate: undefined,
        });
    }, [query, startNewChatMutation]);

    const peopleAnswer =
        people != null ? <PersonAnswerSection people={people} onSelectPerson={onSelectPerson} /> : undefined;

    const projectsAnswer =
        projects != null ? (
            <ProjectAnswerSection
                projects={projects}
                onSelectProject={onSelectProject}
                selectedIdx={selectedProject?.projectIdx}
                onToggleCollapse={onToggleProjectAnswerExpanded}
                isCollapsed={!isProjectAnswerExpanded}
                hasLoadedMore={hasLoadedMoreProjects}
                canLoadMore={canLoadMoreProjects && !hasLoadedMoreProjects}
                isLoadingMore={isLoadingMoreProjects}
                onLoadMore={onLoadMoreProjects}
            />
        ) : undefined;

    const isTextAnswer = aiAnswer != null;
    const textAnswerSection =
        aiAnswer != null ? (
            <TextAnswerSection
                aiAnswer={aiAnswer}
                citedSources={citedSources}
                isStreamingAnswer={isStreamingAnswer}
                isExpanded={isExpanded}
                showDateRange={isTimeSensitive}
                hasSuitableAnswer={hasSuitableAnswer}
                onSelectSource={onSelectSource}
                onExpandedChange={onExpandedChange}
                onSearchWeb={onSearchWeb}
                onSelectProject={isProjectAnswer ? onSelectProject : undefined}
            />
        ) : undefined;

    if (!isTextAnswer && (people == null || people.length === 0) && projects == null) {
        return null;
    }

    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            {isTextAnswer && isProjectAnswer ? (
                <>
                    <SearchViewHeader text="Answer" tooltip={TEXT_ANSWER_TOOLTIP} action={action} />
                    {textAnswerSection}
                    <SearchViewHeader
                        text="Projects"
                        tooltip={getAnswerTooltip(people, projects)}
                        action={projectAction}
                    />
                    {projectsAnswer}
                </>
            ) : (
                <>
                    {people != null ? (
                        <>
                            <SearchViewHeader
                                text="People"
                                tooltip={getAnswerTooltip(people, projects)}
                                action={action}
                            />
                            {peopleAnswer}
                        </>
                    ) : projects != null ? (
                        <>
                            <SearchViewHeader
                                text="Projects"
                                tooltip={getAnswerTooltip(people, projects)}
                                action={projectAction}
                            />
                            {projectsAnswer}
                        </>
                    ) : (
                        <>
                            <SearchViewHeader text="Answer" tooltip={TEXT_ANSWER_TOOLTIP} action={action} />
                            {textAnswerSection}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

const SkeletonAnswerSection: React.FC = () => (
    <Box sx={{ mb: 3, width: "100%", flexGrow: 1 }}>
        <Skeleton variant="text" width="20%" height={32} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="100%" height={64} />
    </Box>
);

const SearchPreviewSection: React.FC<{
    source: Source;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const header = React.useMemo(() => {
        return <SearchViewHeader text={source.file_name} tooltip="Preview the selected document" />;
    }, [source.file_name]);

    return <PreviewSection source={source} page={page} onPageChange={onPageChange} onClose={onClose} header={header} />;
};

interface ExpertCardProps {
    name: string;
    title: string;
    imageUrl: string;
}
const ExpertCard: React.FC<ExpertCardProps> = React.memo(({ name, title, imageUrl }: ExpertCardProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                bgcolor: "surface.0",
                border: 1,
                borderColor: "neutrals.30",
                minWidth: 250,
                height: 64,
                overflow: "hidden",
            }}
        >
            <Avatar
                src={imageUrl}
                sx={{
                    width: 40,
                    height: 40,
                    mr: 2,
                    borderRadius: 1,
                }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "hidden" }}>
                <Typography variant="subtitle2" fontWeight={500} noWrap sx={{ textOverflow: "ellipsis" }}>
                    {name}
                </Typography>
                <Typography variant="caption" color="text.secondary" noWrap sx={{ textOverflow: "ellipsis" }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
});
ExpertCard.displayName = "ExpertCard";

const FAKE_SEXES = Array(8)
    .fill(null)
    .map(() => faker.person.sexType());

const FAKE_NAMES = FAKE_SEXES.map(sex => faker.person.fullName({ sex }));
const FAKE_JOB_TITLES = FAKE_SEXES.map(() => faker.person.jobTitle());
const FAKE_IMAGES = FAKE_SEXES.map(sex =>
    sex === "male"
        ? `https://randomuser.me/api/portraits/men/${faker.number.int({ min: 1, max: 75 })}.jpg`
        : `https://randomuser.me/api/portraits/women/${faker.number.int({ min: 1, max: 75 })}.jpg`,
);

const ExpertsSection: React.FC = React.memo(() => {
    const experts = React.useMemo(() => {
        return FAKE_NAMES.map((name, index) => ({
            name,
            title: FAKE_JOB_TITLES[index],
            imageUrl: FAKE_IMAGES[index],
        }));
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 1, mb: 3, overflow: "hidden" }}>
            <SearchViewHeader text="Experts" tooltip="People who might be able to help with your query" />
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    overflowX: "auto",
                    justifyContent: "space-between",
                    pb: 1,
                    ...getScrollbarSx("primary.main"),
                }}
            >
                {experts.map((expert, index) => (
                    <ExpertCard key={index} {...expert} />
                ))}
            </Box>
        </Box>
    );
});

ExpertsSection.displayName = "ExpertsSection";

interface FeedbackIconButtonProps {
    query: string;
    answer: string | undefined;
    searchFilters: {
        projects: string[];
        sources: string[];
        fileTypes: string[];
        earliestDate: Date | undefined;
    };
}

const FeedbackIconButton: React.FC<FeedbackIconButtonProps> = React.memo(
    ({ query, answer, searchFilters }: FeedbackIconButtonProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [feedbackState, setFeedbackState] = React.useState<{
            type: "thumbs_up" | "thumbs_down" | "text" | null;
            success?: boolean;
            error?: boolean;
        }>({ type: null });
        const [textFeedback, setTextFeedback] = React.useState("");
        const [textFeedbackAnchorEl, setTextFeedbackAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);

        const resetFeedbackState = React.useCallback(() => {
            setTimeout(() => {
                setFeedbackState({ type: null });
            }, 2000);
        }, []);

        const feedbackMutation = useMutation({
            mutationFn: async ({
                feedbackType,
                textFeedback,
            }: {
                feedbackType: NonNullable<typeof feedbackState.type>;
                textFeedback?: string;
            }) => {
                if (!answer || !feedbackType) return;

                return coreSearchRouterSubmitSearchFeedback({
                    body: {
                        feedback_type: feedbackType,
                        text_feedback: textFeedback,
                        search_query: query,
                        search_filters: searchFilters,
                        answer_content: answer,
                    },
                });
            },
            onMutate: ({ feedbackType }) => {
                handleClose();
                try {
                    H.track("submit_search_feedback", {
                        feedback_type: feedbackType ?? "unknown",
                    });
                } catch (error) {
                    console.error("Error tracking feedback", error);
                }
            },
        });

        const handleFeedback = React.useCallback(
            (type: typeof feedbackState.type) => {
                if (!type) return;

                feedbackMutation.mutate(
                    { feedbackType: type },
                    {
                        onSuccess: () => {
                            setFeedbackState({ type, success: true });
                            resetFeedbackState();
                        },
                        onError: error => {
                            console.error("Error submitting feedback", error);
                            setFeedbackState({ type, error: true });
                            resetFeedbackState();
                        },
                    },
                );
            },
            [feedbackMutation, feedbackState, resetFeedbackState],
        );

        const handleTextFeedbackClick = React.useCallback(() => {
            setTextFeedbackAnchorEl(anchorEl);
            handleClose();
        }, [anchorEl, handleClose]);

        const handleTextFeedbackClose = React.useCallback(() => {
            setTextFeedbackAnchorEl(null);
            setTextFeedback("");
        }, []);

        const handleTextFeedbackSubmit = React.useCallback(() => {
            if (textFeedback.trim()) {
                feedbackMutation.mutate(
                    {
                        feedbackType: "text",
                        textFeedback: textFeedback.trim(),
                    },
                    {
                        onSuccess: () => {
                            setFeedbackState({ type: "text", success: true });
                            resetFeedbackState();
                            handleTextFeedbackClose();
                        },
                        onError: () => {
                            setFeedbackState({ type: "text", error: true });
                            resetFeedbackState();
                        },
                    },
                );
            }
        }, [textFeedback, feedbackMutation, handleTextFeedbackClose, resetFeedbackState]);

        const handleKeyDown = React.useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    handleTextFeedbackSubmit();
                }
            },
            [handleTextFeedbackSubmit],
        );

        const handleClickThumbsUp = React.useCallback(() => {
            handleFeedback("thumbs_up");
        }, [handleFeedback]);

        const handleClickThumbsDown = React.useCallback(() => {
            handleFeedback("thumbs_down");
        }, [handleFeedback]);

        const menuItemSx = React.useMemo(() => {
            return {
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                px: 1.5,
            } as const;
        }, []);

        const theme = useTheme();

        const feedbackIconColor =
            feedbackState.success && feedbackState.type === "thumbs_up"
                ? theme.palette.success.main
                : feedbackState.success && feedbackState.type === "thumbs_down"
                  ? theme.palette.error.main
                  : feedbackState.type === "text" && feedbackState.success
                    ? theme.palette.info.main
                    : theme.palette.neutrals[80];

        return (
            <>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{
                        borderRadius: 12,
                        border: 1,
                        bgcolor: "surface.0",
                        borderColor: "neutrals.30",
                    }}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    {feedbackState.error != null ? (
                        <InfoCircle size={16} color={theme.palette.error.main} />
                    ) : feedbackMutation.isPending ? (
                        <CircularProgress size={16} sx={{ color: "neutrals.50" }} />
                    ) : (
                        <FeedbackIcon
                            style={{
                                color: feedbackIconColor,
                                width: 16,
                                height: 16,
                            }}
                        />
                    )}
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                        p: 1,
                    }}
                    MenuListProps={{
                        sx: {
                            py: 0.5,
                        },
                    }}
                >
                    <MenuItem onClick={handleClickThumbsUp} sx={menuItemSx}>
                        <ThumbsUp
                            size={16}
                            color={feedbackState.type === "thumbs_up" && feedbackState.success ? "#4CAF50" : undefined}
                        />
                        <Typography variant="caption">
                            {feedbackState.type === "thumbs_up" && feedbackState.success ? "Liked!" : "Like answer"}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClickThumbsDown} sx={menuItemSx}>
                        <ThumbsDown
                            size={16}
                            color={
                                feedbackState.type === "thumbs_down" && feedbackState.success ? "#4CAF50" : undefined
                            }
                        />
                        <Typography variant="caption">
                            {feedbackState.type === "thumbs_down" && feedbackState.success
                                ? "Disliked!"
                                : "Dislike answer"}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleTextFeedbackClick} sx={menuItemSx}>
                        <MessageText
                            size={16}
                            color={feedbackState.type === "text" && feedbackState.success ? "#4CAF50" : undefined}
                        />
                        <Typography variant="caption">Write feedback</Typography>
                    </MenuItem>
                </Menu>

                <Popover
                    open={Boolean(textFeedbackAnchorEl)}
                    anchorEl={textFeedbackAnchorEl}
                    onClose={handleTextFeedbackClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box sx={{ position: "relative" }}>
                            <TextField
                                autoFocus
                                placeholder="Share your thoughts on this response…"
                                value={textFeedback}
                                onChange={e => setTextFeedback(e.target.value)}
                                onKeyDown={handleKeyDown}
                                multiline
                                minRows={2}
                                maxRows={4}
                                size="small"
                                sx={{
                                    minWidth: 350,
                                    "& .MuiInputBase-input": {
                                        typography: "body2",
                                        pr: 5, // Make room for the send button
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        typography: "body2",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "neutrals.20",
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "neutrals.20",
                                        },
                                    },
                                }}
                            />
                            <IconButton
                                onClick={handleTextFeedbackSubmit}
                                disabled={!textFeedback.trim()}
                                size="small"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    bottom: 8,
                                    bgcolor: textFeedback.trim() ? "secondary.main" : "divider",
                                    width: 24,
                                    height: 24,
                                    "&:hover": {
                                        bgcolor: textFeedback.trim() ? "secondary.dark" : "divider",
                                    },
                                }}
                            >
                                <Send size={16} variant="Bold" color={theme.palette.common.white} />
                            </IconButton>
                        </Box>
                    </Box>
                </Popover>
            </>
        );
    },
);

FeedbackIconButton.displayName = "FeedbackIconButton";

function useLoadMoreProjects(
    setProjects: React.Dispatch<React.SetStateAction<Project[] | undefined>>,
    setSources: React.Dispatch<React.SetStateAction<Sources | undefined>>,
) {
    const handleLoadMoreProjects = React.useCallback(
        async (query: string, minDistanceForMoreProjects: number) => {
            if (minDistanceForMoreProjects == null || query.length === 0) {
                return;
            }

            return coreSearchRouterSearchProjects({
                throwOnError: true,
                body: {
                    query,
                    min_distance: minDistanceForMoreProjects,
                },
            })
                .then(response => {
                    // Append new projects to existing projects
                    setProjects(prevProjects => {
                        const transformedProjects: Project[] = response.data.projects.map(project =>
                            nullToUndefined(project),
                        );
                        if (prevProjects == null) {
                            return transformedProjects;
                        }
                        return [...prevProjects, ...transformedProjects];
                    });

                    // Append new sources to existing sources
                    setSources(prevSources => {
                        const transformedSources: Sources = response.data.sources.map(source => ({
                            ...source,
                            file_type: source.file_type ?? undefined,
                            time_created: source.time_created ?? undefined,
                            tags: source.tags ?? undefined,
                            project_code: source.project_code ?? undefined,
                            project_name: source.project_name ?? undefined,
                            project_id: source.project_id ?? undefined,
                            owner: source.owner ?? undefined,
                            metadata: source.metadata ?? undefined,
                            pages: source.pages.map(page => ({
                                ...page,
                                content: page.content ?? "",
                                page_name: page.page_name ?? undefined,
                            })),
                        }));
                        if (prevSources == null) {
                            return transformedSources;
                        }
                        return [...prevSources, ...transformedSources];
                    });
                })
                .catch(error => {
                    console.error("Failed to load more projects:", error);
                });
        },
        [setProjects, setSources],
    );

    return handleLoadMoreProjects;
}
