import * as React from "react";
import { Box, IconButton, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logomark-primary-custom-color.svg";
import { useParams } from "react-router-dom";
import { CitedSourceBox, CitedSourcePage } from "../shared/citedSourceBox";
import {
    AssistantChatMessageSource,
    AssistantChatMessageSourcePage,
    ProjectWithAiSummary,
    MemberWithProjects,
    AssistantChatMessageWebSearchSource,
} from "../backend-client/generated/types.gen";
import {
    ArrowDown2,
    ArrowRight2,
    Like1 as ThumbsUp,
    Dislike as ThumbsDown,
    MessageText,
    Copy,
    TickCircle,
    Send,
    ArrowLeft2,
    DocumentCloud,
    Bubble,
} from "iconsax-react";
import { useBoolean, useOnMount } from "../utils/hooks";
import { CitedText } from "../shared/citedText";
import { Popover, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { coreChatRouterSubmitChatFeedback } from "../backend-client/generated";
import { Close as CrossIcon } from "@mui/icons-material";
import { H } from "highlight.run";
import { DateRangeCallout, useDateRange } from "../search/dateRangeCallout";
import { hideScrollbarSx } from "../shared/scrollbarProps";
import { ProjectAnswerCard } from "../search/projectAnswerCard";
import { Project } from "../search/types";
import { PersonCard } from "../search/personCard";
import { WebSourceBox } from "../shared/webSourceBox";
import { SelectedPersonData, SelectedSourceInfoForMessage } from "./selectionState";
import { SelectedProjectData } from "./selectionState";
import { convertPersonToCard, convertProjectToCard } from "./converters";
import { debounce } from "lodash";
import { Citation } from "../shared/citation";
import { CitedSourcePopover } from "../shared/citationPopovers/citedSourcePopover";
import { WebSourcePopover } from "../shared/citationPopovers/webSourcePopover";

const CITATION_SX = {
    bgcolor: "surface.75",
} as const;

const LOGO_SIZE = 24;

const dateRangeSx = { height: 28, maxHeight: 28, mb: 1 } as const;

interface SystemMessageProps {
    content: string;
    sources: AssistantChatMessageSource[] | undefined;
    projects: ProjectWithAiSummary[] | undefined;
    people: MemberWithProjects[] | undefined;
    webSources: AssistantChatMessageWebSearchSource[] | undefined;
    systemMessageIdx: number;
    selectedSourceInfo: SelectedSourceInfoForMessage | undefined;
    selectedProjectData: SelectedProjectData | undefined;
    selectedPersonData: SelectedPersonData | undefined;
    areSourcesRecencySensitive: boolean;
    isStreaming?: boolean;
    reasoningTime?: number | undefined;
    isNonStreamingReasoningModel: boolean;
    isSearchingTheWeb: boolean | undefined;
    onSourceSelect: ((systemMessageIdx: number, sourceIdx: number, page: number) => void) | undefined;
    onProjectSelect: ((systemMessageIdx: number, projectIdx: number, fileIdx?: number) => void) | undefined;
    onPersonSelect: ((systemMessageIdx: number, personIdx: number, projectIdx?: number) => void) | undefined;
}

export const SystemMessage: React.FC<SystemMessageProps> = ({
    content,
    sources,
    projects,
    people,
    webSources,
    systemMessageIdx,
    selectedSourceInfo,
    selectedProjectData,
    selectedPersonData,
    areSourcesRecencySensitive,
    isStreaming = false,
    reasoningTime,
    isNonStreamingReasoningModel,
    isSearchingTheWeb,
    onSourceSelect,
    onProjectSelect,
    onPersonSelect,
}) => {
    const theme = useTheme();
    const { value: showSources, toggleValue: toggleShowSources } = useBoolean(true);
    const {
        value: showMessageActions,
        setTrue: setShowFeedbackIcons,
        setFalse: setHideFeedbackIcons,
    } = useBoolean(false);
    const [textFeedbackAnchorEl, setTextFeedbackAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const dateRange = useDateRange(sources);

    const handleSourceSelect = React.useMemo(
        () =>
            onSourceSelect != null
                ? (sourceIdx: number, page: number | undefined) =>
                      onSourceSelect(systemMessageIdx, sourceIdx, page ?? 1)
                : undefined,
        [onSourceSelect, systemMessageIdx],
    );

    const handleProjectSelect = React.useMemo(
        () =>
            onProjectSelect != null
                ? (projectIdx: number, fileIdx?: number) => onProjectSelect(systemMessageIdx, projectIdx, fileIdx)
                : undefined,
        [onProjectSelect, systemMessageIdx],
    );

    const handleSelectSourceByRank = React.useCallback(
        (rank: number) => {
            if (onSourceSelect == null) {
                return;
            }
            const source = sources
                ?.flatMap((s, sourceIdx) => s.pages?.map(p => ({ sourceIdx, page: p })))
                .find(p => p?.page.rank === rank);
            if (source != null) {
                onSourceSelect(systemMessageIdx, source.sourceIdx, source.page.page);
            }
        },
        [onSourceSelect, sources, systemMessageIdx],
    );

    const handleSelectProjectByIdx = React.useCallback(
        (idx: number) => {
            if (onProjectSelect == null) {
                return;
            }
            onProjectSelect(systemMessageIdx, idx);
        },
        [onProjectSelect, systemMessageIdx],
    );

    const handlePersonSelect = React.useMemo(
        () =>
            onPersonSelect != null
                ? (personIdx: number, projectIdx?: number) => onPersonSelect(systemMessageIdx, personIdx, projectIdx)
                : undefined,
        [onPersonSelect, systemMessageIdx],
    );

    const handleSelectWebSourceByIdx = React.useCallback(
        (idx: number) => {
            const webSource = webSources?.[idx];
            if (webSource != null) {
                window.open(webSource.url, "_blank");
            }
        },
        [webSources],
    );

    const hasSources = sources != null && sources.length > 0;

    const [cotContent, displayContent] = React.useMemo(() => {
        // Only consider <think> if it's at the very start of the message
        if (!content.startsWith("<think>")) {
            return ["", content];
        }

        const thinkCloseIndex = content.indexOf("</think>");
        const cotStart = "<think>".length;
        const cotEnd = thinkCloseIndex !== -1 ? thinkCloseIndex : undefined;

        const cot = content.slice(cotStart, cotEnd);
        const display = thinkCloseIndex !== -1 ? content.slice(thinkCloseIndex + "</think>".length).trim() : "";

        return [cot.trim(), display.trim()];
    }, [content]);

    const { value: showCot, toggleValue: toggleShowCot } = useBoolean(true);

    const renderCitation = React.useCallback(
        (rank: number, onCitationClick: ((rank: number) => void) | undefined) => {
            if (sources != null && sources.length > 0) {
                const source = sources.find(s => s.pages?.some(p => p.rank === rank));
                if (source != null) {
                    const page = source.pages?.find(p => p.rank === rank);
                    return (
                        <CitedSourcePopover
                            fileName={source.name}
                            fileType={source.file_type ?? undefined}
                            page={page?.page ?? 1}
                            timeCreated={source.time_created ?? undefined}
                            projectCode={source.project_code ?? undefined}
                        >
                            <Citation index={rank} onClick={onCitationClick} sx={CITATION_SX} />
                        </CitedSourcePopover>
                    );
                }
            }
            if (webSources != null && webSources.length > 0) {
                const webSource = webSources[rank];
                if (webSource != null) {
                    return (
                        <WebSourcePopover
                            url={webSource.url}
                            title={webSource.title ?? undefined}
                            description={webSource.description ?? undefined}
                            rank={rank}
                        >
                            <Citation index={rank} onClick={onCitationClick} sx={CITATION_SX} />
                        </WebSourcePopover>
                    );
                }
            }
            return <Citation index={rank} onClick={onCitationClick} sx={CITATION_SX} />;
        },
        [sources, webSources],
    );

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                mb: 2,
                position: "relative",
                lineHeight: "1.75",
            }}
            onMouseEnter={setShowFeedbackIcons}
            onMouseLeave={setHideFeedbackIcons}
        >
            <Box sx={{ maxWidth: "100%", p: 2, display: "flex", overflowX: "hidden", gap: 1.5 }}>
                <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
                    <Logo
                        style={{
                            width: LOGO_SIZE,
                            height: LOGO_SIZE,
                            marginRight: theme.spacing(1),
                            color: theme.palette.secondary.main,
                        }}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", gap: 0.5 }}>
                    <SystemMessageBackingInformation
                        sources={sources}
                        projects={projects}
                        people={people}
                        webSources={webSources}
                        showBackingInfo={showSources}
                        toggleShowBackingInfo={toggleShowSources}
                        selectedSourceInfo={selectedSourceInfo}
                        selectedProjectData={selectedProjectData}
                        onSourceSelect={handleSourceSelect}
                        onProjectSelect={handleProjectSelect}
                        selectedPersonData={selectedPersonData}
                        onPersonSelect={handlePersonSelect}
                        isStreaming={isStreaming}
                    />
                    {dateRange != null && areSourcesRecencySensitive && (
                        <DateRangeCallout dateRange={dateRange} sx={dateRangeSx} />
                    )}
                    {isStreaming && content === "" ? (
                        <PulsatingDot
                            hasSources={hasSources}
                            isNonStreamingReasoningModel={isNonStreamingReasoningModel}
                            isSearchingTheWeb={isSearchingTheWeb}
                        />
                    ) : (
                        <>
                            {cotContent.length > 0 && (
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 0.5, mb: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                        <Bubble
                                            size={16}
                                            color={theme.palette.neutrals[70]}
                                            style={{ marginRight: "2px" }}
                                        />
                                        <Typography variant="body2" color="neutrals.70">
                                            {isStreaming
                                                ? "Thinking..."
                                                : `Thought for ${reasoningTime != null ? reasoningTime : "a few"} ${
                                                      reasoningTime == null || reasoningTime > 1 ? "seconds" : "second"
                                                  }`}
                                        </Typography>
                                        <IconButton size="small" onClick={toggleShowCot}>
                                            {showCot ? (
                                                <ArrowDown2 size={12} color={theme.palette.neutrals[70]} />
                                            ) : (
                                                <ArrowRight2 size={12} color={theme.palette.neutrals[70]} />
                                            )}
                                        </IconButton>
                                    </Box>

                                    {showCot && (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                pl: 1.5,
                                                lineHeight: 2,
                                                borderLeft: 2,
                                                borderColor: "neutrals.30",
                                                color: "neutrals.60",
                                                whiteSpace: "pre-wrap",
                                            }}
                                        >
                                            {cotContent}
                                        </Typography>
                                    )}
                                </Box>
                            )}

                            <CitedText
                                text={displayContent}
                                onCitationClick={
                                    projects != null && projects.length > 0
                                        ? handleSelectProjectByIdx
                                        : webSources != null && webSources.length > 0
                                          ? handleSelectWebSourceByIdx
                                          : handleSelectSourceByRank
                                }
                                renderCitation={renderCitation}
                            />
                        </>
                    )}
                </Box>

                <MessageActions
                    content={content}
                    textFeedbackAnchorEl={textFeedbackAnchorEl}
                    setTextFeedbackAnchorEl={setTextFeedbackAnchorEl}
                    isVisible={!isStreaming && (showMessageActions || textFeedbackAnchorEl != null)}
                />
            </Box>
        </Box>
    );
};

const PROJECT_CARD_SX = { maxWidth: 350, mt: 1.25 };

interface SystemMessageBackingInformationProps {
    sources: AssistantChatMessageSource[] | undefined;
    projects: ProjectWithAiSummary[] | undefined;
    people: MemberWithProjects[] | undefined;
    webSources: AssistantChatMessageWebSearchSource[] | undefined;
    showBackingInfo: boolean;
    selectedSourceInfo: SelectedSourceInfoForMessage | undefined;
    selectedProjectData: SelectedProjectData | undefined;
    isStreaming: boolean;
    toggleShowBackingInfo: () => void;
    onSourceSelect: ((sourceIdx: number, page: number | undefined) => void) | undefined;
    onProjectSelect: ((projectIdx: number, fileIdx?: number) => void) | undefined;
    selectedPersonData: SelectedPersonData | undefined;
    onPersonSelect: ((personIdx: number, projectIdx?: number) => void) | undefined;
}

const SystemMessageBackingInformation: React.FC<SystemMessageBackingInformationProps> = ({
    sources,
    projects,
    people,
    webSources,
    showBackingInfo,
    toggleShowBackingInfo,
    selectedSourceInfo,
    selectedProjectData,
    isStreaming,
    onSourceSelect,
    onProjectSelect,
    selectedPersonData,
    onPersonSelect,
}) => {
    const hasSources = sources != null && sources.length > 0;
    const hasProjects = projects != null && projects.length > 0;
    const hasPeople = people != null && people.length > 0;
    const hasWebSources = webSources != null && webSources.length > 0;
    const theme = useTheme();
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [isAllTheWayToTheLeft, setIsAllTheWayToTheLeft] = React.useState(true);
    const [isAllTheWayToTheRight, setIsAllTheWayToTheRight] = React.useState(false);

    const updateScrollPosition = React.useCallback(() => {
        if (scrollContainerRef.current) {
            setIsAllTheWayToTheLeft(scrollContainerRef.current.scrollLeft === 0);
            setIsAllTheWayToTheRight(
                scrollContainerRef.current.scrollLeft ===
                    scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth,
            );
        }
    }, []);

    const debouncedUpdateScrollPosition = React.useMemo(
        () =>
            debounce(updateScrollPosition, 100, {
                leading: true,
                trailing: true,
            }),
        [updateScrollPosition],
    );

    React.useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", debouncedUpdateScrollPosition);
            // Initial check
            updateScrollPosition();

            return () => {
                scrollContainer.removeEventListener("scroll", debouncedUpdateScrollPosition);
                debouncedUpdateScrollPosition.cancel();
            };
        }
    }, [debouncedUpdateScrollPosition, updateScrollPosition]);

    const handleScroll = React.useCallback((direction: "left" | "right") => {
        if (scrollContainerRef.current) {
            const cardWidth = 350; // Width of a card + gap
            const scrollAmount = direction === "left" ? -cardWidth : cardWidth;
            scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    }, []);

    // Only show collapsible UI for sources and web sources
    const showCollapsible = hasSources || hasWebSources;
    const relevantText = hasSources
        ? `${sources.length} relevant ${sources.length === 1 ? "doc" : "docs"}`
        : hasWebSources
          ? `${webSources.length} relevant ${webSources.length === 1 ? "web source" : "web sources"}`
          : "";

    // We stream the web sources as they arrive. The object with URLs come first
    // then we re-stream the objects with title and description. Once they arrive, we now we are done.
    const hasTitleAndDescriptionForWebSourcesArriving = React.useMemo(
        () =>
            webSources != null &&
            webSources.length > 0 &&
            webSources.some(ws => ws.title != null && ws.description != null),
        [webSources],
    );

    const handleScrollLeft = React.useCallback(() => {
        handleScroll("left");
    }, [handleScroll]);

    const handleScrollRight = React.useCallback(() => {
        handleScroll("right");
    }, [handleScroll]);

    const arrowControls = (
        <Box
            sx={{
                display: "flex",
                gap: 0.5,
            }}
        >
            <IconButton
                size="small"
                onClick={handleScrollLeft}
                disabled={isAllTheWayToTheLeft}
                sx={{ width: 20, height: 20, opacity: isAllTheWayToTheLeft ? 0.5 : 1 }}
            >
                <ArrowLeft2 size={16} style={{ flexShrink: 0 }} color={theme.palette.neutrals[70]} />
            </IconButton>
            <IconButton
                size="small"
                onClick={handleScrollRight}
                disabled={isAllTheWayToTheRight}
                sx={{ width: 20, height: 20, opacity: isAllTheWayToTheRight ? 0.5 : 1 }}
            >
                <ArrowRight2 size={16} style={{ flexShrink: 0 }} color={theme.palette.neutrals[70]} />
            </IconButton>
        </Box>
    );

    return (
        <>
            {showCollapsible && (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <DocumentCloud size={16} color={theme.palette.neutrals[70]} style={{ marginRight: "2px" }} />
                        <Typography variant="body2" color="neutrals.70">
                            {relevantText}
                        </Typography>
                        <IconButton size="small" onClick={toggleShowBackingInfo}>
                            {showBackingInfo ? (
                                <ArrowDown2 size={12} color={theme.palette.neutrals[70]} />
                            ) : (
                                <ArrowRight2 size={12} color={theme.palette.neutrals[70]} />
                            )}
                        </IconButton>
                    </Box>
                    {(sources?.length ?? 0) > 2 || (webSources?.length ?? 0) > 2 ? arrowControls : null}
                </Box>
            )}

            {((showCollapsible && showBackingInfo) || hasProjects || hasPeople) && (
                <Box sx={{ position: "relative" }}>
                    <Box
                        ref={scrollContainerRef}
                        sx={[
                            {
                                display: "flex",
                                columnGap: hasSources || hasWebSources ? 1 : 2,
                                alignItems: "stretch",
                                overflowX: "auto",
                                mt: hasSources || hasWebSources ? 1 : 0,
                                flexShrink: 0,
                                pb: 0.5,
                            },
                            hideScrollbarSx,
                        ]}
                    >
                        {hasSources
                            ? sources?.map((source, idx) => (
                                  <ChatCitedSourceBox
                                      key={idx}
                                      fileName={source.name}
                                      blobName={source.blob_name ?? undefined}
                                      fileType={source.file_type}
                                      timeCreated={source.time_created ?? undefined}
                                      isUserFile={source.is_user_file ?? false}
                                      pages={source.pages ?? []}
                                      sourceIdx={idx}
                                      onSelect={onSourceSelect}
                                      selectedPage={
                                          selectedSourceInfo?.sourceIdx === idx ? selectedSourceInfo.page : undefined
                                      }
                                  />
                              ))
                            : hasPeople
                              ? people?.map((person, idx) => (
                                    <ChatCitedSourcePerson
                                        key={idx}
                                        person={person}
                                        idx={idx}
                                        isSelected={selectedPersonData?.personIdx === idx}
                                        onPersonSelect={onPersonSelect}
                                    />
                                ))
                              : hasWebSources
                                ? webSources?.map((webSource, idx) => (
                                      <ChatCitedSourceWebSearch
                                          key={idx}
                                          url={webSource.url}
                                          title={webSource.title ?? undefined}
                                          description={webSource.description ?? undefined}
                                          rank={idx}
                                          isStreaming={isStreaming && !hasTitleAndDescriptionForWebSourcesArriving}
                                      />
                                  ))
                                : projects?.map((project, idx) => (
                                      <ChatCitedSourceProject
                                          key={idx}
                                          project={project}
                                          idx={idx}
                                          isSelected={selectedProjectData?.projectIdx === idx}
                                          onProjectSelect={onProjectSelect}
                                          sx={PROJECT_CARD_SX}
                                      />
                                  ))}
                    </Box>

                    {(hasProjects || hasPeople) && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mt: 1,
                                mb: 1.5,
                            }}
                        >
                            <Typography variant="body2" color="neutrals.60" sx={{ textAlign: "center" }}>
                                {hasProjects
                                    ? `${projects?.length} relevant ${projects?.length === 1 ? "project" : "projects"}`
                                    : `${people?.length ?? 0} relevant ${people?.length === 1 ? "person" : "people"}`}
                            </Typography>
                            {(projects?.length ?? 0) > 2 || (people?.length ?? 0) > 2 ? arrowControls : null}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

const PulsatingDot: React.FC<{
    hasSources: boolean;
    isNonStreamingReasoningModel: boolean;
    isSearchingTheWeb: boolean | undefined;
}> = ({ hasSources, isNonStreamingReasoningModel, isSearchingTheWeb }) => {
    const theme = useTheme();

    const [startShining, setStartShining] = React.useState(false);

    useOnMount(() => {
        setTimeout(() => {
            setStartShining(true);
        }, 1000);
    });

    if (startShining && ((isSearchingTheWeb != null && isNonStreamingReasoningModel) || isSearchingTheWeb)) {
        return (
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: hasSources ? "flex-start" : "center",
                    mb: 1,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        position: "relative",
                        background: `linear-gradient(
                            90deg,
                            ${theme.palette.surface[0]} 0%,
                            ${theme.palette.neutrals[80]} 35%,
                            ${theme.palette.surface[0]} 65%,
                            ${theme.palette.surface[0]} 100%
                        )`,
                        animation: "shine 2s linear infinite",
                        "@keyframes shine": {
                            "0%": {
                                backgroundPosition: "100% center",
                            },
                            "100%": {
                                backgroundPosition: "-100% center",
                            },
                        },
                        backgroundSize: "200% 100%",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    {isSearchingTheWeb ? "Searching the web" : "Reasoning"}
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: hasSources ? "flex-start" : "center",
                mb: 1,
            }}
        >
            <Box
                sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    bgcolor: "secondary.main",
                    animation: "pulsate 1.5s ease-in-out infinite",
                    "@keyframes pulsate": {
                        "0%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                        "50%": {
                            transform: "scale(1.05)",
                            opacity: 0.9,
                        },
                        "100%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                    },
                }}
            />
        </Box>
    );
};

interface MessageActionsProps {
    content: string;
    textFeedbackAnchorEl: HTMLButtonElement | null;
    isVisible: boolean;
    setTextFeedbackAnchorEl: (anchorEl: HTMLButtonElement | null) => void;
}

type ActionType = "copy" | "thumbs_up" | "thumbs_down" | "text";
type FeedbackState = { success?: boolean; error?: boolean };
type FeedbackStates = Record<ActionType, FeedbackState>;

const MessageActions: React.FC<MessageActionsProps> = ({
    content,
    textFeedbackAnchorEl,
    setTextFeedbackAnchorEl,
    isVisible,
}) => {
    const [feedbackStates, setFeedbackStates] = React.useState<FeedbackStates>({
        copy: {},
        thumbs_up: {},
        thumbs_down: {},
        text: {},
    });

    const resetFeedbackState = React.useCallback((actionType: ActionType) => {
        setTimeout(() => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: {} }));
        }, 2000);
    }, []);

    const handleActionSuccess = React.useCallback(
        (actionType: ActionType) => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: { success: true } }));
            resetFeedbackState(actionType);
        },
        [resetFeedbackState],
    );

    const handleActionError = React.useCallback(
        (actionType: ActionType) => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: { error: true } }));
            resetFeedbackState(actionType);
        },
        [resetFeedbackState],
    );

    const [textFeedback, setTextFeedback] = React.useState("");
    const { chatId } = useParams<{ chatId: string }>();

    const feedbackMutation = useMutation({
        mutationFn: async ({
            feedbackType,
            textFeedback,
        }: {
            feedbackType: "thumbs_up" | "thumbs_down" | "text";
            textFeedback?: string;
        }) => {
            if (!chatId) return;
            return coreChatRouterSubmitChatFeedback({
                path: { chat_id: chatId },
                body: {
                    feedback_type: feedbackType,
                    text_feedback: textFeedback,
                    message_content: content,
                },
            });
        },
        onMutate: ({ feedbackType }) => {
            H.track("chat_feedback", { feedback_type: feedbackType });
        },
    });

    const handleFeedbackClick = React.useCallback(
        (type: Extract<ActionType, "thumbs_up" | "thumbs_down">) => {
            feedbackMutation.mutate(
                { feedbackType: type },
                {
                    onSuccess: () => handleActionSuccess(type),
                    onError: () => handleActionError(type),
                },
            );
        },
        [feedbackMutation, handleActionSuccess, handleActionError],
    );

    const handleCopyClick = React.useCallback(() => {
        navigator.clipboard
            .writeText(content)
            .then(() => handleActionSuccess("copy"))
            .catch(() => handleActionError("copy"));
    }, [content, handleActionSuccess, handleActionError]);

    const handleTextFeedbackClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setTextFeedbackAnchorEl(event.currentTarget);
        },
        [setTextFeedbackAnchorEl],
    );

    const handleTextFeedbackClose = React.useCallback(() => {
        setTextFeedbackAnchorEl(null);
        setTextFeedback("");
    }, [setTextFeedbackAnchorEl]);

    const handleTextFeedbackSubmit = React.useCallback(() => {
        if (textFeedback.trim()) {
            feedbackMutation.mutate(
                {
                    feedbackType: "text",
                    textFeedback: textFeedback.trim(),
                },
                {
                    onSuccess: () => handleActionSuccess("text"),
                    onError: () => handleActionError("text"),
                },
            );
            handleTextFeedbackClose();
        }
    }, [textFeedback, feedbackMutation, handleTextFeedbackClose, handleActionSuccess, handleActionError]);

    const handleChangeTextFeedback = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFeedback(event.target.value);
    }, []);

    const theme = useTheme();

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleTextFeedbackSubmit();
            }
        },
        [handleTextFeedbackSubmit],
    );

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    bottom: -8,
                    left: `calc(${LOGO_SIZE}px + ${theme.spacing(3)} + 4px)`,
                    display: "flex",
                    gap: 0.5,
                    opacity: isVisible ? 1 : 0,
                    transition: theme =>
                        theme.transitions.create(["opacity"], { duration: 200, easing: "ease-in-out" }),
                }}
            >
                <IconButton size="small" onClick={handleCopyClick} disableFocusRipple disableTouchRipple disableRipple>
                    <ActionIcon actionType="copy" state={feedbackStates.copy} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => handleFeedbackClick("thumbs_up")}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="thumbs_up" state={feedbackStates.thumbs_up} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => handleFeedbackClick("thumbs_down")}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="thumbs_down" state={feedbackStates.thumbs_down} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleTextFeedbackClick}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="text" state={feedbackStates.text} />
                </IconButton>
            </Box>

            <Popover
                open={Boolean(textFeedbackAnchorEl)}
                anchorEl={textFeedbackAnchorEl}
                onClose={handleTextFeedbackClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ position: "relative" }}>
                        <TextField
                            autoFocus
                            placeholder="Share your thoughts on this response…"
                            value={textFeedback}
                            onChange={handleChangeTextFeedback}
                            onKeyDown={handleKeyDown}
                            multiline
                            minRows={2}
                            maxRows={4}
                            size="small"
                            sx={{
                                minWidth: 350,
                                "& .MuiInputBase-input": {
                                    typography: "body2",
                                    pr: 5, // Make room for the send button
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    typography: "body2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "neutrals.20",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "neutrals.20",
                                    },
                                },
                            }}
                        />
                        <IconButton
                            onClick={handleTextFeedbackSubmit}
                            disabled={!textFeedback.trim()}
                            size="small"
                            sx={{
                                position: "absolute",
                                right: 8,
                                bottom: 8,
                                bgcolor: textFeedback.trim() ? "secondary.main" : "divider",
                                width: 24,
                                height: 24,
                                "&:hover": {
                                    bgcolor: textFeedback.trim() ? "secondary.dark" : "divider",
                                },
                            }}
                        >
                            <Send size={16} variant="Bold" color={theme.palette.common.white} />
                        </IconButton>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

interface ActionIconProps {
    actionType: ActionType;
    state: FeedbackState;
    size?: number;
}

const ActionIcon: React.FC<ActionIconProps> = ({ actionType, state, size = 14 }) => {
    const theme = useTheme();

    if (state?.success) {
        return <TickCircle size={size} color={theme.palette.success.main} variant="Bold" />;
    }

    if (state?.error) {
        return <CrossIcon sx={{ fontSize: size, color: theme.palette.error.main }} />;
    }

    switch (actionType) {
        case "copy":
            return <Copy size={size} color={theme.palette.neutrals[50]} />;
        case "thumbs_up":
            return <ThumbsUp size={size} color={theme.palette.neutrals[50]} />;
        case "thumbs_down":
            return <ThumbsDown size={size} color={theme.palette.neutrals[50]} />;
        case "text":
            return <MessageText size={size} color={theme.palette.neutrals[50]} />;
    }
};

interface ChatCitedSourceBoxProps {
    fileName: string;
    fileType: AssistantChatMessageSource["file_type"];
    pages: AssistantChatMessageSourcePage[];
    blobName: string | undefined;
    isUserFile: boolean;
    sourceIdx: number;
    timeCreated: string | undefined;
    onSelect: ((sourceIdx: number, page: number | undefined) => void) | undefined;
    selectedPage: number | undefined;
}

const EMPTY_PAGES: CitedSourcePage[] = [];

const ChatCitedSourceBox: React.FC<ChatCitedSourceBoxProps> = ({
    fileName,
    fileType,
    pages,
    blobName,
    sourceIdx,
    isUserFile,
    timeCreated,
    selectedPage,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => (onSelect != null ? onSelect(sourceIdx, page) : undefined),
        [onSelect, sourceIdx],
    );
    const citedSourcePages = React.useMemo((): CitedSourcePage[] => {
        return pages != null ? pages.map<CitedSourcePage>(p => ({ page: p.page, rank: p.rank })) : EMPTY_PAGES;
    }, [pages]);

    const blobNameForThumbnail = React.useMemo(() => {
        return blobName?.replace(".pdf", ".jpg");
    }, [blobName]);

    return (
        <CitedSourceBox
            fileName={fileName}
            timeCreated={timeCreated}
            fileType={fileType ?? undefined}
            pages={citedSourcePages}
            blobName={blobNameForThumbnail}
            isUserFile={isUserFile}
            onSelect={handleSelect}
            selectedPage={selectedPage}
        />
    );
};

interface ChatCitedSourceProjectProps {
    project: ProjectWithAiSummary;
    isSelected: boolean;
    idx: number;
    onProjectSelect: ((projectIdx: number, fileIdx?: number | undefined) => void) | undefined;
    sx?: SxProps<Theme>;
}

const ChatCitedSourceProject: React.FC<ChatCitedSourceProjectProps> = React.memo(
    ({ project, isSelected, idx, onProjectSelect, sx }: ChatCitedSourceProjectProps) => {
        const handleSelect = React.useCallback(() => {
            onProjectSelect?.(idx, undefined);
        }, [onProjectSelect, idx]);

        const projectForCard = React.useMemo((): Project => {
            return convertProjectToCard(project);
        }, [project]);

        return <ProjectAnswerCard project={projectForCard} onSelect={handleSelect} isSelected={isSelected} sx={sx} />;
    },
);

ChatCitedSourceProject.displayName = "ChatCitedSourceProject";

interface ChatCitedSourceWebSearchProps {
    url: string;
    title: string | undefined;
    description: string | undefined;
    rank: number;
    isStreaming: boolean;
}

const ChatCitedSourceWebSearch: React.FC<ChatCitedSourceWebSearchProps> = ({
    url,
    title,
    description,
    rank,
    isStreaming,
}) => {
    return <WebSourceBox url={url} title={title} description={description} rank={rank} isStreaming={isStreaming} />;
};

// Add new component for chat person card
interface ChatCitedSourcePersonProps {
    person: MemberWithProjects;
    isSelected: boolean;
    idx: number;
    onPersonSelect: ((personIdx: number, projectIdx?: number) => void) | undefined;
    sx?: SxProps<Theme>;
}

const ChatCitedSourcePerson: React.FC<ChatCitedSourcePersonProps> = React.memo(
    ({ person, idx, onPersonSelect, sx }: ChatCitedSourcePersonProps) => {
        const handleSelect = React.useCallback(
            (idxFromPersonCard: number, projectIdx?: number) => {
                onPersonSelect?.(idxFromPersonCard, projectIdx);
            },
            [onPersonSelect],
        );

        const personForCard = React.useMemo(() => {
            return convertPersonToCard(person);
        }, [person]);

        return <PersonCard person={personForCard} idx={idx} onSelect={handleSelect} sx={sx} variant="compact" />;
    },
);

ChatCitedSourcePerson.displayName = "ChatCitedSourcePerson";
