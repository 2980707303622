import React, { useState, useCallback } from "react";
import { Box, Skeleton, styled, SxProps, Theme } from "@mui/material";
import PDFIcon from "../assets/pdf-icon.svg";
import DocIcon from "../assets/docx-icon.png";
import XlsxIcon from "../assets/xlsx-icon.png";
import PptxIcon from "../assets/pptx-icon.png";
import { coreBlobRouterGenerateSasUrl } from "../backend-client/generated/sdk.gen";
import { useQuery } from "@tanstack/react-query";
import { FileType } from "../shared/types";

function getIconUrlAndAlt(fileType: FileType | undefined) {
    if (fileType === "pdf") return { url: PDFIcon, alt: "PDF icon" };
    if (fileType === "xlsx") return { url: XlsxIcon, alt: "XLSX icon" };
    if (fileType === "pptx") return { url: PptxIcon, alt: "PPTX icon" };
    if (fileType === "docx") return { url: DocIcon, alt: "DOCX icon" };
    return { url: undefined, alt: undefined };
}

interface DocumentThumbnailProps {
    blobName: string;
    fileType: FileType | undefined;
    isUserFile: boolean;
    shouldSasSign?: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
    width?: number;
    height?: number;
    fileTypeIconPlacement?: "top" | "bottom";
}

const FILE_EXTENSIONS = ["pdf", "docx", "pptx", "xlsx", "xls", "txt", "doc"];

const StyledImg = styled("img")({});

export const DocumentThumbnail = React.memo<DocumentThumbnailProps>(
    ({
        blobName,
        fileType,
        isUserFile,
        shouldSasSign = false,
        sx,
        onClick,
        width = 35,
        height = 43,
        fileTypeIconPlacement = "bottom",
    }: DocumentThumbnailProps) => {
        const [error, setError] = useState<boolean>(false);
        const handleError = useCallback(() => {
            console.error("Error fetching image URL");
            setError(true);
        }, []);

        const thumbnailQuery = useQuery({
            queryKey: ["documentThumbnail", blobName],
            queryFn: async () => {
                const hasFileEnding = FILE_EXTENSIONS.some(ending => blobName.endsWith(ending));
                const baseFileName = hasFileEnding ? blobName.substring(0, blobName.lastIndexOf(".")) : blobName;
                const jpegFileName = `${baseFileName}.jpg`;

                const response = await coreBlobRouterGenerateSasUrl({
                    throwOnError: true,
                    path: { blob_name: jpegFileName },
                    query: {
                        container_type: isUserFile ? "user-pdfs-and-images" : "images",
                    },
                });

                return response.data.url;
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            enabled: shouldSasSign,
        });

        const { url: fallbackIconUrl, alt: fallbackIconAlt } = getIconUrlAndAlt(fileType);

        const patchedError = error;

        const url = shouldSasSign ? thumbnailQuery.data : blobName;

        return (
            <Box
                sx={[
                    { width, height, borderRadius: 4, position: "relative" },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                onClick={onClick}
            >
                {patchedError ? (
                    <Box
                        component="img"
                        src={fallbackIconUrl}
                        alt={fallbackIconAlt}
                        width={width}
                        // height={height}
                        style={{
                            objectFit: "cover",
                            maxHeight: height,
                            borderRadius: 1,
                            border: 1,
                            borderColor: "neutrals.20",
                        }}
                    />
                ) : url != null ? (
                    <Box sx={{ position: "relative", width, height }}>
                        <StyledImg
                            src={url}
                            alt={blobName}
                            width={width}
                            height={height}
                            sx={{
                                objectFit: "cover",
                                // objectPosition: "right",
                                borderRadius: 1,
                                border: 1,
                                borderColor: "neutrals.10",
                            }}
                            onError={handleError}
                        />
                        <Box
                            component="img"
                            src={fallbackIconUrl}
                            alt={fallbackIconAlt}
                            sx={{
                                position: "absolute",
                                bottom: fileTypeIconPlacement === "bottom" ? 2 : undefined,
                                top: fileTypeIconPlacement === "top" ? 2 : undefined,
                                right: fileTypeIconPlacement === "bottom" ? 2 : undefined,
                                left: fileTypeIconPlacement === "top" ? 2 : undefined,
                                // width: 12,
                                height: fileType === "pdf" ? 18 : 14,
                            }}
                        />
                    </Box>
                ) : (
                    <Skeleton variant="rectangular" width={width} height={height} />
                )}
            </Box>
        );
    },
);

DocumentThumbnail.displayName = "DocumentThumbnail";
