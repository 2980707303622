import * as React from "react";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import PDFIcon from "../../assets/pdf-icon.svg";
import DocIcon from "../../assets/docx-icon.png";
import { SelectedFile } from "./focusDocuments";
import { FileType } from "../../shared/types";

interface UserFocusedDocumentItemProps {
    file: SelectedFile;
    onRemove?: (id: string) => void;
}

function getIcon(fileType: FileType | undefined) {
    if (fileType === "pdf") return PDFIcon;
    if (fileType === "docx") return DocIcon;
    return undefined;
}

export const UserFocusedDocumentItem = React.memo<UserFocusedDocumentItemProps>(
    ({ file, onRemove }: UserFocusedDocumentItemProps) => {
        const handleRemove = React.useCallback(() => {
            onRemove?.(file.id);
        }, [file.id, onRemove]);

        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    border: 1,
                    borderColor: "neutrals.20",
                }}
            >
                <Box
                    component="img"
                    src={getIcon(file.fileType)}
                    alt={file.fileType === "pdf" ? "PDF icon" : "DOCX icon"}
                    sx={{
                        height: file.fileType === "pdf" ? 18 : 14,
                    }}
                />
                <Typography variant="body2" noWrap sx={{ flex: 1 }}>
                    {file.name}
                </Typography>
                {file.isIndexing && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: 26,
                            height: 26,
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: "surface.100" }} size={16} />
                    </Box>
                )}
                {onRemove != null && !file.isIndexing && (
                    <IconButton onClick={handleRemove} size="small">
                        <Close sx={theme => ({ width: 16, height: 16, color: theme.palette.neutrals[80] })} />
                    </IconButton>
                )}
            </Box>
        );
    },
);

UserFocusedDocumentItem.displayName = "FocusedDocumentItem";
