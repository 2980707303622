import * as React from "react";
import { Box, Typography, useTheme, Button, Theme, SxProps } from "@mui/material";
import { User } from "iconsax-react";
import { PersonData } from "./types";
import { countries } from "countries-list";
import { ReactComponent as BackgroundPattern } from "../assets/profile-card-pattern.svg";
import { comparatorOnFields } from "../utils/comparators";
import { ProjectForPerson, ProjectForPersonProps } from "./projectForPerson";

type PersonCardProps = {
    person: PersonData;
    idx: number;
    onSelect: (idx: number, projectIdx: number | undefined) => void;
    sx?: SxProps<Theme>;
    variant?: "default" | "compact";
};

export const CARD_WIDTH = 320;
export const COMPACT_CARD_WIDTH = 270;
export const PROFILE_SIZE = 104;
export const COMPACT_PROFILE_SIZE = 90;
export const PATTERN_HEIGHT = 76;
export const COMPACT_PATTERN_HEIGHT = 60;

export const PersonCard = React.memo(({ person, idx, onSelect, sx, variant = "default" }: PersonCardProps) => {
    const locationText = React.useMemo(() => {
        if (person.city == null && person.country == null) return null;
        const countryName = person.country ? getCountryName(person.country) : null;
        return [person.city, countryName].filter(Boolean).join(", ");
    }, [person.city, person.country]);

    const projectsSortedByStartDate = React.useMemo(() => {
        return [...person.projects]
            .map((project, idx) => ({ ...project, idx }))
            .sort(
                comparatorOnFields(project => [
                    project.start_date ? -new Date(project.start_date).getTime() : 0,
                    project.end_date ? -new Date(project.end_date).getTime() : 0,
                ]),
            );
    }, [person.projects]);

    const handleSelectPerson = React.useCallback(() => {
        onSelect(idx, undefined);
    }, [idx, onSelect]);

    const handleSelectProject = React.useCallback(
        (projectIdx: number) => {
            onSelect(idx, projectIdx);
        },
        [idx, onSelect],
    );

    const patternHeight = variant === "compact" ? COMPACT_PATTERN_HEIGHT : PATTERN_HEIGHT;
    const profileSize = variant === "compact" ? COMPACT_PROFILE_SIZE : PROFILE_SIZE;

    return (
        <Box
            sx={[
                {
                    minWidth: variant === "compact" ? COMPACT_CARD_WIDTH : CARD_WIDTH,
                    height: variant === "compact" ? 400 : 450,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: "neutrals.25",
                    borderRadius: 2,
                    position: "relative",
                    // overflow: "hidden",
                    pb: 1,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={theme => ({
                    position: "relative",
                    minHeight: `calc(${patternHeight}px + ${profileSize / 2}px + ${theme.spacing(1.5)})`,
                    // overflow: "hidden",
                    width: "100%",
                    overflow: "hidden",
                })}
            >
                <BackgroundPattern
                    style={{
                        left: 1,
                        right: 1,
                        top: 1,
                        width: "calc(100%)",
                        height: patternHeight,
                        borderTopLeftRadius: 7,
                        borderTopRightRadius: 7,
                    }}
                />

                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        top: `calc(${patternHeight}px - ${profileSize / 2}px)`,
                        transform: "translateX(-50%)",
                        p: 0.5,
                        bgcolor: "surface.0",
                        borderRadius: "50%",
                    }}
                >
                    <ProfilePicture
                        imageUrl={person.profile_picture_url ?? undefined}
                        firstName={person.first_name}
                        lastName={person.last_name}
                        size={profileSize}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    px: 1,
                    overflow: "auto",
                    zIndex: 2,
                }}
            >
                <Typography
                    variant="body1"
                    color="neutrals.80"
                    fontWeight={500}
                    align="center"
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mb: variant === "compact" ? 0 : 0.5,
                    }}
                >
                    {person.first_name} {person.last_name}
                </Typography>

                {variant === "default" && (
                    <Typography
                        variant="body2"
                        color="neutrals.60"
                        align="center"
                        sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            mb: 3,
                        }}
                    >
                        {person.title} {locationText && `- ${locationText}`}
                    </Typography>
                )}
                {variant === "compact" && (
                    <>
                        <Typography variant="body2" color="neutrals.60" align="center">
                            {person.title}
                        </Typography>

                        <Typography variant="body2" color="neutrals.50" align="center" sx={{ mb: 2 }}>
                            {locationText != null && ` ${locationText}`}
                        </Typography>
                    </>
                )}

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>
                    {projectsSortedByStartDate.slice(0, 2).map(project => (
                        <ProjectForPersonWithIdx
                            key={project.id}
                            project={project}
                            personId={person.unique_id}
                            projectIdx={project.idx}
                            onSelect={handleSelectProject}
                            hideCollaborators={variant === "compact"}
                        />
                    ))}
                </Box>
            </Box>
            <Button
                variant="outlined"
                onClick={handleSelectPerson}
                sx={{
                    mt: 1,
                    color: "secondary.main",
                    borderColor: "secondary.main",
                    textTransform: "none",
                    mx: 1,
                }}
            >
                View {person.projects.length} relevant projects
            </Button>
        </Box>
    );
});

interface ProjectForPersonWithIdxProps extends Omit<ProjectForPersonProps, "onSelect"> {
    personId: string;
    projectIdx: number;
    onSelect: (projectIdx: number) => void;
    hideCollaborators?: boolean;
}

const ProjectForPersonWithIdx = React.memo(
    ({ project, personId, projectIdx, onSelect, hideCollaborators }: ProjectForPersonWithIdxProps) => {
        const handleSelect = React.useCallback(() => {
            onSelect(projectIdx);
        }, [projectIdx, onSelect]);

        return (
            <ProjectForPerson
                project={project}
                personId={personId}
                onSelect={handleSelect}
                hideCollaborators={hideCollaborators}
            />
        );
    },
);

ProjectForPersonWithIdx.displayName = "ProjectForPersonWithIdx";

PersonCard.displayName = "PersonCard";

type ProfilePictureProps = {
    imageUrl?: string;
    firstName: string;
    lastName: string;
    size: number;
};

const ProfilePicture = React.memo(({ imageUrl, firstName, lastName, size }: ProfilePictureProps) => {
    const [imageError, setImageError] = React.useState(false);
    const theme = useTheme();

    const handleImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    if (imageError || imageUrl == null) {
        return (
            <Box
                sx={{
                    width: size,
                    height: size,
                    borderRadius: "50%",
                    bgcolor: "neutrals.10",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <User size={size / 2} color={theme.palette.secondary.main} variant="Bold" />
            </Box>
        );
    }

    return (
        <Box
            component="img"
            src={imageUrl}
            alt={`${firstName} ${lastName}`}
            onError={handleImageError}
            sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                objectFit: "cover",
            }}
        />
    );
});

ProfilePicture.displayName = "ProfilePicture";
const getCountryName = (isoCode: string) => {
    const country = (countries as Record<string, { name: string }>)[isoCode.toUpperCase()];
    return country?.name ?? isoCode;
};
