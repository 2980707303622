import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SuggestedQuery } from "../backend-client/generated";

export interface SuggestedQueriesProps {
    queries: SuggestedQuery[];
    onClick: (query: string) => void;
}

export const SuggestedQueries: React.FC<SuggestedQueriesProps> = ({ queries, onClick }) => {
    const handleClick = React.useCallback(
        (query: string) => {
            onClick(query);
        },
        [onClick],
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {/* <Typography variant="body2" color="secondary.main" fontWeight={500} sx={{ alignSelf: "center" }}> */}
            {/* Suggested queries */}
            {/* </Typography> */}
            <Grid container spacing={1}>
                {queries.map((item, index) => (
                    <Grid key={index} item xs={6}>
                        <SuggestedQueryCard title={item.display_name} query={item.query} onClick={handleClick} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

interface SuggestedQueryCardProps {
    title: string;
    query: string;
    onClick: (query: string) => void;
}

const SuggestedQueryCard: React.FC<SuggestedQueryCardProps> = ({ title, query, onClick }) => {
    const handleClick = React.useCallback(() => {
        onClick(query);
    }, [query, onClick]);

    return (
        <Box
            onClick={handleClick}
            sx={{
                bgcolor: "surface.0",
                color: "primary.contrastText",
                px: 2,
                py: 1,
                borderRadius: 2,
                cursor: "pointer",
                border: 1,
                borderColor: "divider",
                flexShrink: 0,
                minWidth: 200,
            }}
        >
            <Typography variant="body2" noWrap>
                {title}
            </Typography>
        </Box>
    );
};
