export function getFavIconUrl(
    url: string,
    provider: "google" | "duckduckgo" | "clearbit" = "google",
    size = 16,
): string {
    switch (provider) {
        case "google":
            return `https://www.google.com/s2/favicons?domain=${extractDomain(url) ?? ""}&sz=${size}`;
        case "duckduckgo":
            return `https://icons.duckduckgo.com/ip3/${extractDomain(url) ?? ""}.ico`;
        case "clearbit":
            return `https://logo.clearbit.com/${extractDomain(url) ?? ""}`;
    }
}

export function getDuckDuckGoFavIconUrl(url: string): string {
    return `https://icons.duckduckgo.com/ip3/${extractDomain(url) ?? ""}.ico`;
}

export const FAVICON_SIZE = 20;

export function extractDomain(url: string): string | undefined {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname.replace(/^www\./, "");
    } catch (error) {
        console.error("Invalid URL:", error);
        return undefined;
    }
}

export const isValidUrl = (str: string) => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!urlPattern.test(str)) {
        return false;
    }
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
};

export function formatDisplayUrl(url: string): string {
    return url.replace("https://", "").replace("http://", "").replace("www.", "");
}
