import * as React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Calendar } from "iconsax-react";
import { PersonData } from "./types";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { isNonNullable } from "../utils/isNonNullable";
import { ProjectIconWithHalo } from "./projectIconWithHalo";

export type ProjectForPersonProps = {
    project: PersonData["projects"][number];
    personId: string;
    onSelect?: () => void;
    hideCollaborators?: boolean;
};

export const ProjectForPerson = React.memo(
    ({ project, personId, onSelect, hideCollaborators }: ProjectForPersonProps) => {
        const theme = useTheme();

        const formattedDates = React.useMemo(() => {
            return [
                project.start_date ? format(new Date(project.start_date), "MMM yyyy") : null,
                project.end_date ? format(new Date(project.end_date), "MMM yyyy") : null,
            ]
                .filter(isNonNullable)
                .join(" - ");
        }, [project.start_date, project.end_date]);

        const otherProjectMembers = React.useMemo(() => {
            return project.all_members?.filter(member => member.unique_id !== personId);
        }, [project.all_members, personId]);

        return (
            <Box
                key={project.id}
                sx={{
                    px: 1.5,
                    py: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    cursor: "pointer",
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: "neutrals.25",
                    borderRadius: 2,
                    "&:hover": { bgcolor: "action.hover" },
                }}
                onClick={onSelect}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start", flex: 1, minWidth: 0 }}>
                        <ProjectIconWithHalo />
                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                            <Typography
                                variant="body2"
                                color="neutrals.80"
                                noWrap
                                fontWeight={550}
                                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                            >
                                {project.name}
                            </Typography>
                            {(project.start_date != null || project.end_date != null) && (
                                <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                    <Calendar size={14} color={theme.palette.neutrals[50]} />
                                    <Typography variant="caption" color="neutrals.50" noWrap>
                                        {formattedDates}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {project.id != null && (
                        <Typography variant="caption" color="neutrals.50" sx={{ flexShrink: 0 }}>
                            #{project.id.split("-")[1]}
                        </Typography>
                    )}
                </Box>
                {!hideCollaborators && otherProjectMembers?.length > 0 && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: 36,
                            }}
                        >
                            {otherProjectMembers.slice(0, 2).map((member, index) => (
                                <Avatar
                                    key={member.unique_id}
                                    src={member.profile_picture_url ?? undefined}
                                    alt={`${member.first_name} ${member.last_name}`}
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        ml: index === 0 ? 0 : -0.8,
                                        border: 1,
                                        borderColor: "white",
                                        zIndex: index + 1,
                                    }}
                                />
                            ))}
                        </Box>
                        <Typography variant="caption" color="neutrals.50" sx={{ flexShrink: 0 }} noWrap>
                            {otherProjectMembers.length === 1
                                ? `${otherProjectMembers[0].first_name} ${otherProjectMembers[0].last_name} collaborated`
                                : `${otherProjectMembers.length} others collaborated`}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    },
);

ProjectForPerson.displayName = "ProjectForPerson";
