import * as React from "react";
import { Project } from "../types";
import { PreviewSectionLayout } from "../../shared/previewSectionLayout";
import { SearchViewHeader } from "../searchViewHeader";
import { ProjectPreviewSection } from "./projectPreviewSection";
import { ProjectFilePreviewSection } from "./projectFilePreviewSection";

interface ProjectPreviewSectionProps {
    project: Project;
    fileIdx?: number;
    onSelect: (fileIdx: number | undefined) => void;
    onClose: () => void;
}

export const ProjectAnswerPreviewSection = React.memo(
    ({ project, fileIdx, onSelect, onClose }: ProjectPreviewSectionProps) => {
        const handleBackFromFile = React.useCallback(() => {
            onSelect(undefined);
        }, [onSelect]);

        const header = React.useMemo(
            () => (
                <SearchViewHeader
                    text={
                        fileIdx != null
                            ? `${project.name} (${project.id.split("-")[1]}) - File ${fileIdx + 1}`
                            : `${project.name} (${project.id.split("-")[1]})`
                    }
                    tooltip="Preview the selected project and its files"
                    onBack={fileIdx != null ? handleBackFromFile : undefined}
                />
            ),
            [project.name, project.id, fileIdx, handleBackFromFile],
        );

        const handleSelectFile = React.useCallback(
            (fileIdx: number) => {
                onSelect(fileIdx);
            },
            [onSelect],
        );

        return (
            <PreviewSectionLayout header={header} onClose={onClose}>
                {fileIdx != null ? (
                    <ProjectFilePreviewSection project={project} fileIdx={fileIdx} />
                ) : (
                    <ProjectPreviewSection project={project} onSelectFile={handleSelectFile} />
                )}
            </PreviewSectionLayout>
        );
    },
);

ProjectAnswerPreviewSection.displayName = "ProjectAnswerPreviewSection";
