import * as React from "react";
import { Box, Typography, Avatar, useTheme, Theme, SxProps } from "@mui/material";
import { Calendar, LampCharge } from "iconsax-react";
import { Project } from "./types";
import { format } from "date-fns";
import { isNonNullable } from "../utils/isNonNullable";
import { ProjectIconWithHalo } from "./projectIconWithHalo";

export const MIN_WIDTH = 320;

type ProjectAnswerCardProps = {
    project: Project;
    onSelect: () => void;
    isSelected: boolean;
    sx?: SxProps<Theme>;
};

export const ProjectAnswerCard = React.memo(({ project, onSelect, isSelected, sx }: ProjectAnswerCardProps) => {
    const theme = useTheme();

    const formattedDates = React.useMemo(() => {
        return [
            project.start_date ? format(new Date(project.start_date), "MMM yyyy") : null,
            project.end_date ? format(new Date(project.end_date), "MMM yyyy") : null,
        ]
            .filter(isNonNullable)
            .join(" - ");
    }, [project.start_date, project.end_date]);

    const parsedQueryAwareSummary = React.useMemo(() => {
        if (project.query_aware_summary == null) return null;

        // Split by bold tags and map to array of regular text and bold elements
        return project.query_aware_summary.split(/<\/?b>/).map((part, index) =>
            index % 2 === 1 ? (
                <Typography key={index} variant="caption" component="span" color="neutrals.60" fontWeight={500}>
                    {part}
                </Typography>
            ) : (
                part
            ),
        );
    }, [project.query_aware_summary]);

    return (
        <Box
            onClick={onSelect}
            className="parent"
            sx={[
                {
                    mt: 2,
                    px: 2,
                    py: 1.5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    cursor: "pointer",
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: isSelected ? "selection.main" : "neutrals.25",
                    borderRadius: 2,
                    position: "relative",
                    minWidth: MIN_WIDTH,
                    "&:hover": {
                        // bgcolor: "neutrals.10",
                        borderColor: isSelected ? "selection.main" : "neutrals.30",
                        "&::before": {
                            // bgcolor: "neutrals.10",
                            borderColor: isSelected ? "selection.main" : "neutrals.30",
                        },
                        "&::after": {
                            backgroundColor: isSelected ? "selection.main" : "neutrals.30",
                        },
                    },
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: -10,
                        left: -1,
                        width: "calc(25% + 1px)",
                        height: 10.5,
                        bgcolor: "surface.0",
                        borderTop: 1,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "inherit",
                        borderTopLeftRadius: theme.spacing(2),
                        borderTopRightRadius: 0,
                        clipPath: "polygon(0 0, 90% 0, 100% 100%, 0 100%)",
                    },
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        left: "calc(22.5% - 0.5px)",
                        top: "-9.7px",
                        height: "calc(sin(45deg) * 19.5px)",
                        width: "1px",
                        backgroundColor: isSelected ? "selection.main" : "neutrals.25",
                        transform: "rotate(-45deg)",
                        transformOrigin: "top center",
                        zIndex: 2,
                    },
                    // Remove top border for the tab portion
                    borderTopLeftRadius: 0,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <Box sx={{ display: "flex", gap: 1.5, alignItems: "flex-start", flex: 1, minWidth: 0 }}>
                    <ProjectIconWithHalo size={40} />
                    {/* {project.client_logo_url != null ? (
                        <Avatar
                            src={project.client_logo_url}
                            alt={project.client_name ?? ""}
                            sx={{ width: 40, height: 40 }}
                        />
                    ) : project.client_name != null ? (
                        <Avatar
                            alt={project.client_name ?? ""}
                            sx={{ width: 40, height: 40, fontSize: 12, fontWeight: 550 }}
                        >
                            {project.client_name
                                ?.split(" ")
                                .slice(0, 3)
                                .map(name => name[0])
                                .join("")}
                        </Avatar>
                    ) : (
                        <ProjectIconWithHalo size={40} />
                    )} */}
                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                        <Typography
                            variant="body2"
                            color="neutrals.80"
                            noWrap
                            fontWeight={550}
                            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                        >
                            {project.name}
                        </Typography>
                        {(project.start_date != null || project.end_date != null) && (
                            <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                <Calendar size={14} color={theme.palette.neutrals[50]} />
                                <Typography variant="caption" color="neutrals.50" noWrap>
                                    {formattedDates}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {project.id != null && (
                    <Typography variant="caption" color="neutrals.50" sx={{ flexShrink: 0 }}>
                        #{project.id.split("-")[1]}
                    </Typography>
                )}
            </Box>

            {project.all_members?.length > 0 ? (
                <Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "flex-start" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", width: 40 }}>
                        {project.all_members.slice(0, 2).map((member, index) => (
                            <Avatar
                                key={member.unique_id}
                                src={member.profile_picture_url ?? undefined}
                                alt={`${member.first_name} ${member.last_name}`}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    ml: index === 0 ? 0 : -0.8,
                                    border: 1,
                                    borderColor: "white",
                                    zIndex: index + 1,
                                }}
                            />
                        ))}
                    </Box>
                    <Typography variant="caption" color="neutrals.50" sx={{ flexShrink: 0 }} noWrap>
                        {project.all_members.length === 1
                            ? `${project.all_members[0].first_name} ${project.all_members[0].last_name} contributed`
                            : `${project.all_members.length} people contributed`}
                    </Typography>
                </Box>
            ) : (
                <Box height={24} />
            )}
            {/* Placeholder for AI Callout - to be implemented later */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                    py: 1,
                    bgcolor: "surface.25",
                    minHeight: 48,
                    maxHeight: 80,
                    px: 1,
                    overflow: "hidden",
                    // borderRadius: 0.5,
                }}
            >
                <LampCharge size={16} color={theme.palette.neutrals[100]} variant="Bold" style={{ flexShrink: 0 }} />
                <Typography
                    variant="caption"
                    color="neutrals.50"
                    sx={{ display: "-webkit-box", WebkitLineClamp: 2, overflow: "hidden" }}
                >
                    {parsedQueryAwareSummary}
                </Typography>
            </Box>
        </Box>
    );
});

ProjectAnswerCard.displayName = "ProjectAnswerCard";
