import * as React from "react";
import { Box } from "@mui/material";

export const IconWithHalo: React.FC<{ size?: number; children: React.ReactNode }> = ({ size = 36, children }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "neutrals.25",
            borderRadius: "50%",
            width: size,
            height: size,
            flexShrink: 0,
        }}
    >
        {children}
    </Box>
);
