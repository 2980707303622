import * as React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Project } from "./types";
import { MIN_WIDTH as PROJECT_CARD_MIN_WIDTH, ProjectAnswerCard } from "./projectAnswerCard";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";

type ProjectAnswerSectionProps = {
    projects: Project[];
    selectedIdx?: number;
    isCollapsed: boolean;
    hasLoadedMore: boolean;
    canLoadMore: boolean;
    isLoadingMore: boolean;
    onSelectProject: (idx: number) => void;
    onLoadMore: () => Promise<void>;
    onToggleCollapse: () => void;
};

const MAX_INITIAL_ROWS_TO_SHOW = 3;

export const ProjectAnswerSection = React.memo(
    ({
        projects,
        selectedIdx,
        onSelectProject,
        isCollapsed,
        onToggleCollapse,
        hasLoadedMore,
        canLoadMore,
        onLoadMore,
        isLoadingMore,
    }: ProjectAnswerSectionProps) => {
        const visibleProjects = React.useMemo(() => {
            return isCollapsed || !hasLoadedMore ? projects.slice(0, MAX_INITIAL_ROWS_TO_SHOW * 2) : projects;
        }, [isCollapsed, hasLoadedMore, projects]);

        const hiddenProjects = React.useMemo(() => {
            return isCollapsed || !hasLoadedMore ? projects.slice(MAX_INITIAL_ROWS_TO_SHOW * 2) : [];
        }, [isCollapsed, hasLoadedMore, projects]);

        const showShowMoreButton = hasLoadedMore && hiddenProjects.length > 0 && isCollapsed;

        const showLoadMoreButton = isLoadingMore || !hasLoadedMore;

        const showShowLessButton = !isCollapsed && hasLoadedMore && projects.length > MAX_INITIAL_ROWS_TO_SHOW * 2;

        if (projects.length === 0) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, overflowX: "auto", mb: 3 }}>
                    <Typography variant="body1">No results found.</Typography>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 3,
                    alignItems: "stretch",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        rowGap: 0.5,
                        columnGap: 2,
                        width: "100%",
                        mb: showShowMoreButton || showLoadMoreButton || showShowLessButton ? 2 : 0,
                    }}
                >
                    {visibleProjects.map((project, idx) => (
                        <ProjectAnswerCard
                            key={project.id}
                            project={project}
                            onSelect={() => onSelectProject(idx)}
                            isSelected={selectedIdx === idx}
                        />
                    ))}
                </Box>
                {showShowMoreButton && (
                    <Button
                        onClick={onToggleCollapse}
                        fullWidth
                        variant="outlined"
                        sx={{
                            textTransform: "none",
                            bgcolor: "surface.0",
                            borderColor: "neutrals.25",
                            color: "secondary.main",
                            padding: 1.5,
                            gap: 0.5,
                            display: "flex",
                            alignItems: "center",
                            "&:hover": {
                                bgcolor: "neutrals.5",
                            },
                        }}
                    >
                        <Typography variant="body2" color="secondary.main" fontWeight={550}>
                            Show {hiddenProjects.length} more projects
                        </Typography>
                        <ArrowDown2 size={16} variant="Bold" />
                    </Button>
                )}
                {showLoadMoreButton && (
                    <Button
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={onLoadMore}
                        variant="outlined"
                        disabled={!canLoadMore || isLoadingMore}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        sx={theme => ({
                            textTransform: "none",
                            bgcolor: "surface.0",
                            borderColor: "neutrals.25",
                            color: "secondary.main",
                            padding: 1.5,
                            gap: 0.5,
                            display: "flex",
                            height: 46,
                            alignItems: "center",
                            minWidth: `calc((2 * ${PROJECT_CARD_MIN_WIDTH}px) + ${theme.spacing(2)}) !important`,
                            "&:hover": {
                                bgcolor: "surface.0",
                                borderColor: "neutrals.30",
                            },
                        })}
                    >
                        {isLoadingMore ? (
                            <CircularProgress size={18} sx={{ color: "secondary.main" }} />
                        ) : (
                            <>
                                <Typography variant="body2" color="secondary.main" fontWeight={550}>
                                    Load more projects
                                </Typography>
                                <ArrowDown2 size={16} variant="Bold" />
                            </>
                        )}
                    </Button>
                )}
                {showShowLessButton && (
                    <Button
                        onClick={onToggleCollapse}
                        variant="outlined"
                        sx={theme => ({
                            textTransform: "none",
                            bgcolor: "surface.0",
                            borderColor: "neutrals.25",
                            color: "secondary.main",
                            padding: 1.5,
                            gap: 0.5,
                            display: "flex",
                            alignItems: "center",
                            minWidth: `calc((2 * ${PROJECT_CARD_MIN_WIDTH}px) + ${theme.spacing(2)}) !important`,
                            "&:hover": {
                                bgcolor: "neutrals.10",
                            },
                        })}
                    >
                        <Typography variant="body2" color="secondary.main" fontWeight={550}>
                            Show less
                        </Typography>
                        <ArrowUp2 size={16} variant="Bold" />
                    </Button>
                )}
            </Box>
        );
    },
);

ProjectAnswerSection.displayName = "ProjectAnswerSection";
