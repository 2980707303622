import * as React from "react";
import { Box, Typography } from "@mui/material";
import { getFavIconUrl, formatDisplayUrl, extractDomain } from "../utils/url";
import { Link } from "iconsax-react";

interface WebSourceBoxProps {
    url: string;
    title: string | undefined;
    description: string | undefined;
    rank: number;
    isStreaming?: boolean;
}

export const WebSourceBox: React.FC<WebSourceBoxProps> = ({ url, title, description, rank, isStreaming = false }) => {
    const displayUrl = React.useMemo(() => formatDisplayUrl(url), [url]);
    const domain = React.useMemo(() => extractDomain(url) ?? "", [url]);
    const [imageError, setImageError] = React.useState(false);

    const handleImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    const skeletonWidths = React.useMemo(
        () =>
            ({
                title: Math.floor(70 + Math.random() * 20), // 70-90%
                descLine2: Math.floor(50 + Math.random() * 30), // 50-80%
            }) as const,
        [],
    );

    return (
        <Box
            sx={{
                width: 240,
                height: 103,
                flexShrink: 0,
                flexGrow: 0,
                border: 1,
                borderColor: "neutrals.20",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                bgcolor: "surface.0",
                px: 1.5,
                py: 1,
                overflow: "hidden",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                {isStreaming ? (
                    <Box
                        sx={{
                            width: `${skeletonWidths.title}%`,
                            height: 12,
                            bgcolor: "neutrals.20",
                            borderRadius: 0.5,
                            my: 0.5,
                        }}
                    />
                ) : (
                    <Typography
                        component="a"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="caption"
                        color="neutrals.70"
                        fontWeight={500}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            cursor: "pointer",
                            textDecoration: "none",
                            "&:hover": {
                                bgcolor: "action.hover",
                            },
                        }}
                    >
                        {title && title.length > 0 ? title : displayUrl}
                    </Typography>
                )}

                {isStreaming ? (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                        <Box
                            sx={{
                                width: `100%`,
                                height: 10,
                                bgcolor: "neutrals.20",
                                borderRadius: 0.5,
                                my: 0.5,
                            }}
                        />
                        <Box
                            sx={{
                                width: `${skeletonWidths.descLine2}%`,
                                height: 10,
                                bgcolor: "neutrals.20",
                                borderRadius: 0.5,
                                mb: 0.5,
                            }}
                        />
                    </Box>
                ) : (
                    <Typography
                        variant="caption"
                        sx={{
                            color: "neutrals.50",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            alignSelf: "flex-start",
                        }}
                    >
                        {description && description.length > 0 ? description : "No description available"}
                    </Typography>
                )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                {imageError ? (
                    <Link size={20} />
                ) : (
                    <img
                        // TODO: Find a way when the icon doesn't exist to use link icon
                        src={getFavIconUrl(url, "duckduckgo")}
                        alt={`${domain} favicon`}
                        width={20}
                        height={20}
                        style={{ borderRadius: 4, flexShrink: 0 }}
                        onError={handleImageError}
                    />
                )}
                <Typography
                    variant="caption"
                    sx={{
                        color: "neutrals.60",
                        flexShrink: 1,
                        // maxWidth: "calc(100% - 30px)",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {domain}
                </Typography>
                <Box
                    sx={{
                        ml: "auto",
                        color: "citations.main",
                        typography: "caption",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        px: 0.25,
                        border: 1,
                        borderColor: "neutrals.20",
                        borderRadius: 1,
                        width: 16,
                        flexShrink: 0,
                    }}
                >
                    {`${rank + 1}`}
                </Box>
            </Box>
        </Box>
    );
};
