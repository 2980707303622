import { queryOptions } from "@tanstack/react-query";
import {
    coreChatRouterGetChat,
    coreChatRouterListChats,
    coreFileStorageRouterListFiles,
    corePersonaRouterListPersonas,
    coreHistoryRouterGetRecentHistory,
    coreHistoryRouterSearchHistory,
    coreBlobRouterGetBlobInfoBatch,
} from "../backend-client/generated/sdk.gen";
import { coreAuthRouterGetCurrentUser, coreAuthRouterGetTenant } from "../backend-client/generated/sdk.gen";
import { DEFAULT_PERSONA } from "../data/defaultPersona";
import { hasToken, LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { PersonData } from "../search/types";

export const chatQueryOptions = (chatId: string) =>
    queryOptions({
        queryKey: ["chat", chatId],
        queryFn: () => coreChatRouterGetChat({ path: { chat_id: chatId } }).then(response => response.data),
    });

export const chatsQueryOptions = queryOptions({
    queryKey: ["chats"],
    queryFn: () => coreChatRouterListChats().then(response => response.data),
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
});

const CURRENT_USER_CACHE_KEY = "cached_current_user";
export const currentUserQueryOptions = queryOptions({
    queryKey: ["currentUser"],
    queryFn: () =>
        coreAuthRouterGetCurrentUser({
            throwOnError: true,
            credentials: "include",
        }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(CURRENT_USER_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(CURRENT_USER_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: hasToken(),
});

const TENANT_CACHE_KEY = "cached_tenant";
export const tenantQueryOptions = queryOptions({
    queryKey: ["tenant"],
    queryFn: () =>
        coreAuthRouterGetTenant({ throwOnError: true }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(TENANT_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(TENANT_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) != null,
});

export const personasQueryOptions = queryOptions({
    queryKey: ["personas"],
    queryFn: () => corePersonaRouterListPersonas().then(response => response.data),
    select: data => [...(data ?? []), DEFAULT_PERSONA],
});

export const getFilesQueryOptions = (folderId: string | undefined) =>
    queryOptions({
        queryKey: ["files", folderId],
        queryFn: () =>
            coreFileStorageRouterListFiles({
                query: { folder_id: folderId },
            }),
    });

export const recentHistoryQueryOptions = (type: "all" | "chats" | "searches") =>
    queryOptions({
        queryKey: ["history", "recent", type],
        queryFn: () =>
            coreHistoryRouterGetRecentHistory({
                body: { type },
            }).then(response => response.data?.items ?? []),
    });

export const searchHistoryQueryOptions = (query: string, type: "all" | "chats" | "searches") =>
    queryOptions({
        queryKey: ["history", "search", query, type],
        queryFn: () =>
            coreHistoryRouterSearchHistory({
                body: { query, type },
            }).then(response => response.data?.items ?? []),
        enabled: query.trim().length > 0,
    });

export const projectDocumentNamesQueryOptions = (project: PersonData["projects"][number]) =>
    queryOptions({
        queryKey: ["projectDocumentNames", project.file_ids],
        queryFn: async () => {
            const blobNames = project.file_ids.map(fileId => `${fileId}.pdf`);
            const response = await coreBlobRouterGetBlobInfoBatch({
                throwOnError: true,
                body: {
                    blob_names: project.file_ids.map(fileId => `${fileId}.pdf`),
                    container_type: "pdfs",
                },
            });
            return blobNames.map(name => response.data.results[name]?.name ?? undefined);
        },
    });
