import * as React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { PersonData } from "./types";
import { CARD_WIDTH, PersonCard } from "./personCard";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";

type PersonAnswerSectionProps = {
    people: PersonData[];
    onSelectPerson: (idx: number, projectIdx: number | undefined) => void;
};

export const PersonAnswerSection = React.memo(({ people, onSelectPerson }: PersonAnswerSectionProps) => {
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const toggleCollapse = React.useCallback((shouldScroll?: boolean) => {
        setIsCollapsed(prev => {
            if (shouldScroll && !prev) {
                containerRef.current?.scrollIntoView();
            }
            return !prev;
        });
    }, []);

    const handleShowLess = React.useCallback(() => {
        toggleCollapse(true);
    }, [toggleCollapse]);

    const visiblePeople = React.useMemo(() => {
        return isCollapsed ? people.slice(0, 4) : people;
    }, [isCollapsed, people]);

    const hiddenPeople = React.useMemo(() => {
        return isCollapsed ? people.slice(4) : [];
    }, [isCollapsed, people]);

    return (
        <Box
            ref={containerRef}
            sx={{
                display: "flex",
                flexDirection: "column",
                width: 690,
                mb: 3,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1.5,
                    width: "100%",
                    mb: 2,
                }}
            >
                {visiblePeople.map((person, idx) => (
                    <PersonCard key={person.unique_id} person={person} idx={idx} onSelect={onSelectPerson} />
                ))}
            </Box>
            {hiddenPeople.length > 0 && isCollapsed && (
                <ShowMoreButton hiddenPeople={hiddenPeople} onClick={toggleCollapse} />
            )}
            {!isCollapsed && <ShowLessButton onClick={handleShowLess} />}
        </Box>
    );
});

PersonAnswerSection.displayName = "PersonAnswerSection";

type ShowMoreButtonProps = {
    hiddenPeople: PersonData[];
    onClick: () => void;
};

const AVATARS_COUNT = 4;
const AVATAR_SIZE = 32;

const ShowMoreButton = React.memo(({ hiddenPeople, onClick }: ShowMoreButtonProps) => {
    const displayedAvatars = React.useMemo(() => {
        const avatars = hiddenPeople.slice(0, AVATARS_COUNT).map((person, index) => (
            <Avatar
                key={person.unique_id}
                src={person.profile_picture_url ?? undefined}
                alt={`${person.first_name} ${person.last_name}`}
                sx={{
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                    ml: index === 0 ? 0 : -1.2, // Overlap by ~20%
                    border: 2,
                    borderColor: "white",
                    zIndex: index + 1,
                }}
            />
        ));
        const remainingCount = hiddenPeople.length - AVATARS_COUNT;

        if (remainingCount > 0) {
            avatars.push(
                <Box
                    key="remaining"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ml: -1.2,
                        bgcolor: "surface.25",
                        border: 1,
                        borderColor: "white",
                        height: AVATAR_SIZE,
                        width: AVATAR_SIZE,
                        borderRadius: "50%",
                        zIndex: AVATARS_COUNT + 1,
                    }}
                >
                    <Typography variant="body2" color="secondary.main" fontWeight={550}>
                        +{remainingCount}
                    </Typography>
                </Box>,
            );
        }

        return avatars;
    }, [hiddenPeople]);

    return (
        <Box
            onClick={onClick}
            sx={theme => ({
                bgcolor: "surface.0",
                height: 56,
                border: 1,
                borderColor: "neutrals.25",
                borderRadius: 2,
                padding: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: "secondary.main",
                width: `calc(${2 * CARD_WIDTH}px +  ${theme.spacing(1.5)})`,
                "&:hover": {
                    bgcolor: "neutrals.10",
                },
            })}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>{displayedAvatars}</Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mr: 0.5 }}>
                <Typography variant="body2" color="secondary.main" fontWeight={550}>
                    Show more
                </Typography>
                <ArrowDown2 size={16} variant="Bold" />
            </Box>
        </Box>
    );
});

ShowMoreButton.displayName = "ShowMoreButton";

interface ShowLessButtonProps {
    onClick: () => void;
}

const ShowLessButton = React.memo(({ onClick }: ShowLessButtonProps) => {
    return (
        <Button
            onClick={onClick}
            fullWidth
            variant="outlined"
            sx={theme => ({
                justifyContent: "center",
                textTransform: "none",
                bgcolor: "surface.0",
                borderColor: "neutrals.25",
                borderRadius: 2,
                color: "secondary.main",
                padding: 1.5,
                gap: 0.5,
                display: "flex",
                alignItems: "center",
                width: `calc(${2 * CARD_WIDTH}px +  ${theme.spacing(1.5)})`,
                "&:hover": {
                    bgcolor: "neutrals.30",
                },
            })}
        >
            <Typography variant="body2">Show less</Typography>
            <ArrowUp2 size={16} variant="Bold" />
        </Button>
    );
});

ShowLessButton.displayName = "ShowLessButton";
