import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip } from "@mui/material";
import { ArrowUp, BookSaved as Book1 } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { getScrollbarSx } from "./scrollbarProps";
import { HistoryDialog } from "../history/historyDialog";
import { tenantQueryOptions } from "../chat/queryOptions";
import { useQuery } from "@tanstack/react-query";
import { ContextModeButton } from "../chat/contextModeButton";
import { Model } from "../chat/types";
import { DEFAULT_MODEL_FOR_BASE_CHAT } from "../chat/models";

interface SearchInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    isDirectBaseModelChat: boolean;
    model: Model | undefined;
    multiline?: boolean;
    sx?: SxProps<Theme>;
    onModelChange: (model: Model | undefined) => void;
    onDirectBaseModelChatToggle: () => void;
}

const SEND_ICON_SIZE = 24;

export const SearchHomeInput: React.FC<SearchInputProps> = ({
    query,
    disabledReason,
    isDirectBaseModelChat,
    model,
    multiline = false,
    sx,
    onModelChange,
    onDirectBaseModelChatToggle,
    onQueryChange,
    onQuerySubmit,
}) => {
    const theme = useTheme();
    const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);

    const handleHistoryOpen = React.useCallback(() => {
        setIsHistoryOpen(true);
    }, []);

    const handleHistoryClose = React.useCallback(() => {
        setIsHistoryOpen(false);
    }, []);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    const historyButton = (
        <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size="small"
            onClick={handleHistoryOpen}
            sx={{ ml: multiline ? -0.5 : 0 }}
        >
            <Book1 size={24} color={theme.palette.neutrals[60]} />
        </IconButton>
    );

    const tenant = useQuery(tenantQueryOptions);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleModelChange = React.useCallback(
        (model: Model) => {
            onModelChange(model);
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        },
        [onModelChange],
    );

    const handleToggleContextMode = React.useMemo(() => {
        if (onDirectBaseModelChatToggle == null) {
            return undefined;
        }
        return () => {
            onDirectBaseModelChatToggle?.();
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        };
    }, [onDirectBaseModelChatToggle]);

    React.useEffect(() => {
        const handleGlobalKeyDown = (event: KeyboardEvent) => {
            if (
                event.key === "s" &&
                event.shiftKey &&
                (event.metaKey || event.ctrlKey) &&
                handleToggleContextMode != null
            ) {
                event.preventDefault();
                handleToggleContextMode();
            }
        };

        document.addEventListener("keydown", handleGlobalKeyDown);
        return () => document.removeEventListener("keydown", handleGlobalKeyDown);
    }, [handleToggleContextMode]);

    return (
        <>
            <Box
                sx={[
                    {
                        display: "flex",
                        ...(multiline
                            ? {
                                  flexDirection: "column",
                                  alignItems: "stretch",
                              }
                            : {
                                  alignItems: "center",
                              }),
                        bgcolor: "surface.0",
                        borderRadius: 2,
                        border: 1,
                        borderColor: "neutrals.30",
                        "&:focus-within": {
                            borderColor: "neutrals.40",
                        },
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
            >
                <Input
                    autoFocus
                    placeholder="Find answers"
                    value={query}
                    inputRef={inputRef}
                    startAdornment={!multiline ? historyButton : undefined}
                    onChange={onQueryChange}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    disableUnderline
                    multiline={multiline}
                    minRows={multiline ? 2 : undefined}
                    maxRows={multiline ? 3 : undefined}
                    sx={{
                        pl: multiline ? 1.5 : undefined,
                        ...(multiline && { pt: 1.5 }),
                        height: multiline ? "auto" : 48,
                        ...(!multiline && { pr: 1 }),
                        "& .MuiInputBase-input": { ...getScrollbarSx("surface.0") },
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pl: multiline ? 1 : undefined,
                        pr: multiline ? 0.5 : undefined,
                        py: multiline ? 0.5 : undefined,
                    }}
                >
                    {multiline && (tenant.data?.can_chat_with_base_model ?? false) ? (
                        <ContextModeButton
                            isDirectBaseModelChat={isDirectBaseModelChat}
                            onToggle={handleToggleContextMode}
                            model={model ?? DEFAULT_MODEL_FOR_BASE_CHAT}
                            onModelChange={handleModelChange}
                            menuPosition="bottom"
                        />
                    ) : (
                        historyButton
                    )}
                    <Tooltip title={disabledReason ?? ""}>
                        <span>
                            <IconButton disabled={disabledReason != null} onClick={onQuerySubmit} size="small">
                                <Box
                                    sx={{
                                        bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                        borderRadius: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: SEND_ICON_SIZE,
                                        height: SEND_ICON_SIZE,
                                    }}
                                >
                                    <ArrowUp size={SEND_ICON_SIZE} color={theme.palette.common.white} />
                                </Box>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <HistoryDialog open={isHistoryOpen} onClose={handleHistoryClose} defaultTab="searches" />
        </>
    );
};
