import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { projectDocumentNamesQueryOptions } from "../../chat/queryOptions";
import { PDFPreview, PDFPreviewSource } from "../pdfPreview";
import { PersonData } from "../types";

interface ProjectFilePreviewSectionProps {
    project: PersonData["projects"][number];
    fileIdx: number;
}

export const ProjectFilePreviewSection = React.memo(({ project, fileIdx }: ProjectFilePreviewSectionProps) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const { data: documentNames } = useQuery(projectDocumentNamesQueryOptions(project));

    const previewSource = React.useMemo(
        (): Required<PDFPreviewSource> => ({
            file_name: documentNames?.[fileIdx] ?? "Untitled",
            // TODO: Wire other file types
            file_type: "pdf",
            pages: [], // You'll need to populate this from your data
            blob_name: project.file_ids[fileIdx],
            is_user_file: false,
            project_code: project.id.split("-")[1],
            project_name: project.name,
            project_id: project.id,
            time_created: null,
        }),
        [documentNames, fileIdx, project.file_ids, project.id, project.name],
    );

    const handlePageChange = React.useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    return <PDFPreview source={previewSource} page={currentPage} onPageChange={handlePageChange} />;
});

ProjectFilePreviewSection.displayName = "ProjectFilePreviewSection";
