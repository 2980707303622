import * as React from "react";
import { Box, IconButton, Typography, TextField, Skeleton, useTheme } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { chatQueryOptions, chatsQueryOptions, personasQueryOptions } from "./queryOptions";
import { ArrowRight2, Edit2, UserOctagon } from "iconsax-react";
import { coreChatRouterUpdateChat } from "../backend-client/generated/sdk.gen";
import { IosShare } from "@mui/icons-material";
import { ShareDialog } from "./ShareDialog";
import { UserFileContextSwitcher } from "./context/userFileContextSwitcher";
import { PersonaPickerDialog } from "./PersonaPickerDialog";
import { SelectedFile } from "./context/focusDocuments";
import { SelectedPersona } from "./selectedPersona";
import { DocCountIcon } from "./context/docCountIcon";
import { UserFocusedDocumentPopper } from "./context/userFocusedDocumentPopper";

interface ChatHeaderWithExtraFeaturesProps {
    /**
     * The name of the chat.
     * If undefined, the chat is being loaded.
     */
    chatName: string | undefined;
    /**
     * The ID of the chat.
     */
    chatId: string | undefined;
    focusedDocuments: Array<SelectedFile>;
    onFocusedDocumentsChange: ((docs: Array<SelectedFile>) => void) | undefined;
    selectedPersona: SelectedPersona;
    onSelectedPersonaChange: React.Dispatch<React.SetStateAction<SelectedPersona>> | undefined;
}

export const ChatHeaderWithExtraFeatures: React.FC<ChatHeaderWithExtraFeaturesProps> = ({
    chatName,
    chatId,
    focusedDocuments,
    onFocusedDocumentsChange,
    selectedPersona,
    onSelectedPersonaChange,
}) => {
    const [isEditing, setIsEditing] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [editedName, setEditedName] = React.useState(chatName);
    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);
    const [isPersonaPickerOpen, setIsPersonaPickerOpen] = React.useState(false);
    const [docAnchorEl, setDocAnchorEl] = React.useState<HTMLElement | null>(null);

    const queryClient = useQueryClient();

    const updateChatMutation = useMutation({
        mutationFn: ({ newName }: { newName: string }) =>
            coreChatRouterUpdateChat({
                // HACK FOR TYPES
                path: { chat_id: chatId ?? "" },
                body: { name: newName, messages: null, file_scope: null, user_file_scope: null },
            }),
        onSuccess: (_, { newName }) => {
            const singleQueryOptions = chatQueryOptions(chatId ?? "");
            void queryClient.setQueryData(singleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return { ...oldData, updated_at: new Date().toISOString(), name: newName };
            });
            const multipleQueryOptions = chatsQueryOptions;
            void queryClient.setQueryData(multipleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return oldData.map(chat =>
                    chat.unique_id === chatId ? { ...chat, updated_at: new Date().toISOString(), name: newName } : chat,
                );
            });
            setIsEditing(false);
        },
    });

    const handleEditClick = React.useCallback(() => {
        setIsEditing(true);
        setEditedName(chatName);
    }, [chatName]);

    const handleEditComplete = React.useCallback(() => {
        if (chatId != null && editedName != null && editedName.trim() !== "" && editedName !== chatName) {
            updateChatMutation.mutate({ newName: editedName });
        } else {
            setIsEditing(false);
        }
    }, [chatId, editedName, chatName, updateChatMutation]);

    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, []);

    const handleChangeChatName = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(e.target.value);
    }, []);

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                handleEditComplete();
            }
        },
        [handleEditComplete],
    );

    const handleShare = React.useCallback(() => {
        setIsShareDialogOpen(true);
    }, []);

    const handleCloseShareDialog = React.useCallback(() => {
        setIsShareDialogOpen(false);
    }, []);

    const handlePersonaPickerOpen = React.useCallback(() => {
        setIsPersonaPickerOpen(true);
    }, []);

    const handlePersonaPickerClose = React.useCallback(() => {
        setIsPersonaPickerOpen(false);
    }, []);

    const theme = useTheme();

    const { data: personas } = useQuery(personasQueryOptions);

    const personaName = React.useMemo(() => {
        const persona = personas?.find(p => p.unique_id === selectedPersona.id && p.type === selectedPersona.type);
        return persona?.name;
    }, [selectedPersona, personas]);

    const handleFocusedDocumentsChange = React.useMemo(
        () =>
            onFocusedDocumentsChange == null
                ? undefined
                : (docs: Array<SelectedFile>) => onFocusedDocumentsChange(docs),
        [onFocusedDocumentsChange],
    );

    const focusedDocumentCount = React.useMemo(() => focusedDocuments?.length ?? 0, [focusedDocuments]);

    const handleDocIconMouseEnter = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setDocAnchorEl(event.currentTarget);
    }, []);

    const handleDocIconMouseLeave = React.useCallback(() => {
        setDocAnchorEl(null);
    }, []);

    const canEditFocus = onFocusedDocumentsChange != null;

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    px: 2,
                    py: 1,
                    bgcolor: "primary.main",
                    justifyContent: "space-between",
                    borderRadius: 3,
                    position: "relative",
                    minWidth: 800,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <UserFileContextSwitcher
                        focusedDocuments={focusedDocuments}
                        onFocusedDocumentsChange={handleFocusedDocumentsChange}
                    />
                    {focusedDocumentCount > 0 && (
                        <DocCountIcon
                            focusedDocumentCount={focusedDocumentCount}
                            canEditFocus={canEditFocus}
                            onMouseEnter={handleDocIconMouseEnter}
                            onMouseLeave={handleDocIconMouseLeave}
                        />
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        justifyContent: "center",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {isEditing ? (
                        <TextField
                            value={editedName}
                            onChange={handleChangeChatName}
                            onBlur={handleEditComplete}
                            onKeyDown={handleKeyDown}
                            variant="standard"
                            autoFocus
                            InputProps={{
                                disableUnderline: true,
                                sx: { height: "20px" },
                            }}
                            sx={{
                                width: "auto",
                                "& .MuiInput-root": {
                                    color: "text.primary",
                                    fontSize: "body2.fontSize",
                                },
                            }}
                        />
                    ) : chatName != null ? (
                        <Typography variant="body2" noWrap color="neutrals.80">
                            {chatName}
                        </Typography>
                    ) : (
                        <Skeleton width={100} />
                    )}

                    {chatId != null && (isHovered || isEditing) && (
                        <>
                            <IconButton size="small" onClick={handleEditClick} sx={{ p: 0.5 }}>
                                <Edit2 size={16} />
                            </IconButton>
                            <IconButton size="small" onClick={handleShare} sx={{ p: 0.5 }}>
                                <IosShare sx={{ fontSize: 16 }} />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box
                    onClick={handlePersonaPickerOpen}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        cursor: "pointer",
                        bgcolor: "surface.0",
                        borderRadius: 2,
                        py: 0.5,
                        px: 1,
                        border: 1,
                        borderColor: "neutrals.30",
                        width: 300,
                        height: 32,
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, overflow: "hidden" }}>
                        <UserOctagon size={16} color={theme.palette.neutrals[80]} />
                        <Typography variant="caption" fontWeight={500} noWrap sx={{ flexShrink: 0 }}>
                            Assistant persona
                        </Typography>
                        <Typography variant="caption" noWrap sx={{ textOverflow: "ellipsis" }}>
                            {personaName}
                        </Typography>
                    </Box>
                    <IconButton size="small" disableRipple disableFocusRipple disableTouchRipple>
                        <ArrowRight2 size={12} />
                    </IconButton>
                </Box>
            </Box>
            <UserFocusedDocumentPopper focusedDocuments={focusedDocuments} anchorEl={docAnchorEl} />

            {chatId != null && (
                <ShareDialog open={isShareDialogOpen} onClose={handleCloseShareDialog} chatId={chatId} />
            )}
            <PersonaPickerDialog
                open={isPersonaPickerOpen}
                selectedPersona={selectedPersona}
                setSelectedPersona={onSelectedPersonaChange}
                onClose={handlePersonaPickerClose}
            />
        </>
    );
};
