export const getScrollbarSx = (color: string, isDarkMode = false) => ({
    "&::-webkit-scrollbar": {
        width: "14px",
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#98A2B3",
        borderRadius: "8px",
        border: `3px solid ${isDarkMode ? "#1D1D1D" : "#F4F2EF"}`,
        borderColor: "primary.main",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: color,
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
    },
});

export const scrollbarSx = getScrollbarSx("primary.main");

export const hideScrollbarSx = {
    "&::-webkit-scrollbar": {
        display: "none",
    },
    msOverflowStyle: "none", // IE and Edge
    scrollbarWidth: "none", // Firefox
} as const;
