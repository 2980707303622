import * as React from "react";
import { Box } from "@mui/material";
import { PersonData } from "../types";
import { ProjectForPerson, ProjectForPersonProps } from "../projectForPerson";
import { PreviewSectionLayout } from "../../shared/previewSectionLayout";
import { SearchViewHeader } from "../searchViewHeader";
import { formatName } from "../formatPersonName";
import { ProjectPreviewSection } from "./projectPreviewSection";
import { ProjectFilePreviewSection } from "./projectFilePreviewSection";
import { comparatorOnFields } from "../../utils/comparators";

interface PersonPreviewSectionProps {
    person: PersonData;
    projectIdx?: number;
    fileIdx?: number;
    onSelect: (projectIdx: number | undefined, fileIdx: number | undefined) => void;
    onClose: () => void;
}

export const PeopleAnswerPreviewSection = React.memo(
    ({ person, projectIdx, fileIdx, onSelect, onClose }: PersonPreviewSectionProps) => {
        const project = projectIdx != null ? person.projects[projectIdx] : undefined;

        const handleBackFromProject = React.useCallback(() => {
            onSelect(undefined, undefined);
        }, [onSelect]);

        const handleBackFromFile = React.useCallback(() => {
            onSelect(projectIdx, undefined);
        }, [onSelect, projectIdx]);

        const header = React.useMemo(
            () => (
                <SearchViewHeader
                    text={
                        fileIdx != null && project != null
                            ? `${project?.name} (${project?.id.split("-")[1]}) - File ${fileIdx + 1}`
                            : project != null
                              ? `Relevant Projects - ${formatName(person.first_name, person.last_name)} - ${project.name} (${project.id.split("-")[1]})`
                              : `Relevant Projects - ${formatName(person.first_name, person.last_name)}`
                    }
                    tooltip={`These are the projects that ${formatName(person.first_name, person.last_name)} has worked on that are most relevant to the area you asked about.`}
                    onBack={
                        fileIdx != null && project != null
                            ? handleBackFromFile
                            : project != null
                              ? handleBackFromProject
                              : undefined
                    }
                />
            ),
            [person.first_name, person.last_name, project, fileIdx, handleBackFromProject, handleBackFromFile],
        );

        const handleSelectProject = React.useCallback(
            (projectIdx: number) => {
                onSelect(projectIdx, undefined);
            },
            [onSelect],
        );

        const handleSelectFile = React.useCallback(
            (fileIdx: number) => {
                onSelect(projectIdx, fileIdx);
            },
            [onSelect, projectIdx],
        );

        return (
            <PreviewSectionLayout header={header} onClose={onClose}>
                <PersonPreviewSectionContent
                    person={person}
                    project={project}
                    fileIdx={fileIdx}
                    handleSelectProject={handleSelectProject}
                    handleSelectFile={handleSelectFile}
                />
            </PreviewSectionLayout>
        );
    },
);

PeopleAnswerPreviewSection.displayName = "PersonPreviewSection";

interface PersonPreviewSectionContentProps {
    person: PersonData;
    project?: PersonData["projects"][number];
    fileIdx?: number;
    handleSelectProject: (projectIdx: number) => void;
    handleSelectFile: (fileIdx: number) => void;
}

const PersonPreviewSectionContent = React.memo(
    ({ person, project, fileIdx, handleSelectProject, handleSelectFile }: PersonPreviewSectionContentProps) => {
        const projectsSortedByStartDate = React.useMemo(() => {
            return [...person.projects]
                .map((project, idx) => ({ ...project, idx }))
                .sort(
                    comparatorOnFields(project => [
                        project.start_date ? -new Date(project.start_date).getTime() : 0,
                        project.end_date ? -new Date(project.end_date).getTime() : 0,
                    ]),
                );
        }, [person.projects]);

        if (project != null && fileIdx != null) {
            return <ProjectFilePreviewSection project={project} fileIdx={fileIdx} />;
        }
        if (project != null) {
            return (
                <ProjectPreviewSection project={project} personId={person.unique_id} onSelectFile={handleSelectFile} />
            );
        }

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 3, pb: 4 }}>
                {projectsSortedByStartDate.map(project => (
                    <ProjectForPersonWithIdx
                        key={project.id ?? project.idx}
                        project={project}
                        personId={person.unique_id}
                        idx={project.idx}
                        onSelect={handleSelectProject}
                    />
                ))}
            </Box>
        );
    },
);

PersonPreviewSectionContent.displayName = "PersonPreviewSectionContent";

interface ProjectForPersonWithIdx extends Omit<ProjectForPersonProps, "onSelect"> {
    idx: number;
    onSelect?: (idx: number) => void;
}

const ProjectForPersonWithIdx = React.memo(({ project, personId, idx, onSelect }: ProjectForPersonWithIdx) => {
    const handleSelect = React.useCallback(() => {
        onSelect?.(idx);
    }, [idx, onSelect]);

    return <ProjectForPerson project={project} personId={personId} onSelect={handleSelect} />;
});

ProjectForPersonWithIdx.displayName = "ProjectForPersonWithIdx";
